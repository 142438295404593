import { createSlice, PayloadAction } from "@reduxjs/toolkit";


interface CustomerInvoiceType {
    customerInvoiceList: [];
}


const initialState: CustomerInvoiceType = {
    customerInvoiceList: []
};

export const customerInvoiceSlice = createSlice({
    name: "CustomerInvoiceAction",
    initialState: initialState,
    reducers: {
        setCustomerInvoiceList: (state, action: PayloadAction<any>) => {
            state.customerInvoiceList = action.payload;
        },
        cleanCustomerInvoiceList: (state) => {
            state.customerInvoiceList = [];
        },
    },
});

export const { setCustomerInvoiceList } = customerInvoiceSlice.actions;
export const { cleanCustomerInvoiceList } = customerInvoiceSlice.actions;

export default customerInvoiceSlice.reducer;
