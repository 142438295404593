import React, { useEffect, useState, Fragment } from "react";
import { useDispatch } from "react-redux";
import "primeflex/primeflex.css";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useNavigate } from "react-router";
import { StatusCodes } from "http-status-codes";
import { logout } from "../../service/AuthService";
import * as Yup from "yup";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { confirmDialog } from "primereact/confirmdialog";
import { readAllCustomers, readCustomer, saveCustomer } from "../../service/CustomerService";
import { setCustomerList } from "../../state/customerSlice";
import { DataTable } from "primereact/datatable";
import { Column, ColumnEventParams } from "primereact/column";



const CustomerDetail: React.FC<any> = (props: any) => {

    let navigate = useNavigate();
    const dispatch = useDispatch();

    const [
        customerDetailDialogShow,
        setCustomerDetailDialogShow,
    ] = useState<boolean>(false);

    const [
        customerDetailDialogChange,
        setCustomerDetailDialogChange,
    ] = useState<number>(0);

    const hideDialog = () => {
        setCustomerDetailDialogShow(false);
        setCustomerDto(null);
        formik.resetForm();
        refreshCustomerList();
    };

    const [branchName, setBranchName] = useState<string>("");
    const [branchList, setBranchList] = useState<any>([]);

    const [customerDto, setCustomerDto] = useState<any>();

    const customerId: any = props.id;

    const branchNameEditor = (options: any) => {
        return (
            <InputText
                style={{ width: "90%" }}
                value={options.value}
                onChange={(e) => options.editorCallback(e.target.value)}
            />
        );
    };

    const branchIdentiferEditor = (options: any) => {
        return (
            <InputText
                style={{ width: "90%" }}
                value={options.value}
                onChange={(e) => options.editorCallback(e.target.value)}
            />
        );
    };


    const onBranchNameEditCompleted = (e: ColumnEventParams) => {
        let { rowData, newValue, field, newRowData, originalEvent: event } = e;
        event.preventDefault();
        console.log(rowData);
        console.log(newRowData);

        if (rowData[field] !== newValue) {
            rowData[field] = newValue;
        }
    };

    const onBranchIdentiferEditCompleted = (e: ColumnEventParams) => {
        let { rowData, newValue, field, newRowData, originalEvent: event } = e;
        event.preventDefault();
        console.log(rowData);
        console.log(newRowData);

        if (rowData[field] !== newValue) {
            rowData[field] = newValue;
        }
    };

    const onBranchNameEditCanceled = (e: ColumnEventParams) => {
        let { rowData, newValue, field, newRowData, originalEvent: event } = e;
        event.preventDefault();
    };

    const onBranchIdentifierEditCanceled = (e: ColumnEventParams) => {
        let { rowData, newValue, field, newRowData, originalEvent: event } = e;
        event.preventDefault();
    };


    const confirmSave = (values: any) => {
        confirmDialog({
            message: 'Valóban menti a módosításokat?',
            header: 'Megerősítés',
            icon: 'pi pi-exclamation-triangle',
            acceptClassName: 'p-button-text',
            rejectClassName: 'p-button-text',
            accept: () => {

                values.branchList = branchList;
                //alert(JSON.stringify(values, null, 2));
                saveCustomer(values).then(
                    (response) => {
                        setCustomerDto(response.data);
                        hideDialog();
                    },
                    (error) => {
                        console.log(error);
                        hideDialog();
                    }
                );

            },
            reject: () => { }
        });
    }



    const confirmDeleteBranch = (rowData: any) => {
        confirmDialog({
            message: 'Valóban törli az üzletágat?',
            header: 'Megerősítés',
            icon: 'pi pi-exclamation-triangle',
            acceptClassName: 'p-button-text',
            rejectClassName: 'p-button-text',
            accept: () => {
                let branchListOld = [...branchList];
                branchListOld.forEach((value, index) => {
                    if (value.name === rowData.name) branchListOld.splice(index, 1);
                });
                setBranchList(branchListOld);
                customerDto.branchList.splice(0);
                customerDto.branchList.push(...branchListOld)
            },
            reject: () => { }
        });
    }

    const deleteBranchTemplate = (rowData: any) => {
        return (
            <>
                <Button
                    className="p-button-rounded p-button-text"
                    type="button"
                    onClick={() => {
                        confirmDeleteBranch(rowData);
                    }}
                    icon="pi pi-trash"
                    aria-label="Delete"
                />
            </>
        );
    };



    const refreshCustomerList = () => {
        readAllCustomers().then(
            (response) => {
                dispatch(
                    setCustomerList(
                        response.data.customerList
                    )
                );
            },
            (error) => {
                // console.log(error.response.status);
                if (error.response.status === StatusCodes.UNAUTHORIZED) {
                    //TODO login oldalon üzenet --> Lejárt a munkamenet, jelentkezzen be
                    logout();
                    navigate("/login?status=unauthorized");
                }
            }
        );
    };

    const header = (
        <div className="table-header">
            Üzletágak
            <Button icon="pi pi-plus" />
        </div>
    );

    useEffect(() => {
        if (
            props.show === true
        ) {
            console.log("CustomerDetail's useEffect");
            setCustomerDto(null);
            setBranchList([]);
            setBranchName("");
            if (customerId !== null && customerId !== "") {
                readCustomer(customerId).then(
                    (response) => {
                        console.log(response.data.branchList);
                        setCustomerDto(response.data);
                        setBranchList(response.data.branchList);
                        setBranchName("");

                    },
                    (error) => {
                        if (
                            error.response.status === StatusCodes.METHOD_NOT_ALLOWED
                        ) {
                            console.log("A név már szerepel a rendszerben");
                        }
                        if (error.response.status === StatusCodes.UNAUTHORIZED) {
                            logout();
                            navigate("/login?status=unauthorized");
                        }
                    }
                );
            }
            setCustomerDetailDialogChange(props.change);
            setCustomerDetailDialogShow(props.show);
        }
    }, [customerDetailDialogChange, navigate, props.show, props.change, customerId]);

    const formik = useFormik({
        initialValues: {
            id: customerDto && customerDto.id ? customerDto.id : "",
            name: customerDto && customerDto.name ? customerDto.name : "",
            shortName: customerDto && customerDto.shortName ? customerDto.shortName : "",
        },
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: Yup.object({
            name: Yup.string()
                .min(2, "A névnek legalább 2 karakternek kell lennie")
                .required("Kötelező kitölteni!"),
            shortName: Yup.string()
                .min(2, "A névnek legalább 2 karakternek kell lennie")
                .required("Kötelező kitölteni!"),
        }),
        onSubmit: (values) => {
            if (
                customerDetailDialogShow
            ) {
                confirmSave(values);
            }
        },
    });




    return (
        <>
            <Dialog header="Ügyfél adatai" visible={customerDetailDialogShow}
                maximizable modal closable={false} style={{ width: "90vw" }} onHide={() => hideDialog()}>
                <form onSubmit={formik.handleSubmit} className="p-fluid">
                    <div className="formgrid grid">
                        <div className="field col-12 md:col-6">
                            <label
                                htmlFor="shortName"
                                className="block text-900 font-medium mb-2"
                            >
                                Rövid név
                            </label>
                            <InputText
                                className="w-full mb-3"
                                autoFocus
                                id="shortName"
                                name="shortName"
                                value={formik.values.shortName}
                                onChange={formik.handleChange}
                            />
                            {formik.touched.shortName &&
                                formik.errors.shortName ? (
                                <small className="p-error">
                                    {formik.errors.shortName.toString()}
                                </small>
                            ) : null}
                        </div>

                        <div className="field col-12 md:col-6">
                            <label
                                htmlFor="name"
                                className="block text-900 font-medium mb-2"
                            >
                                Név
                            </label>
                            <InputText
                                className="w-full mb-3"
                                id="name"
                                name="name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                            />
                            {formik.touched.name &&
                                formik.errors.name ? (
                                <small className="p-error">
                                    {formik.errors.name.toString()}
                                </small>
                            ) : null}
                        </div>
                    </div>




                    <div className="formgrid grid">
                        <div className="field col-12 md:col-6">

                            <label
                                htmlFor="name"
                                className="block text-900 font-medium mb-2"
                            >
                                Üzletágak
                            </label>

                            <div className="formgrid grid">
                                <div className="col-10">
                                    <InputText
                                        className="w-full mb-2"
                                        id="newBranch"
                                        name="newBranch"
                                        value={branchName}
                                        onChange={(e) => setBranchName(e.target.value)}
                                    />
                                </div>
                                <div className="col-2">
                                    <Button type="button" className="w-full mb-2 p-button-outlined" icon="pi pi-plus"
                                        onClick={() => {
                                            if (branchName !== "") {
                                                let branchListOld = [...branchList];
                                                for (var branchItem of branchListOld) {
                                                    if (branchName === branchItem.name) {
                                                        return;
                                                    }
                                                }
                                                let branch = { id: "0", name: branchName };
                                                branchListOld.push(branch);
                                                setBranchList(branchListOld);
                                                customerDto.branchList.splice(0);
                                                customerDto.branchList.push(...branchListOld)
                                                setBranchName("");
                                            }
                                        }}
                                    />
                                </div>
                            </div>

                            <DataTable
                                className="admin-list"
                                value={branchList}
                                size="small"
                                responsiveLayout="scroll"
                            >
                                <Column header="" body={deleteBranchTemplate} style={{ width: "80px" }} />
                                <Column sortable field="name" header="Név" style={{ width: "200px" }}
                                    onCellEditCancel={onBranchNameEditCanceled}
                                    editor={(options) => branchNameEditor(options)}
                                    onCellEditComplete={onBranchNameEditCompleted} />
                                <Column sortable field="orderNo" header="Sorszám" style={{ width: "200px" }}
                                    onCellEditCancel={onBranchNameEditCanceled}
                                    editor={(options) => branchNameEditor(options)}
                                    onCellEditComplete={onBranchNameEditCompleted} />
                            </DataTable>
                        </div>


                    </div>




                    <div className="formgrid grid">
                        <div className="field">
                            <Button
                                className="p-button-text"
                                type="button"
                                label="Bezárás"
                                icon="pi pi-times"
                                onClick={() => {
                                    hideDialog();
                                }}
                            />
                        </div>

                        <div className="field">
                            <Button
                                className="p-button-text"
                                type="submit"
                                label="Mentés"
                                icon="pi pi-check"
                            />
                        </div>
                    </div>
                </form>
            </Dialog>
        </>
    );
};

export default CustomerDetail;





