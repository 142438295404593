import React, { useEffect, useState, Fragment } from "react";
import { useDispatch } from "react-redux";
import "primeflex/primeflex.css";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useNavigate } from "react-router";
import { StatusCodes } from "http-status-codes";
import * as Yup from "yup";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { confirmDialog } from "primereact/confirmdialog";
import { addTask, readHiddenTasks, readOwnHiddenTasks, unhideTasks, updateTaskName } from "../../service/TrafficTableService";
import { setTrafficTableLineList } from "../../state/trafficTableSlice";
import { logout } from "../../service/AuthService";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { onRowIndexTemplate } from "../../service/IndexColumn";



const HiddenTaskDialog: React.FC<any> = (props: any) => {

    let navigate = useNavigate();
    const dispatch = useDispatch();

    const [hiddenTaskList, setHiddenTaskList] = useState<any>([]);
    const [selectedTasks, setSelectedTasks] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const [
        dialogShow,
        setDialogShow,
    ] = useState<boolean>(false);

    const [
        dialogChange,
        setDialogChange,
    ] = useState<number>(0);

    const hideDialog = () => {
        setDialogShow(false);
        formik.resetForm();
        onCloseModal();
    };


    const onClose = props.onClose;
    const ownTasks: boolean = props.ownTasks

    const onCloseModal = () => {
        onClose();
    }


    const confirmSave = (values: any) => {
        confirmDialog({
            message: 'Valóban menti a módosításokat?',
            header: 'Megerősítés',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                let selectedTaskIds: any = [];
                selectedTasks.forEach((value: any) => {
                    selectedTaskIds.push(value.taskId);
                });
                //values = {taskId: taskId, comment: values.coment};
                //alert(JSON.stringify(values, null, 2));
                console.log(selectedTaskIds);
                unhideTasks({ taskIdList: selectedTaskIds }).then(
                    (response) => {
                        hideDialog();
                    },
                    (error) => {
                        console.log(error);
                        hideDialog();
                    }
                );

            },
            reject: () => { }
        });
    }

    useEffect(() => {
        if (
            props.show === true
        ) {
            setDialogChange(props.change);
            setDialogShow(props.show);
            setSelectedTasks([]);
            setLoading(true);

            if (ownTasks) {
                readOwnHiddenTasks().then(
                    (response) => {
                        setHiddenTaskList(response.data.hiddenTaskList);
                        setLoading(false);
                    },
                    (error) => {
                        setLoading(false);
                        if (error.response.status === StatusCodes.UNAUTHORIZED) {
                            logout();
                            navigate("/login?status=unauthorized");
                        }
                    }
                );
            } else {
                readHiddenTasks().then(
                    (response) => {
                        setHiddenTaskList(response.data.hiddenTaskList);
                        setLoading(false);
                    },
                    (error) => {
                        setLoading(false);
                        if (error.response.status === StatusCodes.UNAUTHORIZED) {
                            logout();
                            navigate("/login?status=unauthorized");
                        }
                    }
                );
            }

        }
    }, [dialogChange, navigate, props.show, props.change]);

    const formik = useFormik({
        initialValues: {

        },
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: Yup.object({
        }),
        onSubmit: (values) => {
            if (dialogShow) {
                confirmSave(values);
            }
        },
    });


    return (
        <>
            <Dialog header="Elrejtett feladatok láthatóvá tétele" visible={dialogShow} closable={false}
                maximizable modal style={{ width: "90vw", height: "90vh" }} onHide={() => hideDialog()}>
                <form onSubmit={formik.handleSubmit} className="p-fluid">
                    <div className="formgrid grid">
                        <div className="field col-12">

                            <DataTable
                                //className="admin-list"
                                scrollable
                                scrollHeight="60vh"
                                value={hiddenTaskList}
                                size="small"
                                responsiveLayout="scroll"
                                selectionMode="checkbox"
                                selection={selectedTasks}
                                onSelectionChange={e => setSelectedTasks(e.value)}
                                dataKey="taskId"
                                loading={loading}

                            >
                                <Column field="rowIndex" header="" style={{ maxWidth: '60px' }} body={onRowIndexTemplate} />
                                <Column selectionMode="multiple" style={{ maxWidth: "50px" }} ></Column>
                                <Column
                                    key="participantName"
                                    sortable
                                    filter
                                    field="participantName"
                                    header="Résztvevő"
                                    style={{ width: "125px" }}
                                />
                                <Column
                                    key="projectOwnerName"

                                    sortable
                                    filter
                                    field="projectOwnerName"
                                    header="Account"
                                    style={{ width: "135px" }}
                                />
                                <Column
                                    key="projectShortIdentifier"

                                    sortable
                                    filter
                                    field="projectShortIdentifier"
                                    header="Rövid munkaszám"
                                    style={{ width: "290px" }}
                                />
                                <Column
                                    key="projectName"

                                    sortable
                                    filter
                                    field="projectName"
                                    header="Munkanév"
                                    style={{ width: "290px" }}
                                />
                                <Column
                                    key="taskName"
                                    sortable
                                    filter
                                    field="taskName"
                                    header="Feladat"
                                    style={{ width: "290px" }}
                                />
                                <Column
                                    key="taskComment"
                                    sortable
                                    filter
                                    field="taskComment"
                                    header="Megjegyzés"
                                    style={{ width: "290px" }}
                                />
                            </DataTable>

                        </div>


                    </div>

                    <div className="formgrid grid">
                        <div className="field">
                            <Button
                                className="p-button-text"
                                type="button"
                                label="Bezárás"
                                icon="pi pi-times"
                                onClick={() => {
                                    hideDialog();
                                }}
                            />
                        </div>

                        <div className="field">
                            <Button
                                className="p-button-text"
                                type="submit"
                                label="Mentés"
                                icon="pi pi-check"
                            />
                        </div>
                    </div>
                </form>
            </Dialog>
        </>
    );
};

export default HiddenTaskDialog;





