import axios from 'axios';
import { server } from '../config/endpoints';

const API_URL = server;

export const readAllSkyUsers = () => {
    return axios
        .get(API_URL + '/protected/skyuser')
        .then((response) => {
            return response;
        });
};

export const readAllCreativeSkyUsers = () => {
    return axios
        .get(API_URL + '/protected/skyuser/creative')
        .then((response) => {
            return response;
        });
};

export const readAllAccountSkyUsers = () => {
    return axios
        .get(API_URL + '/protected/skyuser/account')
        .then((response) => {
            return response;
        });
};

export const readAllCoworkerSkyUsers = () => {
    return axios
        .get(API_URL + '/protected/skyuser/coworker')
        .then((response) => {
            return response;
        });
};

export const readOnlyCoworkerSkyUsers = () => {
    return axios
        .get(API_URL + '/protected/skyuser/onlycoworker')
        .then((response) => {
            return response;
        });
};

export const readAllGenericSkyUsers = () => {
    return axios
        .get(API_URL + '/protected/skyuser/generic')
        .then((response) => {
            return response;
        });
};

export const readFirstWorkingDayBySkyUser = () => {
    return axios
        .get(API_URL + '/protected/skyuser/firstworkingday')
        .then((response) => {
            return response;
        });
};

export const readAllSkyRoles = () => {
    return axios
        .get(API_URL + '/protected/skyrole')
        .then((response) => {
            return response;
        });
};

export const readSkyUser = (id: string) => {
    return axios
        .get(API_URL + '/protected/skyuser/' + id)
        .then((response) => {
            return response;
        });
};

export const saveSkyUser = (formData: any) => {
    return axios
        .post(API_URL + '/protected/skyuser', formData)
        .then((response) => {
            return response;
        });
};

export const deleteSkyUser = (id: string) => {
    return axios
        .delete(API_URL + '/protected/skyuser/' + id)
        .then((response) => {
            return response;
        });
};

export const readSkyUsersOfBranches = (formData: any) => {
    return axios
        .post(API_URL + '/protected/skyuser/branches', formData)
        .then((response) => {
            return response;
        });
};


