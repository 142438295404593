import axios from 'axios';
import { server } from '../config/endpoints';

const API_URL = server;


export const newTask = (formData: any) => {
    return axios
        .post(API_URL + '/protected/task/new', formData)
        .then((response) => {
            return response;
        });
};

export const readTask = (formData: any) => {
    return axios
        .post(API_URL + '/protected/task/read', formData)
        .then((response) => {
            return response;
        });
};

export const updateTask = (formData: any) => {
    return axios
        .post(API_URL + '/protected/task/update', formData)
        .then((response) => {
            return response;
        });
};

export const hideTask = (formData: any) => {
    return axios
        .post(API_URL + '/protected/task/hide', formData)
        .then((response) => {
            return response;
        });
};


export const renameTask = (formData: any) => {
    return axios
        .post(API_URL + '/protected/task/rename', formData)
        .then((response) => {
            return response;
        });
};



export const readAllCreativeSkyUsers = (taskId: string) => {
    return axios
        .get(API_URL + '/protected/task/creative/' + taskId)
        .then((response) => {
            return response;
        });
};

export const readAllAccountSkyUsers = (taskId: string) => {
    return axios
        .get(API_URL + '/protected/task/account/' + taskId)
        .then((response) => {
            return response;
        });
};

export const readAllCoworkerSkyUsers = (taskId: string) => {
    return axios
        .get(API_URL + '/protected/task/coworker/' + taskId)
        .then((response) => {
            return response;
        });
};



export const readCreativeSkyUsersByTask = (taskId: string) => {
    return axios
        .get(API_URL + '/protected/task/addedcreative/' + taskId)
        .then((response) => {
            return response;
        });
};

export const readAccountSkyUsersByTask = (taskId: string) => {
    return axios
        .get(API_URL + '/protected/task/addedaccount/' + taskId)
        .then((response) => {
            return response;
        });
};

export const readCoworkerSkyUsersByTask = (taskId: string) => {
    return axios
        .get(API_URL + '/protected/task/addedcoworker/' + taskId)
        .then((response) => {
            return response;
        });
};


