import React from "react";
import {useDispatch} from "react-redux";

const RoleList: React.FC = () => {

    const dispatch = useDispatch();

    return (
        <div className="border-2 surface-border border-round surface-section flex-auto p-3 md:p-4 lg:p-5">
            <div className="surface-card border-round shadow-2 p-2 md:p-4 lg:p-5">
                <div className="text-center">
                   
                    <div className="text-xl text-900 font-medium mb-3">
                        Szerepkörök
                    </div>
                    <p className="mt-0 mb-4 p-0 line-height-3">
                        Mai dátum: {new Date().toLocaleDateString()}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default RoleList;
