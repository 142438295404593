import React, { useEffect, useRef, useState } from "react";
import { Route, Routes, useNavigate } from "react-router";
import {
    setAuthToken,
    getCurrentUser,
    logout,
} from "../service/AuthService";
import { StyleClass } from "primereact/styleclass";
import { Ripple } from "primereact/ripple";

import skyGroupLogo from "../asset/sky-group-logo.png";

import Dashboard from "./dashboard/Dashboard";
import SkyUserList from "./dashboard/SkyUserList";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../state/store";
import { setActiveMenu, setActiveView } from "../state/navigationSlice";
import RoleList from "./dashboard/RoleList";
import ProtectedRoute from "../route/ProtectedRoute";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import ProjectList from "./dashboard/ProjectList";
import CustomerList from "./dashboard/CustomerList";
import BranchList from "./dashboard/BranchList";
import TrafficTable from "./dashboard/TrafficTable";
import WorkingTime from "./dashboard/WorkingTime";
import TrafficTableNotes from "./dashboard/TrafficTableNotes";
import { Badge } from 'primereact/badge';
import EonReportDialog from "./dashboard/EonReportDialog";
import { createEonReport } from "../service/ReportService";
import FileSaver from "file-saver";
import { BlockUI } from "primereact/blockui";
import WorkingTimeReportDialog from "./dashboard/WorkingTimeReportDialog";
import { createWorkingTimeReport } from "../service/WorkingTimeReportService";
import MessageDialog from "../component/MessageDialog";
import VendorList from "./dashboard/VendorList";
import VendorInvoiceList from "./dashboard/VendorInvoiceList";
import ContributorList from "./dashboard/ContributorList";
import CustomerInvoiceList from "./dashboard/CustomerInvoiceList";
import CustomerInvoiceLoadResultList from "./dashboard/CustomerInvoiceLoadResultList";
import VendorInvoiceLoadResultList from "./dashboard/VendorInvoiceLoadResultList";
import FinanceRoute from "../route/FinanceRoute";

const Main2: React.FC = () => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    setAuthToken();


    const btnRefSidebar = useRef(null);
    const btnRefDashboard = useRef(null);
    const btnRefUserList = useRef(null);
    const btnRefAdministration = useRef<any>(null);
    const btnRefCustomerList = useRef(null);
    const btnRefProjectList = useRef(null);
    const btnRefTrafficTable = useRef(null);
    const btnRefWorkingHours = useRef(null);
    const btnRefTopbar = useRef(null);
    const btnRefProfil = useRef(null);

    const btnRef1 = useRef(null);
    const btnRef2 = useRef(null);
    const btnRefReports = useRef<any>(null);
    const btnRefInvoices = useRef<any>(null);
    const btnRefInvoicesArea = useRef<HTMLUListElement>(null);
    const btnVendorInvoice = useRef(null);

    const [user, setUser] = useState<any>();
    const [userName, setUserName] = useState<String>("");
    const [name, setName] = useState<String>("");
    const [role, setRole] = useState<String>("");
    const [eonReport, setEonReport] = useState<Boolean>(false);
    const [blockedPage, setBlockedPage] = useState<boolean>(false);
    const [blockedMessage, setBlockedMessage] = useState<string>("");

    const [messageDialogShow, setMessageDialogShow] = useState<Number>(0);
    const [messageDialogHeader, setMessageDialogHeader] = useState<string>("");
    const [messageDialogBody, setMessageDialogBody] = useState<string>("");
    const [messageDialogError, setMessageDialogError] =
        useState<boolean>(false);

    const activeView: any = useSelector(
        (state: RootState) => state.navigation.activeView
    );
    const activeMenu: string = useSelector(
        (state: RootState) => state.navigation.activeMenu
    );

    const [updateGenericReportDialogShow, setUpdateGenericReportDialogShow] =
        useState<boolean>(false);

    const [updateGenericReportDialogChange, setUpdateGenericReportDialogChange] =
        useState<number>(0);

    const onWorkingTimeReportDialogClose = (data: any) => {
        console.log("onWorkingTimeReportDialogClose is called");
        setBlockedPage(true);
        setBlockedMessage('Jelentés készítése folyamatban...');
        setUpdateGenericReportDialogShow(false);
        setUpdateGenericReportDialogChange(0);
        console.log(data);
        let reportTypeItems = [
            { label: "Naptár nézet egyben", value: "1" },
            { label: "Naptár nézet emberenként", value: "2" },
            { label: "E.ON Havidíj kimutatás", value: "3" },
            { label: "E.ON Fix/Óradíjas kimutatás", value: "3" },
        ];
        createWorkingTimeReport(data).then(
            (response) => {
                setBlockedPage(false);
                setUpdateGenericReportDialogShow(false);

                console.log(response);
                let blob = new Blob([response.data]);

                FileSaver(blob, "Munkaórák-" + reportTypeItems[data.reportTypeId - 1].label + "-" +
                    + new Date(data.fromDate).toLocaleDateString("hu-HU").replaceAll(" ", "").replaceAll(".", "") + "-"
                    + new Date(data.toDate).toLocaleDateString("hu-HU").replaceAll(" ", "").replaceAll(".", "")
                    + ".xlsx");
            },
            async (error) => {
                console.log(error);
                let jsonAsText = await error.response.data.text();
                let json = JSON.parse(jsonAsText)
                setMessageDialogHeader("Ellenőrzési hiba");
                setMessageDialogBody(json.message);
                setMessageDialogError(true);
                setMessageDialogShow(Date.now());
                setBlockedPage(false);
                setUpdateGenericReportDialogShow(false);
            }
        );
    };

    const onGenericReportDialogReject = () => {
        console.log("onGenericReportDialogReject is called");
        setUpdateGenericReportDialogShow(false);
        setUpdateGenericReportDialogChange(0);
    };

    const showGenericReport = () => {
        console.log("showEonReport is called");
        setUpdateGenericReportDialogShow(true);
        setUpdateGenericReportDialogChange(Date.now());
    };


    //setUserName(getCurrentUser().userName);

    const clear = () => {
        logout();
        navigate("/login");
    };


    useEffect(() => {
        setUserName(getCurrentUser().userName);
        setName(getCurrentUser().name);
        setRole(getCurrentUser().role);
        setEonReport(getCurrentUser().eonReport);

    }, []);

    const confirmLogout = () => {
        confirmDialog({
            message: 'Valóban kijelentkezik?',
            header: 'Megerősítés',
            icon: 'pi pi-exclamation-triangle',
            acceptClassName: 'p-button-text',
            rejectClassName: 'p-button-text',
            accept: () => { clear(); },
            reject: () => { }
        });
    }




    return (
        <>
            <BlockUI
                blocked={blockedPage}
                fullScreen
                template={
                    <div
                        className="text-center text-teal-50"
                        style={{ fontSize: "2rem" }}
                    >
                        <i
                            className="pi pi-spin pi-spinner"
                            style={{ fontSize: "3rem" }}
                        />
                        <br />
                        {blockedMessage}
                    </div>
                }
            />
            <div className="surface-overlay py-3 px-6 shadow-2 flex align-items-center justify-content-between relative lg:static" style={{ minHeight: '80px' }}>
                <ConfirmDialog />
                <MessageDialog
                    messageDialogError={messageDialogError}
                    messageDialogShow={messageDialogShow}
                    messageDialogHeader={messageDialogHeader}
                    messageDialogBody={messageDialogBody}
                />
                <img
                    src={skyGroupLogo}
                    height={40}
                    alt="SKY logo"
                />
                <StyleClass nodeRef={btnRef1} selector="@next" enterClassName="hidden" leaveToClassName="hidden" hideOnOutsideClick>
                    <a ref={btnRef1} className="cursor-pointer block lg:hidden text-700">
                        <i className="pi pi-bars text-4xl"></i>
                        <Ripple />
                    </a>
                </StyleClass>
                <div className="align-items-center z-2 flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full surface-overlay left-0 top-100 z-1 shadow-2 lg:shadow-none">
                    <ul className="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                        <li>
                            <a className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium 
                            border-round cursor-pointer transition-colors transition-duration-150 w-full"
                                onClick={() => {
                                    dispatch(setActiveView("Dashboard"));
                                    dispatch(setActiveMenu("dashboard"));
                                    navigate("dashboard");

                                }}
                            >
                                <i className="pi pi-home text-indigo-500 mr-2"></i>
                                <span>Dashboard</span>
                                <Ripple />
                            </a>
                        </li>
                        {role === 'ADMIN' || role === 'ACCOUNT' || role === 'FINANCE' ? (
                            <li>
                                <a className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full"
                                    onClick={() => {
                                        dispatch(setActiveView("Munkák"));
                                        dispatch(setActiveMenu("projectlist"));
                                        navigate("projectlist");

                                    }}
                                >
                                    <i className="pi pi-info-circle text-green-500 mr-2"></i>
                                    <span>Munkák</span>
                                    <Ripple />
                                </a>
                            </li>
                        ) : null}

                        {role !== 'FINANCE' ? (
                            <li>
                                <a className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full"
                                    onClick={() => {
                                        dispatch(setActiveView("Traffic tábla"));
                                        dispatch(setActiveMenu("traffictable"));
                                        navigate("traffictable");

                                    }}
                                >
                                    <i className="pi pi-book text-primary-500 mr-2"></i>
                                    <span>Traffic tábla</span>
                                    <Ripple />
                                </a>
                            </li>
                        ) : null}


                        {role !== 'FINANCE' ? (
                            <li>
                                <a className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full"
                                    onClick={() => {
                                        dispatch(setActiveView("Munkaóráim"));
                                        dispatch(setActiveMenu("workingtime"));
                                        navigate("workingtime");

                                    }}
                                >
                                    <i className="pi pi-calendar text-red-500 mr-2"></i>
                                    <span>Munkaóráim</span>
                                    <Ripple />
                                </a>
                            </li>
                        ) : null}



                        {role === "ADMIN" ? (
                            <li className="lg:relative">
                                <StyleClass nodeRef={btnRefAdministration} selector="@next" enterClassName="hidden" enterActiveClassName="scalein" leaveToClassName="hidden" leaveActiveClassName="fadeout" hideOnOutsideClick>
                                    <a ref={btnRefAdministration} className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full">
                                        <i className="pi pi-users text-orange-500 mr-2 "></i>
                                        <span>Adminisztráció</span>
                                        <i className="pi pi-angle-down ml-auto lg:ml-3"></i>
                                        <Ripple />
                                    </a>
                                </StyleClass>
                                <ul className="list-none z-2 py-3 px-6 m-0 lg:px-0 lg:py-0 border-round shadow-0 lg:shadow-2 lg:border-1 border-50 lg:absolute surface-overlay hidden origin-top w-full lg:w-15rem cursor-pointer">

                                    {role === "ADMIN" ? (
                                        <li>
                                            <a className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full"
                                                onClick={() => {

                                                    dispatch(setActiveView("Ügyfelek"));
                                                    dispatch(setActiveMenu("customerlist"));
                                                    navigate("customerlist");
                                                    btnRefAdministration.current?.click();
                                                }}
                                            >
                                                <i className="pi pi-money-bill text-orange-500 mr-2"></i>
                                                <span className="font-medium">Ügyfelek</span>
                                                <Ripple />
                                            </a>
                                        </li>) : null}

                                    {role === "ADMIN" ? (
                                        <li>
                                            <a className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full"
                                                onClick={() => {
                                                    dispatch(setActiveView("Felhasználók"));
                                                    dispatch(setActiveMenu("userlist"));
                                                    navigate("userlist");
                                                    btnRefAdministration.current?.click();
                                                }}
                                            >
                                                <i className="pi pi-id-card text-orange-500 mr-2"></i>
                                                <span className="font-medium">Felhasználók</span>
                                                <Ripple />
                                            </a>
                                        </li>) : null}

                                    {role === "ADMIN" || role === "FINANCE" ? (
                                        <li>
                                            <a className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full"
                                                onClick={() => {
                                                    dispatch(setActiveView("Alvállalkozók"));
                                                    dispatch(setActiveMenu("vendorlist"));
                                                    navigate("vendorlist");
                                                    btnRefAdministration.current?.click();
                                                }}
                                            >
                                                <i className="pi pi-map text-orange-500 mr-2"></i>
                                                <span className="font-medium">Alvállalkozók</span>
                                                <Ripple />
                                            </a>
                                        </li>) : null}

                                </ul>
                            </li>
                        ) : null}



                        {role === "ADMIN" || role === "ACCOUNT" || role === "FINANCE" ? (
                            <li className="lg:relative">
                                <StyleClass nodeRef={btnRefReports} selector="@next" enterClassName="hidden" enterActiveClassName="scalein" leaveToClassName="hidden" leaveActiveClassName="fadeout" hideOnOutsideClick>
                                    <a ref={btnRefReports} className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full">
                                        <i className="pi pi-database text-teal-500 mr-2"></i>
                                        <span>Jelentések</span>
                                        <i className="pi pi-angle-down ml-auto lg:ml-3"></i>
                                        <Ripple />
                                    </a>
                                </StyleClass>
                                <ul className="list-none py-3 px-6 m-0 lg:px-0 lg:py-0 border-round shadow-0 lg:shadow-2 lg:border-1 border-50 lg:absolute surface-overlay hidden origin-top w-full lg:w-15rem cursor-pointer">

                                    <li>
                                        <a className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full"
                                            onClick={() => {
                                                showGenericReport();
                                                btnRefReports.current?.click();
                                            }}
                                        >
                                            <i className="pi pi-envelope text-teal-500 mr-2"></i>
                                            <span className="font-medium">Munkaóra jelentés</span>
                                            <Ripple />
                                        </a>
                                    </li>

                                </ul>
                            </li>
                        ) : null}




                        {role === "ADMIN" || role === "FINANCE" || role === "MANAGEMENT" ? (
                            <li className="lg:relative">
                                <StyleClass nodeRef={btnRefInvoices} selector="@next" enterClassName="hidden" enterActiveClassName="scalein" leaveToClassName="hidden" leaveActiveClassName="fadeout" hideOnOutsideClick>
                                    <a ref={btnRefInvoices} className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full">
                                        <i className="pi pi-database text-gray-500 mr-2"></i>
                                        <span>Számlák</span>
                                        <i className="pi pi-angle-down ml-auto lg:ml-3"></i>
                                        <Ripple />
                                    </a>
                                </StyleClass>
                                <ul className="list-none py-3 px-6 m-0 lg:px-0 lg:py-0 border-round shadow-0 lg:shadow-2 lg:border-1 border-50 lg:absolute surface-overlay hidden origin-top w-full lg:w-15rem cursor-pointer">



                                    <li>
                                        <a className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full"
                                            onClick={() => {
                                                dispatch(setActiveView("Számlák leigazolásra feladva"));
                                                dispatch(setActiveMenu("vendorInvoiceList"));
                                                navigate("vendorinvoicelist");
                                                btnRefInvoices.current?.click();
                                            }}
                                        >
                                            <i className="pi pi-server text-gray-500 mr-2"></i>
                                            <span className="font-medium">Alvállalkozói számlák</span>
                                            <Ripple />
                                        </a>
                                    </li>




                                    <li>
                                        <a className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full"
                                            onClick={() => {
                                                dispatch(setActiveView("Vevői számlák"));
                                                dispatch(setActiveMenu("customerInvoiceList"));
                                                navigate("customerinvoicelist");
                                                btnRefInvoices.current?.click();
                                            }}
                                        >
                                            <i className="pi pi-qrcode text-gray-500 mr-2"></i>
                                            <span className="font-medium">Vevői számlák</span>
                                            <Ripple />
                                        </a>
                                    </li>







                                    <li className="border-top-1 border-gray-400">
                                        <a className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full"
                                            onClick={() => {
                                                dispatch(setActiveView("Vevői számla betöltések"));
                                                dispatch(setActiveMenu("customerInvoiceLoadResultList"));
                                                navigate("customerinvoiceloadresultlist");
                                                btnRefInvoices.current?.click();
                                            }}
                                        >
                                            <i className="pi pi-link text-gray-500 mr-2"></i>
                                            <span className="font-medium">Vevői számla betöltések</span>
                                            <Ripple />
                                        </a>
                                    </li>


                                    <li>
                                        <a className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full"
                                            onClick={() => {
                                                dispatch(setActiveView("Alvállalkozó számla betöltések"));
                                                dispatch(setActiveMenu("vendorInvoiceLoadResultList"));
                                                navigate("vendorinvoiceloadresultlist");
                                                btnRefInvoices.current?.click();
                                            }}
                                        >
                                            <i className="pi pi-sliders-h text-gray-500 mr-2"></i>
                                            <span className="font-medium">Alvállalkozó számla betöltések</span>
                                            <Ripple />
                                        </a>
                                    </li>



                                </ul>


                            </li>
                        ) : null}




                    </ul>






                    <ul className="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row border-top-1 surface-border lg:border-top-none">
                        <i className="pi pi-table text-base text-cyan-500 lg:text-2xl mr-2 lg:mr-2"></i>
                        <li className="border-top-1 surface-border lg:border-top-none">
                            <div className="text-900 font-medium mr-5">
                                {activeView
                                    ? activeView.toUpperCase()
                                    : null}
                            </div>
                            <Ripple />
                        </li>


                        <i className="pi pi-user text-base text-cyan-500 lg:text-2xl mr-2 lg:mr-2"></i>
                        <li className="border-top-1 surface-border lg:border-top-none">
                            <div className="text-900 font-medium mr-3">
                                {name
                                    ? name
                                    : null}
                            </div>
                            <Ripple />
                        </li>

                        <li>
                            <a className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full"
                                onClick={() => {
                                    console.log("logout is pressed");
                                    confirmLogout();
                                }}
                            >
                                <i className="pi pi-sign-out text-base text-blue-500 lg:text-2xl mr-2 lg:mr-0"></i>
                                <span className="block lg:hidden font-medium">Kijelentkezés</span>
                                <Ripple />
                            </a>
                        </li>
                    </ul>
                </div>

                <WorkingTimeReportDialog
                    show={updateGenericReportDialogShow}
                    change={updateGenericReportDialogChange}
                    onClose={onWorkingTimeReportDialogClose}
                    onReject={onGenericReportDialogReject}
                />

            </div>
            <div className="flex flex-column flex-auto p-2 mt-2 md:p-2 lg:p-3">
                {/* <div className="text-xl text-900 font-medium mb-3">
                    {activeView}
                </div> */}
                <Routes>
                    <Route element={<ProtectedRoute />}>
                        <Route path={"rolelist"} element={<RoleList />} />
                        <Route path={"dashboard"} element={<Dashboard />} />
                        <Route path={"userlist"} element={<SkyUserList />} />
                        <Route path={"projectList"} element={<ProjectList />} />
                        <Route path={"customerlist"} element={<CustomerList />} />
                        <Route path={"vendorlist"} element={<VendorList />} />
                        <Route path={"contributorlist"} element={<ContributorList />} />
                        <Route path={"traffictable"} element={<TrafficTable />} />
                        <Route path={"workingtime"} element={<WorkingTime />} />
                        <Route path={"demo"} element={<TrafficTableNotes />} />
                    </Route>

                    <Route element={<FinanceRoute />}>
                        <Route path={"vendorinvoicelist"} element={<VendorInvoiceList />} />
                        <Route path={"customerinvoicelist"} element={<CustomerInvoiceList />} />
                        <Route path={"customerinvoiceloadresultlist"} element={<CustomerInvoiceLoadResultList />} />
                        <Route path={"vendorinvoiceloadresultlist"} element={<VendorInvoiceLoadResultList />} />
                    </Route>

                </Routes>
            </div>
        </>
    );
};
export default Main2;


