import axios from 'axios';
import { server } from '../config/endpoints';

const API_URL = server;

export const readAllVendorInvoices = (company: string) => {

    if (company === 'Összes') {
        company = 'ALL';
    }
    return axios
        .get(API_URL + '/protected/vendorinvoice/all/' + company)
        .then((response) => {
            return response;
        });
};

export const readVendorInvoicesByVendorAsLabelValue = (vendorId: string) => {
    return axios
        .get(API_URL + '/protected/vendorinvoice/lv/' + vendorId)
        .then((response) => {
            return response;
        });
};


export const readVendorInvoicesByVendor = (invoiceFrom: string, vendorId: string) => {
    return axios
        .get(API_URL + '/protected/vendorinvoice/byvendor/' + invoiceFrom + '/' + vendorId)
        .then((response) => {
            return response;
        });
};


export const readVendorInvoice = (id: string) => {
    return axios
        .get(API_URL + '/protected/vendorinvoice/' + id)
        .then((response) => {
            return response;
        });
};


export const deleteVendorInvoice = (id: string) => {
    return axios
        .delete(API_URL + '/protected/vendorinvoice/' + id)
        .then((response) => {
            return response;
        });
};


export const saveVendorInvoice = (formData: any) => {
    return axios
        .post(API_URL + '/protected/vendorinvoice', formData)
        .then((response) => {
            return response;
        });
};

export const uploadSkyVendorInvoices = (formData: any) => {
    return axios
        .post(API_URL + '/protected/vendorinvoice/upload/sky', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then((response) => {
            return response;
        });
};


export const uploadBpcVendorInvoices = (formData: any) => {
    return axios
        .post(API_URL + '/protected/vendorinvoice/upload/bpc', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then((response) => {
            return response;
        });
};