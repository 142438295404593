import React, { useEffect, useState, Fragment } from "react";
import { useDispatch } from "react-redux";
import "primeflex/primeflex.css";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useNavigate } from "react-router";
import { readAllSkyRoles, readAllSkyUsers, readSkyUser, saveSkyUser } from "../../service/SkyUserService";
import { StatusCodes } from "http-status-codes";
import { logout } from "../../service/AuthService";
import * as Yup from "yup";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Password } from "primereact/password";
import { Divider } from "primereact/divider";
import { confirmDialog } from "primereact/confirmdialog";
import { setSkyUserList } from "../../state/skyUserSlice";
import { Checkbox } from "primereact/checkbox";
import { Calendar } from "primereact/calendar";
import { set } from "immer/dist/internal";


const SkyUserDetail: React.FC<any> = (props: any) => {

    let navigate = useNavigate();
    const dispatch = useDispatch();

    const [
        skyUserDetailDialogShow,
        setSkyUserDetailDialogShow,
    ] = useState<boolean>(false);

    const [
        skyUserDetailDialogChange,
        setSkyUserDetailDialogChange,
    ] = useState<number>(0);

    const hideDialog = () => {
        setSkyUserDetailDialogShow(false);
        setSkyUserDto(null);
        formik.resetForm();
        refreshSkyUserList();
    };

    const [skyUserDto, setSkyUserDto] = useState<any>();



    const skyUserId: any = props.id;

    const roles = [
        { label: "Válasszon egy szerepkört!", value: "" },
        { label: "Admin", value: "ADMIN" },
        { label: "Account", value: "ACCOUNT" },
        { label: "Kreatív", value: "CREATIVE" },
        { label: "Menedzsment", value: "MANAGEMENT" },
        { label: "Munkatárs", value: "COWORKER" },
        { label: "Pénzügy", value: "FINANCE" },
    ];

    const header = <h6>Adja meg a jelszavát!</h6>;
    const footer = (
        <Fragment>
            <Divider />
            <p className="mt-2">Javaslatok</p>
            <ul className="pl-2 ml-2 mt-0" style={{ lineHeight: "1.5" }}>
                <li>Legalább 8 karakter hosszú</li>
                <li>Legalább egy nagybetű</li>
                <li>Legalább egy kisbetű</li>
                <li>Legalább egy szám</li>
                {/* <li>Legalább egy speciális karakter @#$%^&*?_~.,;=</li> */}
            </ul>
        </Fragment>
    );

    const confirmSave = (values: any) => {
        confirmDialog({
            message: 'Valóban menti a módosításokat?',
            header: 'Megerősítés',
            icon: 'pi pi-exclamation-triangle',
            acceptClassName: 'p-button-text',
            rejectClassName: 'p-button-text',
            // acceptLabel: '',
            // acceptIcon: 'pi pi-check',
            // rejectLabel: '',
            // rejectIcon: 'pi pi-times',
            accept: () => {
                saveSkyUser(values).then(
                    (response) => {
                        setSkyUserDto(response.data);
                        hideDialog();
                    },
                    (error) => {
                        console.log(error);
                        hideDialog();
                    }
                );

            },
            reject: () => { }
        });
    }


    const refreshSkyUserList = () => {
        readAllSkyUsers().then(
            (response) => {
                dispatch(
                    setSkyUserList(
                        response.data.skyUserList
                    )
                );
            },
            (error) => {
                // console.log(error.response.status);
                if (error.response.status === StatusCodes.UNAUTHORIZED) {
                    //TODO login oldalon üzenet --> Lejárt a munkamenet, jelentkezzen be
                    logout();
                    navigate("/login?status=unauthorized");
                }
            }
        );
    };

    useEffect(() => {
        if (
            props.show === true
        ) {
            console.log("SkyUserDetail's useEffect");
            setSkyUserDto(null);
            if (skyUserId !== null && skyUserId !== "") {
                readSkyUser(skyUserId).then(
                    (response) => {
                        console.log(response.data);
                        setSkyUserDto(response.data);

                    },
                    (error) => {
                        if (
                            error.response.status === StatusCodes.METHOD_NOT_ALLOWED
                        ) {
                            console.log("A név már szerepel a rendszerben");
                        }
                        if (error.response.status === StatusCodes.UNAUTHORIZED) {
                            logout();
                            navigate("/login?status=unauthorized");
                        }
                    }
                );
            }
            setSkyUserDetailDialogChange(props.change);
            setSkyUserDetailDialogShow(props.show);
        }
    }, [skyUserDetailDialogChange, navigate, props.show, props.change, skyUserId]);

    const formik = useFormik({
        initialValues: {
            id: skyUserDto && skyUserDto.id ? skyUserDto.id : "",
            name: skyUserDto && skyUserDto.name ? skyUserDto.name : "",
            shortName: skyUserDto && skyUserDto.shortName ? skyUserDto.shortName : "",
            role: skyUserDto && skyUserDto.role ? skyUserDto.role : "",
            email: skyUserDto && skyUserDto.email ? skyUserDto.email : "",
            firstWorkingDay: skyUserDto && skyUserDto.firstWorkingDay ? new Date(skyUserDto.firstWorkingDay): null,
            password: skyUserDto && skyUserDto.password ? skyUserDto.password : "",
            passwordAgain: skyUserDto && skyUserDto.passwordAgain ? skyUserDto.passwordAgain : "",
            hiddenOnTrafficTable: skyUserDto && skyUserDto.hiddenOnTrafficTable ? skyUserDto.hiddenOnTrafficTable : false,
        },
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: Yup.object({
            name: Yup.string()
                .min(2, "A névnek legalább 2 karakternek kell lennie")
                .required("Kötelező kitölteni!"),
            shortName: Yup.string()
                .min(2, "A névnek legalább 2 karakternek kell lennie")
                .required("Kötelező kitölteni!"),
            role: Yup.string()
                .min(2, "A névnek legalább 2 karakternek kell lennie")
                .required("Kötelező kitölteni!"),
            email: Yup.string()
                .min(5, "Minimum 5 karakter kell, hogy legyen!")
                .email("Hibás email cím!")
                .required("Kötelező kitölteni!"),
            firstWorkingDay: Yup.date()
                .required("Kötelező kitölteni!")
                .typeError("Kötelező kitölteni!"),
            password: Yup.string()
                .min(8, "Legalább 8 karakter hosszú")
                .required("Kötelező kitölteni!")
                .matches(/[0-9]/, "Legalább egy szám")
                .matches(/[a-zA-Z]/, "Legalább egy kisbetű")
                .matches(/[A-Z]/, "Legalább egy nagybetű"),
            passwordAgain: Yup.string()
                .min(8, "Legalább 8 karakter hosszú")
                .required("Kötelező kitölteni!")
                .matches(/[0-9]/, "Legalább egy szám")
                .matches(/[a-zA-Z]/, "Legalább egy kisbetű")
                .matches(/[A-Z]/, "Legalább egy nagybetű")
                .when("password", {
                    is: (val: string) => (val && val.length > 0 ? true : false),
                    then: Yup.string().oneOf(
                        [Yup.ref("password")],
                        "Különböző jelszó"
                    ),
                }),
        }),
        onSubmit: (values) => {
            if (
                skyUserDetailDialogShow
            ) {
                confirmSave(values);
                //alert(JSON.stringify(values, null, 2));
                // saveSkyUser(values).then(
                //     (response) => {
                //         // console.log(response.data);
                //         setSkyUserDto(response.data);
                //         hideDialog();
                //     },
                //     (error) => {
                //         console.log(error);
                //         hideDialog();
                //     }
                // );
            }
        },
    });


    return (
        <>
            <Dialog header="Felhasználó adatai" visible={skyUserDetailDialogShow}
                maximizable modal style={{ width: "90vw" }} onHide={() => hideDialog()}>
                <form onSubmit={formik.handleSubmit} className="p-fluid">
                    <div className="formgrid grid">
                        <div className="field col-12 md:col-6">
                            <label
                                htmlFor="name"
                                className="block text-900 font-medium mb-2"
                            >
                                Név
                            </label>
                            <InputText
                                className="w-full mb-3"
                                autoFocus
                                id="name"
                                name="name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                            />
                            {formik.touched.name &&
                                formik.errors.name ? (
                                <small className="p-error">
                                    {formik.errors.name.toString()}
                                </small>
                            ) : null}
                        </div>

                        <div className="field col-12 md:col-6">
                            <label
                                htmlFor="shortName"
                                className="block text-900 font-medium mb-2"
                            >
                                Rövid név
                            </label>
                            <InputText
                                className="w-full mb-3"
                                id="shortName"
                                name="shortName"
                                value={formik.values.shortName}
                                onChange={formik.handleChange}
                            />
                            {formik.touched.shortName &&
                                formik.errors.shortName ? (
                                <small className="p-error">
                                    {formik.errors.shortName.toString()}
                                </small>
                            ) : null}
                        </div>
                    </div>

                    <div className="formgrid grid">
                        <div className="field col-12 md:col-6">
                            <label
                                htmlFor="email"
                                className="block text-900 font-medium mb-2"
                            >
                                Email
                            </label>
                            <InputText
                                className="w-full mb-3"

                                id="email"
                                name="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                            />
                            {formik.touched.email &&
                                formik.errors.email ? (
                                <small className="p-error">
                                    {formik.errors.email.toString()}
                                </small>
                            ) : null}
                        </div>


                        <div className="field col-12 md:col-6">
                            <label
                                htmlFor="companyType"
                                className="block text-900 font-medium mb-2"
                            >
                                Szerepkör
                            </label>
                            <Dropdown
                                id="companyType"
                                className="w-full mb-3"
                                {...formik.getFieldProps(
                                    "role"
                                )}
                                options={roles}
                                onChange={formik.handleChange}
                                value={
                                    formik.values.role
                                }
                                placeholder="Válasszon cégformát!"
                            />
                            {formik.touched.role &&
                                formik.errors.role ? (
                                <small className="p-error">
                                    {formik.errors.role.toString()}
                                </small>
                            ) : null}
                        </div>
                    </div>

                    <div className="formgrid grid">
                        <div className="field col-12 md:col-6">
                            <label
                                htmlFor="password"
                                className="block text-900 font-medium mb-2"
                            >
                                Jelszó
                            </label>
                            <Password
                                id="password"
                                //toggleMask
                                className="w-full mb-3"
                                {...formik.getFieldProps(
                                    "password"
                                )}
                                header={header}
                                footer={footer}
                            />
                            {formik.touched.password &&
                                formik.errors.password ? (
                                <small className="p-error">
                                    {formik.errors.password.toString()}
                                </small>
                            ) : null}
                        </div>
                        <div className="field col">
                            <label
                                htmlFor="passwordAgain"
                                className="block text-900 font-medium mb-2"
                            >
                                Jelszó ismét
                            </label>
                            <Password
                                id="passwordAgain"
                                //toggleMask
                                className="w-full mb-3"
                                {...formik.getFieldProps(
                                    "passwordAgain"
                                )}
                                header={header}
                                footer={footer}
                            />
                            {formik.touched.passwordAgain &&
                                formik.errors.passwordAgain ? (
                                <small className="p-error">
                                    {
                                        formik.errors
                                            .passwordAgain.toString()
                                    }
                                </small>
                            ) : null}
                        </div>
                    </div>

                    <div className="formgrid grid">
                        <div className="field col-12 md:col-6">
                            <label
                                htmlFor="firstWorkingDay"
                                className="block text-900 font-medium mb-2"
                            >
                                Első munkanap
                            </label>
                            <Calendar
                                className="w-full"
                                showIcon
                                dateFormat="yy.mm.dd"
                                id="firstWorkingDay"  
                                {...formik.getFieldProps("firstWorkingDay")} 
                                />
                            {formik.errors.firstWorkingDay ? (
                                <small className="p-error">
                                    {formik.errors.firstWorkingDay.toString()}
                                </small>
                            ) : null}
                        </div>

                        <div className="field col-12 md:col-6 mt-5">
                            <div className="field-checkbox col">
                                <Checkbox
                                    id="hiddenOnTrafficTable"
                                    name="hiddenOnTrafficTable"
                                    checked={
                                        formik.values.hiddenOnTrafficTable
                                    }
                                    onChange={
                                        formik.handleChange
                                    }
                                />
                                <label
                                    htmlFor="taskList"
                                    className="block text-900 font-medium"
                                >
                                    Elrejtés a traffic tábláról
                                </label>
                            </div>
                            {formik.touched.hiddenOnTrafficTable &&
                                formik.errors.hiddenOnTrafficTable ? (
                                <small className="p-error">
                                    {formik.errors.hiddenOnTrafficTable.toString()}
                                </small>
                            ) : null}
                        </div>
                    </div>





                    <div className="formgrid grid">
                        <div className="field">
                            <Button
                                className="p-button-text"
                                type="button"
                                label="Bezárás"
                                icon="pi pi-times"
                                onClick={() => {
                                    hideDialog();
                                }}
                            />
                        </div>

                        <div className="field">
                            <Button
                                className="p-button-text"
                                type="submit"
                                label="Mentés"
                                icon="pi pi-check"
                            />
                        </div>
                    </div>
                </form>
            </Dialog>
        </>
    );
};

export default SkyUserDetail;