import axios from 'axios';
import { server } from '../config/endpoints';

const API_URL = server;

export const readAllCustomers = () => {
    return axios
        .get(API_URL + '/protected/customer')
        .then((response) => {
            return response;
        });
};

export const readAllCustomersByCaller = () => {
    return axios
        .get(API_URL + '/protected/customer/bycaller')
        .then((response) => {
            return response;
        });
};


export const readCustomer = (id: string) => {
    return axios
        .get(API_URL + '/protected/customer/' + id)
        .then((response) => {
            return response;
        });
};

export const readBranchesOfCustomer = (id: string) => {
    return axios
        .get(API_URL + '/protected/customer/branches/' + id)
        .then((response) => {
            return response;
        });
};

export const saveCustomer = (formData: any) => {
    return axios
        .post(API_URL + '/protected/customer', formData)
        .then((response) => {
            return response;
        });
};

export const deleteCustomer = (id: string) => {
    return axios
        .delete(API_URL + '/protected/customer/' + id)
        .then((response) => {
            return response;
        });
};
