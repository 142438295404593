import React, { Component, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "primeflex/primeflex.css";
import { Button } from "primereact/button";
import SkyUserDetail from "./SkyUserDetail";
import { DataTable, DataTableRowClickEventParams } from "primereact/datatable";
import { Column, ColumnEventParams } from "primereact/column";
import { RootState } from "../../state/store";
import { readAllSkyUsers } from "../../service/SkyUserService";
import { setSkyUserList } from "../../state/skyUserSlice";
import { StatusCodes } from "http-status-codes";
import {
  getCurrentUser,
  logout,
  setAuthToken,
} from "../../service/AuthService";
import { useNavigate } from "react-router";
import { setActiveMenu, setActiveView } from "../../state/navigationSlice";
import "../../css/Admin.css";
import "../../css/TrafficTable.css";
import { Panel } from "primereact/panel";
import {
  deleteTask,
  hideTask,
  readBackward,
  readForward,
  readTrafficTable,
  refreshTrafficTable,
  updateTaskComment,
  updateTaskName,
  updateTrafficTableCell,
} from "../../service/TrafficTableService";
import { classNames } from "primereact/utils";
import { Toast } from "primereact/toast";
import { ContextMenu } from "primereact/contextmenu";
import UpdateTaskCommentDialog from "./UpdateTaskCommentDialog";
import UpdateTaskNameDialog from "./UpdateTaskNameDialog";
import { setBranchList } from "../../state/branchSlice";
import {
  setTrafficTableLineCell,
  setTrafficTableLineList,
} from "../../state/trafficTableSlice";
import { confirmDialog } from "primereact/confirmdialog";
import AddTaskDialog from "./AddTaskDialog";
import AddParticipantDialog from "./AddParticipantDialog";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import { InputTextarea } from "primereact/inputtextarea";
import { useFormik } from "formik";
import * as Yup from "yup";
import TrafficTableNotes from "./TrafficTableNotes";
import HiddenTaskDialog from "./HiddenTaskDialog";
import { createEonReport } from "../../service/ReportService";
import FileSaver from "file-saver";
import { ToggleButton } from "primereact/togglebutton";
import { onRowIndexTemplate } from "../../service/IndexColumn";
import ModifyTaskDialog from "./ModifyTaskDialog";
import NewTaskDialog from "./NewTaskDialog";
import HideTaskDialog from "./HideTaskDialog";
import RenameTaskDialog from "./RenameTaskDialog";



const TrafficTable: React.FC = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  setAuthToken();
  dispatch(setActiveView("Traffic tábla"));
  dispatch(setActiveMenu("traffictable"));

  const toast: any = useRef(null);
  const [selectedTrafficTableLine, setSelectedTrafficTableLine] =
    useState<any>(null);
  const cm: any = useRef(null);
  let colors: string[] = [
    "text-blue-500",
    "text-green-500",
    "text-red-500",
    "text-cyan-500",
    "text-pink-500",
    "text-indigo-500",
    "text-teal-500",
    "text-orange-500",
    "text-bluegray-500",
    "text-purple-500",
    "text-red-500",
    "text-gray-700",
    "text-primary-500",
    "text-blue-700",
    "text-green-700",
    "text-cyan-700",
    "text-pink-700",
    "text-indigo-700",
    "text-teal-700",
    "text-orange-700",
    "text-bluegray-700",
    "text-purple-700",
    "text-red-700",
  ];
  const [participantNames, setParticipantNames] = useState<any>([]);

  const [taskId, setTaskId] = useState<string>("");
  const [projectId, setProjectId] = useState<string>("");
  const [participantId, setParticipantId] = useState<string>("");
  const [taskComment, setTaskComment] = useState<string>("");
  const [taskName, setTaskName] = useState<string>("");
  const [trafficTableColumns, setTrafficTableColumns] = useState<any>([]);
  var [trafficTableLineList2, setTrafficTableLineList2] = useState<any>(null);
  const [fromDate, setFromDate] = useState<string>("");
  const [role, setRole] = useState<String>("");
  const [loading, setLoading] = useState<boolean>(false);
  const trafficTableNotesRef = useRef(null);
  //let notes: string = "";
  const [notes, setNotes] = useState<any>(null);
  const [byHoursOrder, setByHoursOrder] = useState<boolean>(false);
  const tableReference = useRef<DataTable>(null);


  const [projectShortIdentifier, setProjectShortIdentifier] =
    useState<string>("");
  const [projectName, setProjectName] = useState<string>("");

  const [modifyTaskDialogShow, setModifyTaskDialogShow] =
    useState<boolean>(false);
  const [modifyTaskDialogChange, setModifyTaskDialogChange] =
    useState<number>(0);

  const [newTaskDialogShow, setNewTaskDialogShow] =
    useState<boolean>(false);
  const [newTaskDialogChange, setNewTaskDialogChange] =
    useState<number>(0);

  const [hideTaskDialogShow, setHideTaskDialogShow] =
    useState<boolean>(false);
  const [hideTaskDialogChange, setHideTaskDialogChange] =
    useState<number>(0);

  const [renameTaskDialogShow, setRenameTaskDialogShow] =
    useState<boolean>(false);
  const [renameTaskDialogChange, setRenameTaskDialogChange] =
    useState<number>(0);

  const [editValue, setEditValue] = useState<string>("");
  let [editValue2, setEditValue2] = useState<string>("");


  const columns = [
    { field: "date1a", header: "0101 P" },
    { field: "date1b", header: "0101 W" },
    { field: "date2a", header: "0102 P" },
    { field: "date2b", header: "0102 W" },
  ];

  const skyUserList: [] = useSelector(
    (state: RootState) => state.skyUser.skyUserList
  );

  const onCellEditCompleted = (e: ColumnEventParams) => {
    let { rowData, newValue, field, newRowData, originalEvent: event } = e;
    event.preventDefault();
    console.log("onCellEditCompleted " + newValue);
    console.log(e);

    // if (rowData[field] !== newValue) {
    //   rowData[field] = newValue;
    //   updateTrafficTableCell({
    //     fromDate: rowData["fromDate"],
    //     taskId: rowData["taskId"],
    //     cellId: field,
    //     cellValue: newValue,
    //   }).then(
    //     (response) => {
    //       refresh(byHoursOrder);
    //     },
    //     (error) => {
    //       refresh(byHoursOrder);
    //     }
    //   );
    // }
    //
    // console.log(
    //   rowData["fromDate"] +
    //   " " +
    //   rowData["taskId"] +
    //   " " +
    //   field +
    //   " " +
    //   newValue +
    //   " "
    // );

    if (rowData[field] !== editValue) {
      rowData[field] = editValue;
      updateTrafficTableCell({
        fromDate: rowData["fromDate"],
        taskId: rowData["taskId"],
        cellId: field,
        cellValue: editValue,
      }).then(
        (response) => {
          refresh(byHoursOrder);
        },
        (error) => {
          refresh(byHoursOrder);
        }
      );
    }

  };

  const onTaskCommentEditCompleted = (e: ColumnEventParams) => {
    let { rowData, newValue, field, newRowData, originalEvent: event } = e;
    //event.preventDefault();
    console.log("onTaskCommentEditCompleted " + newValue);
    console.log(e);

    if (rowData[field] !== newValue) {
      setLoading(true);
      rowData[field] = newValue;
      updateTaskComment({
        taskId: rowData["taskId"],
        comment: newValue,
      }).then(
        (response) => {
          refresh(byHoursOrder);
        },
        (error) => {
          setLoading(false);
        }
      );
    }

    // if (rowData[field] !== editValue) {
    //   setLoading(true);
    //   rowData[field] = editValue;
    //   updateTaskComment({
    //     taskId: rowData["taskId"],
    //     comment: editValue,
    //   }).then(
    //       (response) => {
    //         refresh(byHoursOrder);
    //       },
    //       (error) => {
    //         refresh(byHoursOrder);
    //         //setLoading(false);
    //       }
    //   );
    // }

  };

  const onTaskNameEditCompleted = (e: ColumnEventParams) => {
    let { rowData, newValue, field, newRowData, originalEvent: event } = e;
    // if (rowData[field] !== newValue) {
    //   rowData[field] = newValue;
    //   updateTaskName({
    //     taskId: rowData["taskId"],
    //     name: newValue,
    //   });
    // }

    if (rowData[field] !== newValue) {
      setLoading(true);
      rowData[field] = newValue;
      updateTaskName({
        taskId: rowData["taskId"],
        name: newValue,
      }).then(
        (response) => {
          refresh(byHoursOrder);
        },
        (error) => {
          setLoading(false);
        }
      );
    }


  };

  const codeEditor = (props: any) => {
    return inputTextEditor(props, "date1a");
  };

  const dynamicColumns = columns.map((col, i) => {
    return (
      <Column
        field={col.field}
        header={col.header}
        style={{ width: "50px" }}
        editor={codeEditor}
      />
    );
  });

  const dynamicTrafficTableColumns = trafficTableColumns.map((col: any) => {
    const last = col.field.charAt(col.field.length - 1);
    if (last === "a" && (role === "ADMIN" || role === "ACCOUNT")) {
      return (
        <Column
          key={col.field}
          field={col.field}
          header={col.header}
          onCellEditInit={(options) => {
            console.log('onCellEditInit ' + options.value);
            setEditValue(options.value);
          }}
          editor={(options) => textEditor(options)}
          onCellEditComplete={onCellEditCompleted}
          onBeforeCellEditShow={(e) => { console.log("onBeforeCellEditShow " + e.rowData[e.field]) }}
          onBeforeCellEditHide={(e) => { console.log("onBeforeCellEditHide " + e.rowData[e.field]) }}
          headerStyle={{ width: "65px", fontSize: "1rem" }}
          style={{ width: "65px", fontSize: "1.1rem" }}
        />
      );
    } else {
      return (
        <Column
          key={col.field}
          field={col.field}
          header={col.header}
          //headerStyle={{background: 'yellow'}}
          headerStyle={{ width: "65px", fontSize: "1rem" }}
          style={{ width: "65px", background: '#F8F9FA', fontSize: '1.1rem' }}
        />
      );
    }
  });

  const textEditor = (options: any) => {
    return (
      <InputText
        style={{ width: "50px" }}
        maxLength={4}
        //value={options.value}
        //onChange={(e) => options.editorCallback(e.target.value)}
        value={editValue}
        onChange={(e) => setEditValue(e.target.value)}
      />
    );
  };

  const taskCommentEditor = (options: any) => {
    return (
      <InputText
        defaultValue=""
        style={{ width: "98%" }}
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
      //value={editValue2}
      //onChange={(e) => {setEditValue2(e.target.value)}}
      />
    );
  };

  const taskNameEditor = (options: any) => {
    return (
      <InputText
        defaultValue=""
        style={{ width: "98%" }}
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
      />
    );
  };

  const onEditorValueChange = (props: any, value: any) => {
    let updatedProducts = [...props.value];
    trafficTableLineList2[props.rowIndex][props.field] = value;
    setTrafficTableLineList2(updatedProducts);
  };

  const inputTextEditor = (props: any, field: any) => {
    return (
      <InputText
        type="text"
        value={props.rowData[field]}
        onChange={(e) => onEditorValueChange(props, e.target.value)}
      />
    );
  };

  const [updateTaskCommentDialogShow, setUpdateTaskCommentDialogShow] =
    useState<boolean>(false);

  const [updateTaskCommentDialogChange, setUpdateTaskCommentDialogChange] =
    useState<number>(0);

  const [updateTaskNameDialogShow, setUpdateTaskNameDialogShow] =
    useState<boolean>(false);

  const [updateTaskNameDialogChange, setUpdateTaskNameDialogChange] =
    useState<number>(0);

  const [addTaskDialogShow, setAddTaskDialogShow] = useState<boolean>(false);

  const [addTaskDialogChange, setAddTaskDialogChange] = useState<number>(0);

  const [assignParticipantDialogShow, setAssignParticipantDialogShow] =
    useState<boolean>(false);

  const [assignParticipantDialogChange, setAssignParticipantDialogChange] =
    useState<number>(0);



  const [hiddenTaskDialogShow, setHiddenTaskDialogShow] =
    useState<boolean>(false);

  const [hiddenTaskDialogChange, setHiddenTaskDialogChange] =
    useState<number>(0);

  // const trafficTableLineList: any[] = useSelector(
  //     (state: RootState) => state.trafficTable.trafficTableLineList
  // );

  useEffect(() => {
    console.log("TrafficTable's useEffect");
    setRole(getCurrentUser().role);
    setLoading(true);
    readTrafficTable(byHoursOrder).then(
      (response) => {
        let tempList: any = [];
        let tableColumsTemp: any = [];

        dispatch(setActiveMenu("traffictable"));

        //trafficTableLineList3 = [...response.data.trafficTableLineList];
        setTrafficTableLineList2(response.data.trafficTableLineList);
        setFromDate(response.data.fromDate);

        //setNotes(response.data.notes);
        //trafficTableNotesRef.current?.innerText = response.data.notes;

        // dispatch(
        //     setTrafficTableLineList(
        //         response.data.trafficTableLineList
        //     )
        // );
        response.data.tableColumnList.forEach((value: any) => {
          tableColumsTemp.push({ field: value.field, header: value.header });
        });
        //  setTrafficTableLineList(response.data.trafficTableLineList);
        let colorIndex: number = -1;
        response.data.trafficTableLineList.forEach(
          (value: any, index: number) => {
            let found: boolean = false;
            for (var item of tempList) {
              if (value.participantName === item.participantName) {
                found = true;
              }
            }
            if (!found) {
              tempList.push({
                participantName: value.participantName,
                className: colors[++colorIndex],
              });
            }
          }
        );
        setParticipantNames(tempList);
        setTrafficTableColumns(tableColumsTemp);
        setLoading(false);
        console.log(tempList);
      },
      (error) => {
        setLoading(false);
        if (error.response.status === StatusCodes.UNAUTHORIZED) {
          console.log("Unauthorized access");
          logout();
          navigate("/login?status=unauthorized");
        }
      }
    );
    // }, [navigate, dispatch]);
  }, []);

  /*   const today = () => {
      setLoading(true);
      readTrafficTable().then(
        (response) => {
          let tableColumsTemp: any = [];
  
          setTrafficTableLineList2(response.data.trafficTableLineList);
          setFromDate(response.data.fromDate);
  
          response.data.tableColumnList.forEach((value: any) => {
            tableColumsTemp.push({ field: value.field, header: value.header });
          });
          setTrafficTableColumns(tableColumsTemp);
          setLoading(false);
        },
        (error) => {
          setLoading(false);
          if (error.response.status === StatusCodes.UNAUTHORIZED) {
            console.log("Unauthorized access");
          }
        }
      );
    }; */


  const today = () => {
    setLoading(true);
    readTrafficTable(byHoursOrder).then(
      (response) => {
        let tableColumsTemp: any = [];

        setTrafficTableLineList2(response.data.trafficTableLineList);
        setFromDate(response.data.fromDate);

        response.data.tableColumnList.forEach((value: any) => {
          tableColumsTemp.push({ field: value.field, header: value.header });
        });
        setTrafficTableColumns(tableColumsTemp);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        if (error.response.status === StatusCodes.UNAUTHORIZED) {
          console.log("Unauthorized access");
        }
      }
    );
  };

  /*   const backward = () => {
      setLoading(true);
      readBackward(fromDate).then(
        (response) => {
          let tableColumsTemp: any = [];
          setTrafficTableLineList2(response.data.trafficTableLineList);
          setFromDate(response.data.fromDate);
  
          response.data.tableColumnList.forEach((value: any) => {
            tableColumsTemp.push({ field: value.field, header: value.header });
          });
          setTrafficTableColumns(tableColumsTemp);
          setLoading(false);
        },
        (error) => {
          setLoading(false);
          if (error.response.status === StatusCodes.UNAUTHORIZED) {
            console.log("Unauthorized access");
          }
        }
      );
    }; */


  const backward = () => {
    setLoading(true);
    readBackward(fromDate, byHoursOrder).then(
      (response) => {
        let tableColumsTemp: any = [];
        setTrafficTableLineList2(response.data.trafficTableLineList);
        setFromDate(response.data.fromDate);

        response.data.tableColumnList.forEach((value: any) => {
          tableColumsTemp.push({ field: value.field, header: value.header });
        });
        setTrafficTableColumns(tableColumsTemp);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        if (error.response.status === StatusCodes.UNAUTHORIZED) {
          console.log("Unauthorized access");
        }
      }
    );
  };

  /*   const forward = () => {
      setLoading(true);
      readForward(fromDate).then(
        (response) => {
          let tableColumsTemp: any = [];
          setTrafficTableLineList2(response.data.trafficTableLineList);
          setFromDate(response.data.fromDate);
  
          response.data.tableColumnList.forEach((value: any) => {
            tableColumsTemp.push({ field: value.field, header: value.header });
          });
          setTrafficTableColumns(tableColumsTemp);
          setLoading(false);
        },
        (error) => {
          setLoading(false);
          if (error.response.status === StatusCodes.UNAUTHORIZED) {
            console.log("Unauthorized access");
            logout();
            navigate("/login?status=unauthorized");
          }
        }
      );
    }; */


  const forward = () => {
    setLoading(true);
    readForward(fromDate, byHoursOrder).then(
      (response) => {
        let tableColumsTemp: any = [];
        setTrafficTableLineList2(response.data.trafficTableLineList);
        setFromDate(response.data.fromDate);

        response.data.tableColumnList.forEach((value: any) => {
          tableColumsTemp.push({ field: value.field, header: value.header });
        });
        setTrafficTableColumns(tableColumsTemp);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        if (error.response.status === StatusCodes.UNAUTHORIZED) {
          console.log("Unauthorized access");
          logout();
          navigate("/login?status=unauthorized");
        }
      }
    );
  };

  /*   const refresh = () => {
      setLoading(true);
      refreshTrafficTable(fromDate).then(
        (response) => {
          let tableColumsTemp: any = [];
          setTrafficTableLineList2(response.data.trafficTableLineList);
          setFromDate(response.data.fromDate);
  
          response.data.tableColumnList.forEach((value: any) => {
            tableColumsTemp.push({ field: value.field, header: value.header });
          });
          setTrafficTableColumns(tableColumsTemp);
          setLoading(false);
        },
        (error) => {
          setLoading(false);
          if (error.response.status === StatusCodes.UNAUTHORIZED) {
            console.log("Unauthorized access");
            logout();
            navigate("/login?status=unauthorized");
          }
        }
      );
    }; */

  const refresh2 = () => {
    setLoading(true);
    refreshTrafficTable(fromDate, byHoursOrder).then(
      (response) => {
        let tableColumsTemp: any = [];
        setTrafficTableLineList2(response.data.trafficTableLineList);
        setFromDate(response.data.fromDate);

        response.data.tableColumnList.forEach((value: any) => {
          tableColumsTemp.push({ field: value.field, header: value.header });
        });
        setTrafficTableColumns(tableColumsTemp);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        if (error.response.status === StatusCodes.UNAUTHORIZED) {
          console.log("Unauthorized access");
          logout();
          navigate("/login?status=unauthorized");
        }
      }
    );
  };

  const refresh = (byHoursOrder: boolean) => {
    setLoading(true);
    refreshTrafficTable(fromDate, byHoursOrder).then(
      (response) => {
        let tableColumsTemp: any = [];
        setTrafficTableLineList2(response.data.trafficTableLineList);
        setFromDate(response.data.fromDate);

        response.data.tableColumnList.forEach((value: any) => {
          tableColumsTemp.push({ field: value.field, header: value.header });
        });
        setTrafficTableColumns(tableColumsTemp);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        if (error.response.status === StatusCodes.UNAUTHORIZED) {
          console.log("Unauthorized access");
          logout();
          navigate("/login?status=unauthorized");
        }
      }
    );
  };

  const addToParticipantNames = (
    participantName: string,
    className: string
  ) => {
    participantNames.push({
      participantName: participantName,
      className: className,
    });
  };

  const editTrafficLineTemplate = (rowData: any) => {
    return (
      <>
        <Button
          className="p-button-rounded p-button-text"
          type="button"
          onClick={() => { }}
          icon="pi pi-pencil"
          aria-label="Edit"
        />
        <Button
          className="p-button-rounded p-button-text"
          type="button"
          onClick={() => { }}
          icon="pi pi-plus"
          aria-label="Add"
        />
        <Button
          className="p-button-rounded p-button-text"
          type="button"
          onClick={() => { }}
          icon="pi pi-user"
          aria-label="User"
        />
      </>
    );
  };

  const rowClass = (rowData: any) => {
    return {
      "text-orange-500": rowData.participantRole === "ACCOUNT",
      "text-green-500": rowData.participantRole === "CREATIVE",
      "text-blue-500": rowData.participantRole === "COWORKER",
    };
  };

  const classNameForRow = (rowData: any): string => {
    for (var item of participantNames) {
      if (rowData.participantName === item.participantName) {
        return item.className;
      }
    }
    return "";
  };

  const participantNameTemplate = (rowData: any) => {
    const stockClassName = classNames({
      "text-orange-500": rowData.participantRole === "ACCOUNT",
      "text-green-500": rowData.participantRole === "CREATIVE",
      "text-blue-500": rowData.participantRole === "COWORKER",
    });

    return <div className={"a"}>{rowData.participantName}</div>;
  };


  const modifyTask = (workingTimeLine: any) => {
    console.log(workingTimeLine.taskId);
    setProjectId(workingTimeLine.projectId);
    setTaskId(workingTimeLine.taskId);
    setTaskName(workingTimeLine.taskName);
    setProjectShortIdentifier(workingTimeLine.projectShortIdentifier);
    setProjectName(workingTimeLine.projectName);
    setModifyTaskDialogShow(true);
    setModifyTaskDialogChange(Date.now());
  };

  const newTask = (workingTimeLine: any) => {
    setProjectId(workingTimeLine.projectId);
    setProjectShortIdentifier(workingTimeLine.projectShortIdentifier);
    setProjectName(workingTimeLine.projectName);
    setNewTaskDialogShow(true);
    setNewTaskDialogChange(Date.now());
  };

  const hideTask = (workingTimeLine: any) => {
    setProjectId(workingTimeLine.projectId);
    setTaskId(workingTimeLine.taskId);
    setTaskName(workingTimeLine.taskName);
    setProjectShortIdentifier(workingTimeLine.projectShortIdentifier);
    setProjectName(workingTimeLine.projectName);
    setHideTaskDialogShow(true);
    setHideTaskDialogChange(Date.now());
  };

  const renameTask = (workingTimeLine: any) => {
    setProjectId(workingTimeLine.projectId);
    setTaskId(workingTimeLine.taskId);
    setTaskName(workingTimeLine.taskName);
    setProjectShortIdentifier(workingTimeLine.projectShortIdentifier);
    setProjectName(workingTimeLine.projectName);
    setRenameTaskDialogShow(true);
    setRenameTaskDialogChange(Date.now());
  };


  const disabledTask = (trafficTableLine: any, menuName: string) => {
    let result = false;
    if (
      trafficTableLine &&
      (trafficTableLine.taskType === 3 ||
        trafficTableLine.taskType === 4)
    ) {
      result = true;
    } else if (trafficTableLine && trafficTableLine.taskType === 2 && (menuName === 'renameTask' || menuName === 'newTask')) {
      result = true;
    }
    return result;
  };

  const contextMenuModel = [
    {
      label: "Résztvevő felvétele / törlése feladathoz",
      icon: "pi pi-fw pi-clone",
      command: () => modifyTask(selectedTrafficTableLine),
      disabled: disabledTask(selectedTrafficTableLine, 'modifyTask'),
    },
    {
      label: "Új feladat felvétele",
      icon: "pi pi-fw pi-map",
      command: () => newTask(selectedTrafficTableLine),
      disabled: disabledTask(selectedTrafficTableLine, 'newTask'),
    },
    {
      label: "Feladat elrejtése",
      icon: "pi pi-fw pi-minus",
      command: () => hideTask(selectedTrafficTableLine),
      disabled: disabledTask(selectedTrafficTableLine, 'hideTask'),
    },
    {
      label: "Feladat átnevezése",
      icon: "pi pi-fw pi-tag",
      command: () => renameTask(selectedTrafficTableLine),
      disabled: disabledTask(selectedTrafficTableLine, 'renameTask'),
    },
    // {
    //   label: "Új feladat felvétele",
    //   icon: "pi pi-fw pi-map",
    //   command: () => createTask(selectedTrafficTableLine),
    // },
    // {
    //   label: "Feladat törlése",
    //   icon: "pi pi-fw pi-times",
    //   command: () => removeTask(selectedTrafficTableLine),
    // },
    // {
    //   label: "Feladat elrejtése",
    //   icon: "pi pi-fw pi-minus",
    //   command: () => hideTaskFromTable(selectedTrafficTableLine),
    // },
    // {
    //   label: "Új kreatív hozzárendelése",
    //   icon: "pi pi-fw pi-user-plus",
    //   command: () => addCreative(selectedTrafficTableLine),
    // },
  ];

  const showUpdateTaskComment = (trafficTableLine: any) => {
    setTaskId(trafficTableLine.taskId);
    setTaskComment(trafficTableLine.comment);
    setUpdateTaskCommentDialogShow(true);
    setUpdateTaskCommentDialogChange(Date.now());
    //toast.current.show({ severity: 'info', summary: 'TrafficTableLine Selected', detail: trafficTableLine.participantName });
  };

  const showUpdateTaskName = (trafficTableLine: any) => {
    setTaskId(trafficTableLine.taskId);
    setTaskName(trafficTableLine.taskName);
    setUpdateTaskNameDialogShow(true);
    setUpdateTaskNameDialogChange(Date.now());
    //toast.current.show({ severity: 'info', summary: 'TrafficTableLine Selected', detail: trafficTableLine.participantName });
  };


  const showHiddenTask = () => {
    setHiddenTaskDialogShow(true);
    setHiddenTaskDialogChange(Date.now());
  };

  const createTask = (trafficTableLine: any) => {
    setAssignParticipantDialogShow(false);
    setProjectId(trafficTableLine.projectId);
    setParticipantId(trafficTableLine.participantId);
    setTaskName("");
    setTaskComment("");
    setAddTaskDialogShow(true);
    setAddTaskDialogChange(Date.now());
  };

  const addCreative = (trafficTableLine: any) => {
    setAddTaskDialogShow(false);
    setProjectId(trafficTableLine.projectId);
    setParticipantId(trafficTableLine.participantId);

    setTaskName(trafficTableLine.taskName);
    setTaskComment(trafficTableLine.taskComment);
    setAssignParticipantDialogShow(true);
    setAssignParticipantDialogChange(Date.now());
    //toast.current.show({ severity: 'info', summary: 'Assign creative is called', detail: '' });
  };


  const onHiddenTaskDialogClose = () => {
    setNewTaskDialogShow(false);
    setModifyTaskDialogShow(false);
    setHideTaskDialogShow(false);
    setRenameTaskDialogShow(false);

    refresh(byHoursOrder);
  };



  // const removeTask = (rowData: any) => {
  //   confirmDialog({
  //     message: "Valóban törli a feladatot?",
  //     header: "Megerősítés",
  //     icon: "pi pi-exclamation-triangle",
  //     acceptClassName: "p-button-text",
  //     rejectClassName: "p-button-text",
  //     accept: () => {
  //       deleteTask(rowData.taskId).then(
  //         (response) => {
  //           dispatch(
  //             setTrafficTableLineList(response.data.trafficTableLineList)
  //           );
  //           refresh(byHoursOrder);
  //         },
  //         (error) => {
  //           if (error.response.status === StatusCodes.UNAUTHORIZED) {
  //           }
  //         }
  //       );
  //     },
  //     reject: () => { },
  //   });
  // };

  // const hideTaskFromTable = (rowData: any) => {
  //   confirmDialog({
  //     message: "Valóban elrejti a feladatot?",
  //     header: "Megerősítés",
  //     icon: "pi pi-exclamation-triangle",
  //     acceptClassName: "p-button-text",
  //     rejectClassName: "p-button-text",
  //     accept: () => {
  //       setLoading(true);
  //       hideTask(rowData.taskId).then(
  //         (response) => {
  //           dispatch(
  //             setTrafficTableLineList(response.data.trafficTableLineList)
  //           );
  //           refresh(byHoursOrder);
  //           setLoading(false);
  //         },
  //         (error) => {
  //           if (error.response.status === StatusCodes.UNAUTHORIZED) {
  //           }
  //         }
  //       );
  //     },
  //     reject: () => { },
  //   });
  // };

  const trafficTableNotesOnBlur = (event: any) => {
    //const notes = trafficTableNotesRef.current;
    console.log("onBlur");
    console.log(notes);
  }


  const trafficTableNotesOnChange = (event: any) => {
    //notes = event.target.value;
    setNotes(event.target.value);
    console.log(event.target.value);

  }

  const headerTemplate = (rowData: any) => {
    return (
      <div className="formgrid grid">
        <span className="mt-3">
          Szerződés alapadatai
        </span>

        <Button className="p-button-rounded p-button-text" icon="pi pi-arrow-left" />
      </div>
    )
  }


  const changeOrder = (value: boolean) => {
    setByHoursOrder(value);
    tableReference.current?.reset();
    refresh(value);
  };


  const formik = useFormik({
    initialValues: {
      notes: ""
    },
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: Yup.object({

    }),
    onSubmit: (values) => {

    },
  });

  const hideTaskTemplate = (rowData: any) => {
    return (
      <>
        <Button
          className="mt-0 mb-0 pt-0 pb-0 p-button-rounded p-button-text"

          style={{ height: "20px" }}
          // onClick={() => {

          // }}
          icon="pi pi-times-circle text-gray-500"
          aria-label="Edit"
        />
      </>
    );
  };

  return (
    <>
      <Toast ref={toast}></Toast>

      {role === "ADMIN" || role === "ACCOUNT" ? (
        <ContextMenu
          model={contextMenuModel}
          ref={cm}
          onHide={() => setSelectedTrafficTableLine(null)}
        />
      ) : null}

      <div className="border-2 surface-border border-round flex-auto surface-section p-3 md:p-4 lg:p-5">
        <div className="flex justify-content-start flex-wrap card-container green-container">
          <div className="flex align-items-center justify-content-center mb-1">
            <Button
              className="p-panel p-button p-button-sm p-button-success mr-3"
              icon="pi pi-refresh"
              onClick={() => {
                refresh(byHoursOrder);
              }}
            />

            <Button
              className="p-panel p-button p-button-sm p-button-info  mr-3"
              icon="pi pi-backward"
              onClick={() => {
                backward();
              }}
            />
            <Button
              className="p-panel p-button p-button-sm p-button-info  mr-3"
              icon="pi pi-home"
              onClick={() => {
                today();
              }}
            />
            <Button
              className="p-panel p-button p-button-sm p-button-info mr-3"
              icon="pi pi-forward"
              onClick={() => {
                forward();
              }}
            />

            {role !== "CREATIVE" ? (
              <Button
                className="p-panel p-button p-button-sm p-button-warning mr-3"
                icon="pi pi-verified"
                onClick={() => {
                  showHiddenTask();
                }}
              />
            ) : null}



            <ToggleButton
              className="p-panel p-button p-button-sm mr-3"
              onIcon="pi pi-align-justify" offIcon="pi pi-align-justify"
              onLabel="" offLabel=""
              checked={byHoursOrder} onChange={(e) => changeOrder(e.value)}

            />

            <TrafficTableNotes />
            {/*             <InputTextarea   
              ref={trafficTableNotesRef}  
              onBlur={(e)=>{trafficTableNotesOnBlur(e)}}      
              onChange={(e)=> trafficTableNotesOnChange(e)}     
              className="ml-5"
              value={notes}
              id="tafficTableNotes"
              style={{resize: "none", width: "60vw" }}
              rows={3}/>*/}
          </div>
        </div>

        <div className="formgrid grid">
          <div className="field col-12">
            <DataTable
              //className="admin-list"
              style={{ width: "93vw" }}
              ref={tableReference}
              //className="w-9"
              rowClassName={classNameForRow}
              contextMenuSelection={selectedTrafficTableLine}
              onContextMenuSelectionChange={(e) =>
                setSelectedTrafficTableLine(e.value)
              }
              onContextMenu={(e) => {
                console.log(e);
                cm.current.show(e.originalEvent);
              }}
              value={trafficTableLineList2}
              size="small"
              scrollable
              removableSort={true}
              //scrollHeight="800px"
              scrollHeight="70vh"
              loading={loading}
              scrollDirection="both"
              //responsiveLayout="scroll"
              //editMode="cell" trafficTableLineList2[0][1] = 'ss'
              showGridlines
            //dataKey="taskId"
            // onRowClick={(e: DataTableRowClickEventParams) => {
            //     console.log(e);
            //     colors[0] = 'ss';
            //     var myarray = [...trafficTableLineList2.current];
            //     //var myarray = JSON.parse(JSON.stringify(trafficTableLineList2)) as typeof trafficTableLineList2;
            //     myarray.push(trafficTableLineList2[0]);
            //     var row =  trafficTableLineList3.at(0);

            //     row['date1a'] = '1';
            //     setTrafficTableLineList2(myarray);

            // }}

            //selectionMode="single"
            >
              {/* <Column header="Művelet" body={editTrafficLineTemplate} style={{ flexGrow: 1, flexBasis: '200px' }} /> */}
              {/* <Column
                key="taskId"
                frozen
                field="taskId"
                header="Task ID"
                style={{ width: "70px" }}
              /> */}
              {/* <Column className="pt-0 pb-0 mt-0 mb-0" style={{ width: "45px" }} body={hideTaskTemplate} /> */}
              <Column field="rowIndex" header="" frozen style={{ width: '50px' }} body={onRowIndexTemplate} />
              <Column
                key="participantName"
                frozen
                sortable
                filter
                field="participantName"
                header="Kreatív"
                style={{ width: "125px" }}
              />
              <Column
                key="projectOwnerName"
                frozen
                sortable
                filter
                field="projectOwnerName"
                header="Account"
                style={{ width: "135px" }}
              />
              {/* <Column key="participantRole" frozen field="participantRole" header="Jogkör" style={{ minWidth: '200px' }} /> */}
              <Column
                key="projectShortIdentifier"
                frozen
                sortable
                filter
                field="projectShortIdentifier"
                header="Rövid munkaszám"
                style={{ width: "290px" }}
              />
              <Column
                key="projectName"
                frozen
                sortable
                filter
                field="projectName"
                header="Munkanév"
                style={{ width: "290px" }}
              />
              {role === "ADMIN" || role === "ACCOUNT" || role === "COWORKER" ? (
                <Column
                  key="taskName"
                  frozen
                  sortable
                  filter
                  field="taskName"
                  editor={(options) => taskNameEditor(options)}
                  onCellEditComplete={onTaskNameEditCompleted}

                  onCellEditInit={(options) => {
                    console.log('taskName onCellEditInit ' + options.value);
                  }}
                  onBeforeCellEditShow={(e) => { console.log("taskName onBeforeCellEditShow " + e.rowData[e.field]) }}
                  onBeforeCellEditHide={(e) => { console.log("taskName onBeforeCellEditHide " + e.rowData[e.field]) }}
                  header="Feladat"
                  style={{ width: "290px" }}
                />
              ) : (
                <Column
                  key="taskName"
                  frozen
                  sortable
                  filter
                  field="taskName"
                  header="Feladat"
                  style={{ width: "200px" }}
                />
              )}

              {role === "ADMIN" || role === "ACCOUNT" || role === "COWORKER" ? (
                <Column
                  key="taskComment"
                  frozen
                  sortable
                  filter
                  field="taskComment"
                  editor={(options) => taskCommentEditor(options)}
                  onCellEditComplete={onTaskCommentEditCompleted}

                  onCellEditInit={(options) => {
                    console.log('taskComment onCellEditInit ' + options.value);
                  }}
                  onBeforeCellEditShow={(e) => { console.log("taskComment onBeforeCellEditShow " + e.rowData[e.field])}}
                  onBeforeCellEditHide={(e) => { console.log("taskComment onBeforeCellEditHide " + e.rowData[e.field]) }}
                 
                  header="Megjegyzés"
                  style={{ width: "370px" }}
                />
              ) : (
                <Column
                  key="taskComment"
                  frozen
                  sortable
                  filter
                  field="taskComment"
                  header="Megjegyzés"
                  //header={headerTemplate}
                  style={{ width: "200px" }}
                />
              )}

              {dynamicTrafficTableColumns}
            </DataTable>
          </div>
        </div>

        <AddTaskDialog
          refresh={refresh2}
          projectId={projectId}
          participantId={participantId}
          taskName={taskName}
          taskComment={taskComment}
          show={addTaskDialogShow}
          change={addTaskDialogChange}
        />

        <AddParticipantDialog
          refresh={refresh2}
          projectId={projectId}
          participantId={participantId}
          taskName={taskName}
          taskComment={taskComment}
          show={assignParticipantDialogShow}
          change={assignParticipantDialogChange}
        />

        <HiddenTaskDialog
          ownTasks={false}
          onClose={onHiddenTaskDialogClose}
          show={hiddenTaskDialogShow}
          change={hiddenTaskDialogChange}
        />



        <NewTaskDialog
          refresh={onHiddenTaskDialogClose}
          projectId={projectId}
          taskId={taskId}
          projectShortIdentifier={projectShortIdentifier}
          projectName={projectName}
          taskName={taskName}
          taskComment={taskComment}
          show={newTaskDialogShow}
          change={newTaskDialogChange}
        />

        <ModifyTaskDialog
          refresh={onHiddenTaskDialogClose}
          projectId={projectId}
          taskId={taskId}
          projectShortIdentifier={projectShortIdentifier}
          projectName={projectName}
          taskName={taskName}
          show={modifyTaskDialogShow}
          change={modifyTaskDialogChange}
        />

        <HideTaskDialog
          refresh={onHiddenTaskDialogClose}
          projectId={projectId}
          taskId={taskId}
          projectShortIdentifier={projectShortIdentifier}
          projectName={projectName}
          taskName={taskName}
          show={hideTaskDialogShow}
          change={hideTaskDialogChange}
        />

        <RenameTaskDialog
          refresh={onHiddenTaskDialogClose}
          projectId={projectId}
          taskId={taskId}
          projectShortIdentifier={projectShortIdentifier}
          projectName={projectName}
          taskName={taskName}
          show={renameTaskDialogShow}
          change={renameTaskDialogChange}
        />
      </div>
    </>
  );
};

export default TrafficTable;
