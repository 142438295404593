import axios from 'axios';
import { server } from '../config/endpoints';

const API_URL = server;

export const readAllProjects = () => {
    return axios
        .get(API_URL + '/protected/project')
        .then((response) => {
            return response;
        });
};

export const readAllProjectsByFilters = (company: string, closingFilter: string) => {
    if (closingFilter === 'Összes') {
        closingFilter = '0';
    } else if (closingFilter === 'Nyitott') {
        closingFilter = '1';
    } else {
        closingFilter = '2';
    }
    return axios
        .get(API_URL + '/protected/project/all/' + company + '/' + closingFilter)
        .then((response) => {
            return response;
        });
};


export const readProject = (id: string) => {
    return axios
        .get(API_URL + '/protected/project/' + id)
        .then((response) => {
            return response;
        });
};


export const checkProjectClosing = (id: string) => {
    return axios
        .get(API_URL + '/protected/project/closing/' + id)
        .then((response) => {
            return response;
        });
};

export const saveProject = (formData: any) => {
    return axios
        .post(API_URL + '/protected/project', formData)
        .then((response) => {
            return response;
        });
};

export const deleteProject = (id: string) => {
    return axios
        .delete(API_URL + '/protected/project/' + id)
        .then((response) => {
            return response;
        });
};
