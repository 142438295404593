import { createSlice, PayloadAction } from "@reduxjs/toolkit";


interface VendorType {
    vendorList: [];
}


const initialState: VendorType = {
    vendorList: []
};

export const vendorSlice = createSlice({
    name: "VendorAction",
    initialState: initialState,
    reducers: {
        setVendorList: (state, action: PayloadAction<any>) => {
            state.vendorList = action.payload;
        },
        cleanVendorList: (state) => {
            state.vendorList = [];
        },
    },
});

export const { setVendorList } = vendorSlice.actions;
export const { cleanVendorList } = vendorSlice.actions;

export default vendorSlice.reducer;
