import React, { useEffect, useState, Fragment } from "react";
import { useDispatch } from "react-redux";
import "primeflex/primeflex.css";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useNavigate } from "react-router";
import { StatusCodes } from "http-status-codes";
import * as Yup from "yup";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { confirmDialog } from "primereact/confirmdialog";
import { setTrafficTableLineList } from "../../state/trafficTableSlice";
import { logout } from "../../service/AuthService";
import {
  hideTask,
  readAccountSkyUsersByTask,
  readAllAccountSkyUsers,
  readAllCoworkerSkyUsers,
  readAllCreativeSkyUsers,
  readCoworkerSkyUsersByTask,
  readCreativeSkyUsersByTask,
  renameTask,
} from "../../service/TaskService";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { newTask, readTask, updateTask } from "../../service/TaskService";

const RenameTaskDialog: React.FC<any> = (props: any) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [dialogShow, setDialogShow] = useState<boolean>(false);

  const [dialogChange, setDialogChange] = useState<number>(0);

  const hideDialog = () => {
    setDialogShow(false);
    formik.resetForm();
    refresh();
  };

  const [addedCreatives, setAddedCreatives] = useState<any>([]);
  const [selectedCreatives, setSelectedCreatives] = useState<any>(null);

  const [addedAccounts, setAddedAccounts] = useState<any>([]);
  const [selectedAccounts, setSelectedAccounts] = useState<any>(null);

  const [addedCoworkers, setAddedCoworkers] = useState<any>([]);
  const [selectedCoworkers, setSelectedCoworkers] = useState<any>(null);

  const [readOnly, setReadOnly] = useState<boolean>(false);

  const projectId: string = props.projectId;
  const taskId: string = props.taskId;
  const taskName: string = props.taskName;
  const refresh = props.refresh;

  const header: string =
    props.projectShortIdentifier +
    " " +
    props.projectName;

  const confirmSave = (values: any) => {
    confirmDialog({
      message: "Valóban menti a módosításokat?",
      header: "Megerősítés",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        values.taskId = taskId;
        //alert(JSON.stringify(values, null, 2));
        renameTask(values).then(
          (response) => {
            hideDialog();
          },
          (error) => {
            console.log(error);
            hideDialog();
          }
        );
        hideDialog();
      },
      reject: () => {},
    });
  };

  useEffect(() => {
    if (props.show === true) {
      setSelectedAccounts([]);
      setSelectedCoworkers([]);
      setSelectedCreatives([]);
      setReadOnly(false);
      readCreativeSkyUsersByTask(taskId).then(
        (response) => {
          setAddedCreatives(response.data.skyUserList);

          let oldList = [...selectedCreatives];
          for (var user of response.data.skyUserList) {
            if (user.workingTimeReported) {
              oldList.push(user);
              setReadOnly(true);
            }
          }
          setSelectedCreatives(oldList);
        },
        (error) => {
          console.log(error.response.status);
        }
      );
      readAccountSkyUsersByTask(taskId).then(
        (response) => {
          setAddedAccounts(response.data.skyUserList);

          let oldList = [...selectedAccounts];
          for (var user of response.data.skyUserList) {
            if (user.workingTimeReported) {
              oldList.push(user);
              setReadOnly(true);
            }
          }
          setSelectedAccounts(oldList);
        },
        (error) => {
          console.log(error.response.status);
        }
      );
      readCoworkerSkyUsersByTask(taskId).then(
        (response) => {
          setAddedCoworkers(response.data.skyUserList);
          console.log(selectedCoworkers);

          let oldList = [...selectedCoworkers];
          for (var user of response.data.skyUserList) {
            if (user.workingTimeReported) {
              oldList.push(user);
              setReadOnly(true);
            }
          }
          setSelectedCoworkers(oldList);
        },
        (error) => {
          console.log(error.response.status);
        }
      );
      console.log("taskId " + props.taskId);

      setDialogChange(props.change);
      setDialogShow(props.show);
    }
  }, [dialogChange, navigate, props.show, props.change, projectId]);

  const formik = useFormik({
    initialValues: {
      taskName: taskName,
      projectId: projectId ? projectId : "",
    },
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: Yup.object({
      taskName: Yup.string()
        .min(2, "A névnek legalább 2 karakternek kell lennie")
        .required("Kötelező kitölteni!"),
    }),
    onSubmit: (values) => {
      if (dialogShow) {
        confirmSave(values);
      }
    },
  });

  const isRowSelectable = (event: any) => {
    return false;
  };

  const rowClassName = (data: any) => {
    console.log(data);
    return "p-disabled";
  };

  return (
    <>
      <Dialog
        header="Feladat átnevezése"
        visible={dialogShow}
        closable={false}
        maximizable
        modal
        style={{ width: "70vw" }}
        onHide={() => hideDialog()}
      >
        <form onSubmit={formik.handleSubmit} className="p-fluid">

        <div className="formgrid grid">
            <div className="field col-12">
              <label
                htmlFor="taskName"
                className="block text-900 font-medium mb-2"
              >
                Munkaszám és Munkanév
              </label>
              <InputText
                className="w-full mb-3"
                autoFocus
                readOnly
                id="projectShortIdentifier"
                name="projectShortIdentifier"
                value={header}
              />
            </div>
          </div>

        

          <div className="formgrid grid">
            <div className="field col-12">
              <label
                htmlFor="taskName"
                className="block text-900 font-medium mb-2"
              >
                Feladat neve
              </label>
              <InputText
                className="w-full mb-3"
                autoFocus
                readOnly={readOnly}
                id="taskName"
                name="taskName"
                value={formik.values.taskName}
                onChange={formik.handleChange}
              />
              {formik.touched.taskName && formik.errors.taskName ? (
                <small className="p-error">
                  {formik.errors.taskName.toString()}
                </small>
              ) : null}
            </div>
          </div>

          {readOnly ? (
            <div className="formgrid grid">
              <div className="field col-12">
                <div className="flex align-items-start p-3 bg-red-100 border-round border-1 border-red-300">
                  <i className="pi pi-info-circle text-red-900 text-2xl mr-3"></i>
                  <div className="mr-1">
                    <p className="m-0 p-0 text-red-700 line-height-3 font-bold">
                      A Feladat nem nevezhető át, mert van rajta tény Munkaóra.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="formgrid grid">
              <div className="field col-12">
                <div className="flex align-items-start p-3 bg-green-100 border-round border-1 border-green-300">
                  <i className="pi pi-info-circle text-green-900 text-2xl mr-3"></i>
                  <div className="mr-1">
                    <p className="m-0 p-0 text-green-700 line-height-3 font-bold">
                      A Feladat átnevezhető, mert még nincs rajta tény Munkaóra.
                      Mielőtt átnevezed, azért előtte győződj meg róla, hogy
                      tényleg nem is dolgozott rajta senki.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="formgrid grid">
            <div className="field col-4">
              <DataTable
                isDataSelectable={isRowSelectable}
                rowClassName={rowClassName}
                header="Kreatívok"
                scrollable
                scrollHeight="40vh"
                value={addedCreatives}
                size="small"
                responsiveLayout="scroll"
                selectionMode="checkbox"
                selection={selectedCreatives}
                onSelectionChange={(e) => setSelectedCreatives(e.value)}
                dataKey="id"
              >
                <Column
                  selectionMode="multiple"
                  style={{ maxWidth: "50px" }}
                ></Column>
                <Column
                  key="shortName"
                  sortable
                  filter
                  field="shortName"
                  header="Rövid név"
                  style={{ width: "125px" }}
                />
              </DataTable>
            </div>

            <div className="field col-4">
              <DataTable
                isDataSelectable={isRowSelectable}
                rowClassName={rowClassName}
                header="Accountok"
                scrollable
                scrollHeight="40vh"
                value={addedAccounts}
                size="small"
                responsiveLayout="scroll"
                selectionMode="checkbox"
                selection={selectedAccounts}
                onSelectionChange={(e) => setSelectedAccounts(e.value)}
                dataKey="id"
              >
                <Column
                  selectionMode="multiple"
                  style={{ maxWidth: "50px" }}
                ></Column>
                <Column
                  key="shortName"
                  sortable
                  filter
                  field="shortName"
                  header="Rövid név"
                  style={{ width: "125px" }}
                />
              </DataTable>
            </div>

            <div className="field col-4">
              <DataTable
                isDataSelectable={isRowSelectable}
                rowClassName={rowClassName}
                header="Munkatársak"
                scrollable
                scrollHeight="40vh"
                value={addedCoworkers}
                size="small"
                responsiveLayout="scroll"
                selectionMode="checkbox"
                selection={selectedCoworkers}
                onSelectionChange={(e) => setSelectedCoworkers(e.value)}
                dataKey="id"
              >
                <Column
                  selectionMode="multiple"
                  style={{ maxWidth: "50px" }}
                ></Column>
                <Column
                  key="shortName"
                  sortable
                  filter
                  field="shortName"
                  header="Rövid név"
                  style={{ width: "125px" }}
                />
              </DataTable>
            </div>
          </div>

          <div className="formgrid grid">
            <div className="field">
              <Button
                className="p-button-text"
                type="button"
                label="Bezárás"
                icon="pi pi-times"
                onClick={() => {
                  hideDialog();
                }}
              />
            </div>

            {!readOnly ? (
              <div className="field">
                <Button
                  className="p-button-text"
                  type="submit"
                  label="Mentés"
                  icon="pi pi-check"
                />
              </div>
            ) : null}
          </div>
        </form>
      </Dialog>
    </>
  );
};

export default RenameTaskDialog;
