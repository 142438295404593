import React from "react";


const RightLayoutBenefits2 = () => {
    return (
        <>
            <div className="w-full lg:w-6 px-4 py-4 lg:p-7 bg-blue-50">
                <div className="text-xl text-black-alpha-90 font-500 mb-3">
                    SKY-Business Intelligence
                </div>
                <ul className="list-none p-0 m-0">
                    <li className="flex align-items-start mb-4">
                        <div>
                            <span
                                className="flex align-items-center justify-content-center bg-blue-500"
                                style={{
                                    width: "38px",
                                    height: "38px",
                                    borderRadius: "10px",
                                }}
                            >
                                <i className="text-xl text-white pi pi-check-circle"></i>
                            </span>
                        </div>
                        <div className="ml-3">
                            <span className="font-medium text-black-alpha-90">
                                Projektek kezelése
                            </span>
                            <p className="mt-2 mb-0 text-black-alpha-50 line-height-3">
                                Haszáld a nap minden percében!
                            </p>
                        </div>
                    </li>
                    <li className="flex align-items-start mb-4">
                        <div>
                            <span
                                className="flex align-items-center justify-content-center bg-pink-500"
                                style={{
                                    width: "38px",
                                    height: "38px",
                                    borderRadius: "10px",
                                }}
                            >
                                <i className="text-xl text-white pi pi-times-circle"></i>
                            </span>
                        </div>
                        <div className="ml-3">
                            <span className="font-medium text-black-alpha-90">
                                Munkaidő nyilvántartás
                            </span>
                            <p className="mt-2 mb-0 text-black-alpha-50 line-height-3">
                                Kövesd nyomon munkaóráidat!
                            </p>
                        </div>
                    </li>
                    <li className="flex align-items-start mb-4">
                        <div>
                            <span
                                className="flex align-items-center justify-content-center bg-teal-500"
                                style={{
                                    width: "38px",
                                    height: "38px",
                                    borderRadius: "10px",
                                }}
                            >
                                <i className="text-xl text-white pi pi-heart-fill"></i>
                            </span>
                        </div>
                        <div className="ml-3">
                            <span className="font-medium text-black-alpha-90">
                                Monitoring
                            </span>
                            <p className="mt-2 mb-0 text-black-alpha-50 line-height-3">
                               Ellenőrizd a megtérülést!
                            </p>
                        </div>
                    </li>
                    <li className="flex align-items-start mb-4">
                        <div>
                            <span
                                className="flex align-items-center justify-content-center bg-purple-500"
                                style={{
                                    width: "38px",
                                    height: "38px",
                                    borderRadius: "10px",
                                }}
                            >
                                <i className="text-xl text-white pi pi-percentage"></i>
                            </span>
                        </div>
                        <div className="ml-3">
                            <span className="font-medium text-black-alpha-90">
                                Reporting
                            </span>
                            <p className="mt-2 mb-0 text-black-alpha-50 line-height-3">
                                Készíts jelentést a projekt bármelyik fázisáról!
                            </p>
                        </div>
                    </li>

                </ul>
            </div>
        </>
    );
};

export default RightLayoutBenefits2;
