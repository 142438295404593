import React, { useEffect, useState, Fragment } from "react";
import { useDispatch } from "react-redux";
import "primeflex/primeflex.css";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useNavigate } from "react-router";
import { StatusCodes } from "http-status-codes";
import { logout } from "../../service/AuthService";
import * as Yup from "yup";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { confirmDialog } from "primereact/confirmdialog";
import { readAllCustomers, readCustomer, saveCustomer } from "../../service/CustomerService";
import { setCustomerList } from "../../state/customerSlice";
import { DataTable } from "primereact/datatable";
import { Column, ColumnEventParams } from "primereact/column";
import {readAllVendors, readVendor, saveVendor} from "../../service/VendorService";
import {setVendorList} from "../../state/vendorSlice";
import {readAllContributors, readContributor, saveContributor} from "../../service/ContributorService";
import {setContributorList} from "../../state/contributorSlice";



const ContributorDetail: React.FC<any> = (props: any) => {

    let navigate = useNavigate();
    const dispatch = useDispatch();

    const [
        contributorDetailDialogShow,
        setContributorDetailDialogShow,
    ] = useState<boolean>(false);

    const [
        contributorDetailDialogChange,
        setContributorDetailDialogChange,
    ] = useState<number>(0);

    const hideDialog = () => {
        setContributorDetailDialogShow(false);
        setContributorDto(null);
        formik.resetForm();
        refreshContributorList();
    };

    const [contributorDto, setContributorDto] = useState<any>();

    const contributorId: any = props.id;



    const confirmSave = (values: any) => {
        confirmDialog({
            message: 'Valóban menti a módosításokat?',
            header: 'Megerősítés',
            icon: 'pi pi-exclamation-triangle',
            acceptClassName: 'p-button-text',
            rejectClassName: 'p-button-text',
            accept: () => {
                //alert(JSON.stringify(values, null, 2));
                saveContributor(values).then(
                    (response) => {
                        setContributorDto(response.data);
                        hideDialog();
                    },
                    (error) => {
                        console.log(error);
                        hideDialog();
                    }
                );

            },
            reject: () => { }
        });
    }


    const refreshContributorList = () => {
        readAllContributors().then(
            (response) => {
                dispatch(
                    setContributorList(
                        response.data.contributorList
                    )
                );
            },
            (error) => {
                // console.log(error.response.status);
                if (error.response.status === StatusCodes.UNAUTHORIZED) {
                    //TODO login oldalon üzenet --> Lejárt a munkamenet, jelentkezzen be
                    logout();
                    navigate("/login?status=unauthorized");
                }
            }
        );
    };


    useEffect(() => {
        if (
            props.show === true
        ) {
            console.log("ContributorDetail's useEffect");
            setContributorDto(null);
            if (contributorId !== null && contributorId !== "") {
                readContributor(contributorId).then(
                    (response) => {
                        setContributorDto(response.data);
                    },
                    (error) => {
                        if (
                            error.response.status === StatusCodes.METHOD_NOT_ALLOWED
                        ) {
                            console.log("A név már szerepel a rendszerben");
                        }
                        if (error.response.status === StatusCodes.UNAUTHORIZED) {
                            logout();
                            navigate("/login?status=unauthorized");
                        }
                    }
                );
            }
            setContributorDetailDialogChange(props.change);
            setContributorDetailDialogShow(props.show);
        }
    }, [contributorDetailDialogChange, navigate, props.show, props.change, contributorId]);

    const formik = useFormik({
        initialValues: {
            id: contributorDto && contributorDto.id ? contributorDto.id : "",
            name: contributorDto && contributorDto.name ? contributorDto.name : "",
            shortName: contributorDto && contributorDto.shortName ? contributorDto.shortName : "",
        },
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: Yup.object({
            name: Yup.string()
                .min(2, "A névnek legalább 2 karakternek kell lennie")
                .required("Kötelező kitölteni!"),
            shortName: Yup.string()
                .min(2, "A névnek legalább 2 karakternek kell lennie")
                .required("Kötelező kitölteni!"),
        }),
        onSubmit: (values) => {
            if (
                contributorDetailDialogShow
            ) {
                confirmSave(values);
            }
        },
    });




    return (
        <>
            <Dialog header="Egyéb közreműködő adatai" visible={contributorDetailDialogShow}
                maximizable modal closable={false} style={{ width: "90vw" }} onHide={() => hideDialog()}>
                <form onSubmit={formik.handleSubmit} className="p-fluid">
                    <div className="formgrid grid">
                        <div className="field col-12 md:col-6">
                            <label
                                htmlFor="shortName"
                                className="block text-900 font-medium mb-2"
                            >
                                Rövid név
                            </label>
                            <InputText
                                className="w-full mb-3"
                                autoFocus
                                id="shortName"
                                name="shortName"
                                value={formik.values.shortName}
                                onChange={formik.handleChange}
                            />
                            {formik.touched.shortName &&
                                formik.errors.shortName ? (
                                <small className="p-error">
                                    {formik.errors.shortName.toString()}
                                </small>
                            ) : null}
                        </div>

                        <div className="field col-12 md:col-6">
                            <label
                                htmlFor="name"
                                className="block text-900 font-medium mb-2"
                            >
                                Név
                            </label>
                            <InputText
                                className="w-full mb-3"
                                id="name"
                                name="name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                            />
                            {formik.touched.name &&
                                formik.errors.name ? (
                                <small className="p-error">
                                    {formik.errors.name.toString()}
                                </small>
                            ) : null}
                        </div>
                    </div>



                    <div className="formgrid grid">
                        <div className="field">
                            <Button
                                className="p-button-text"
                                type="button"
                                label="Bezárás"
                                icon="pi pi-times"
                                onClick={() => {
                                    hideDialog();
                                }}
                            />
                        </div>

                        <div className="field">
                            <Button
                                className="p-button-text"
                                type="submit"
                                label="Mentés"
                                icon="pi pi-check"
                            />
                        </div>
                    </div>
                </form>
            </Dialog>
        </>
    );
};

export default ContributorDetail;





