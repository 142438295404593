import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "primeflex/primeflex.css";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column, ColumnEventParams } from "primereact/column";
import { RootState } from "../../state/store";
import { StatusCodes } from "http-status-codes";
import {
  getCurrentUser,
  logout,
  setAuthToken,
} from "../../service/AuthService";
import { useNavigate } from "react-router";
import { setActiveMenu, setActiveView } from "../../state/navigationSlice";
import "../../css/Admin.css";
import { deleteTask, hideTask } from "../../service/TrafficTableService";
import { classNames } from "primereact/utils";
import { Toast } from "primereact/toast";
import { ContextMenu } from "primereact/contextmenu";
import { confirmDialog } from "primereact/confirmdialog";
import { InputText } from "primereact/inputtext";
import {
  closeDay,
  readBackward,
  readForward,
  readToday,
  refreshWorkingTime,
  updateWorkingTimeCell,
} from "../../service/WorkingTimeService";
import { setTrafficTableLineList } from "../../state/workingHoursSlice";
import MessageDialog from "../../component/MessageDialog";
import MyWorkingTimeReportDialog from "./MyWorkingTimeReportDialog";
import { createMyWorkingTimeReport } from "../../service/MyWorkingTimeReportService";
import FileSaver from "file-saver";
import { BlockUI } from "primereact/blockui";
import { ToggleButton } from "primereact/togglebutton";
import "../../css/WorkingTime.css";
import HiddenTaskDialog from "./HiddenTaskDialog";
import { onRowIndexTemplate } from "../../service/IndexColumn";
import NewTaskDialog from "./NewTaskDialog";
import ModifyTaskDialog from "./ModifyTaskDialog";
import HideTaskDialog from "./HideTaskDialog";
import RenameTaskDialog from "./RenameTaskDialog";

const WorkingTime: React.FC = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  setAuthToken();
  dispatch(setActiveView("Munkaóráim"));
  dispatch(setActiveMenu("workinghours"));

  const toast: any = useRef(null);
  const [selectedWorkingTimeLine, setSelectedWorkingTimeLine] =
    useState<any>(null);
  const cm: any = useRef(null);
  let colors: string[] = [
    "text-blue-500",
    "text-green-500",
    "text-purple-500",
    "text-cyan-500",
    "text-pink-500",
    "text-indigo-500",
    "text-teal-500",
    "text-orange-500",
    "text-bluegray-500",
    "text-purple-500",
    "text-red-500",
    "text-gray-500",
    "text-primary-500",
  ];
  const [taskTypes, setTaskTypes] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [closedDay, setClosedDay] = useState<boolean>(false);
  const [editing, setEditing] = useState<boolean>(false);
  const [byHoursOrder, setByHoursOrder] = useState<boolean>(false);

  const [taskId, setTaskId] = useState<string>("");
  const [projectId, setProjectId] = useState<string>("");
  const [participantId, setParticipantId] = useState<string>("");
  const [projectShortIdentifier, setProjectShortIdentifier] =
    useState<string>("");
  const [projectName, setProjectName] = useState<string>("");
  const [taskComment, setTaskComment] = useState<string>("");
  const [actualDate, setActualDate] = useState<string>("");
  const [taskName, setTaskName] = useState<string>("");
  const [trafficTableColumns, setTrafficTableColumns] = useState<any>([]);
  var [trafficTableLineList2, setTrafficTableLineList2] = useState<any>(null);
  const [blockedPage, setBlockedPage] = useState<boolean>(false);
  const [blockedMessage, setBlockedMessage] = useState<string>("");
  const [workingHours, setWorkingHours] = useState<number>(0);

  const tableReference = useRef<DataTable>(null);
  const [role, setRole] = useState<String>("");
  const [editValue, setEditValue] = useState<any>("");

  const [messageDialogShow, setMessageDialogShow] = useState<Number>(0);
  const [messageDialogHeader, setMessageDialogHeader] = useState<string>("");
  const [messageDialogBody, setMessageDialogBody] = useState<string>("");
  const [messageDialogError, setMessageDialogError] = useState<boolean>(false);

  const [hiddenTaskDialogShow, setHiddenTaskDialogShow] =
    useState<boolean>(false);
  const [hiddenTaskDialogChange, setHiddenTaskDialogChange] =
    useState<number>(0);

  const columns = [
    { field: "date1a", header: "0101 P" },
    { field: "date1b", header: "0101 W" },
    { field: "date2a", header: "0102 P" },
    { field: "date2b", header: "0102 W" },
  ];

  const onCellEditCompleted = (e: ColumnEventParams) => {
    let { rowData, newValue, field, newRowData, originalEvent: event } = e;
    event.preventDefault();
    console.log("onCellEditCompleted " + newValue);
    console.log(e);

    // if (rowData[field] !== newValue) {
    //   setLoading(true);
    //   rowData[field] = newValue;
    //   updateWorkingTimeCell({
    //     actualDate: rowData["actualDate"],
    //     taskId: rowData["taskId"],
    //     cellId: field,
    //     cellValue: newValue,
    //   }).then(
    //     (response) => {
    //       refresh(byHoursOrder);
    //     },
    //     (error) => {
    //       refresh(byHoursOrder);
    //       toast.current.show({
    //         severity: "error",
    //         summary: "Munkaóra",
    //         detail: "A munkaóra mentés sikertelen",
    //       });
    //     }
    //   );
    // }
    // console.log(rowData["actualDate"] + " " + rowData["taskId"] + " " + field + " " + newValue + " " );


    if (rowData[field] !== editValue) {
      setLoading(true);
      rowData[field] = editValue;
      updateWorkingTimeCell({
        actualDate: rowData["actualDate"],
        taskId: rowData["taskId"],
        cellId: field,
        cellValue: editValue,
      }).then(
        (response) => {
          refresh(byHoursOrder);
        },
        (error) => {
          refresh(byHoursOrder);
          toast.current.show({
            severity: "error",
            summary: "Munkaóra",
            detail: "A munkaóra mentés sikertelen",
          });
        }
      );
    }
    console.log(rowData["actualDate"] + " " + rowData["taskId"] + " " + field + " " + editValue + " " );


    setEditing(false);

  };

  const codeEditor = (props: any) => {
    return inputTextEditor(props, "date1a");
  };

  const dynamicColumns = columns.map((col, i) => {
    return (
      <Column
        field={col.field}
        header={col.header}
        style={{ width: "50px" }}
        editor={codeEditor}
      />
    );
  });

  const dynamicWorkingTimeColumns = trafficTableColumns.map((col: any) => {
    const last = col.field.charAt(col.field.length - 1);
    if (closedDay || col.field === "planned") {
      return (
        <Column
          key={col.field}
          field={col.field}
          header={col.header}
          headerStyle={{
            width: "65px",
            background: "#F8F9FA",
            fontSize: "1rem",
          }}
          style={{ width: "65px", background: "#F8F9FA", fontSize: "0.9rem" }}
        />
      );
    } else {
      return (
        <Column
          key={col.field}
          field={col.field}
          header={col.header}
          headerStyle={{
            width: "65px",
            background: "#F8F9FA",
            fontSize: "1rem",
          }}
          editor={(options) => textEditor(options)}
          onCellEditInit={(options) => {
            console.log('onCellEditInit ' +options.value);
            setEditValue(options.value);
          }}
          onCellEditComplete={onCellEditCompleted}
          onBeforeCellEditShow={(e) => {console.log("onBeforeCellEditShow " + e.rowData[e.field])}}
          onBeforeCellEditHide={(e) => {console.log("onBeforeCellEditHide "  + e.rowData[e.field])}}
          style={{ width: "65px", fontSize: "1.1rem" }}
        />
      );
    }
  });

  const textEditor = (options: any) => {
    //console.log("textEditor " + options.value);
    //console.log(options);
    return (
      <InputText
        defaultValue=""
        style={{ width: "50px" }}
        maxLength={4}
        // value={options.rowData[options.field]}
        // onChange={(e) => options.editorCallback(e.target.value)}
        value={editValue}
        onChange={(e) => setEditValue(e.target.value)}
      />
    );
  };

  const onEditorValueChange = (props: any, value: any) => {
    let updatedProducts = [...props.value];
    trafficTableLineList2[props.rowIndex][props.field] = value;
    setTrafficTableLineList2(updatedProducts);
  };

  const inputTextEditor = (props: any, field: any) => {
    return (
      <InputText
        type="text"
        value={props.rowData[field]}
        onChange={(e) => onEditorValueChange(props, e.target.value)}
      />
    );
  };

  const [updateTaskCommentDialogShow, setUpdateTaskCommentDialogShow] =
    useState<boolean>(false);

  const [updateTaskCommentDialogChange, setUpdateTaskCommentDialogChange] =
    useState<number>(0);

  const [updateTaskNameDialogShow, setUpdateTaskNameDialogShow] =
    useState<boolean>(false);

  const [updateTaskNameDialogChange, setUpdateTaskNameDialogChange] =
    useState<number>(0);

  const [newTaskDialogShow, setNewTaskDialogShow] = useState<boolean>(false);

  const [newTaskDialogChange, setNewTaskDialogChange] = useState<number>(0);

  const [hideTaskDialogShow, setHideTaskDialogShow] = useState<boolean>(false);

  const [hideTaskDialogChange, setHideTaskDialogChange] = useState<number>(0);

  const [renameTaskDialogShow, setRenameTaskDialogShow] =
    useState<boolean>(false);

  const [renameTaskDialogChange, setRenameTaskDialogChange] =
    useState<number>(0);

  const [modifyTaskDialogShow, setModifyTaskDialogShow] =
    useState<boolean>(false);

  const [modifyTaskDialogChange, setModifyTaskDialogChange] =
    useState<number>(0);

  const [assignParticipantDialogShow, setAssignParticipantDialogShow] =
    useState<boolean>(false);

  const [assignParticipantDialogChange, setAssignParticipantDialogChange] =
    useState<number>(0);

  const [workingTimeReportDialogShow, setWorkingTimeReportDialogShow] =
    useState<boolean>(false);

  const [workingTimeReportDialogChange, setWorkingTimeReportDialogChange] =
    useState<number>(0);

  const trafficTableLineList: any[] = useSelector(
    (state: RootState) => state.workingHours.trafficTableLineList
  );

  const hideDialog = () => { };

  const showWorkingTimeReportDialog = () => {
    console.log("showWorkingTimeReportDialog is called");
    setWorkingTimeReportDialogShow(true);
    setWorkingTimeReportDialogChange(Date.now());
  };


  const today = () => {
    setLoading(true);
    readToday(byHoursOrder).then(
      (response) => {
        let tableColumsTemp: any = [];

        setTrafficTableLineList2(response.data.workingTimeLineList);
        setActualDate(response.data.actualDate);
        setClosedDay(response.data.closed);
        setWorkingHours(response.data.workingHours);

        response.data.tableColumnList.forEach((value: any) => {
          tableColumsTemp.push({ field: value.field, header: value.header });
        });
        setTrafficTableColumns(tableColumsTemp);
        setLoading(false);
      },
      (error) => {
        if (error.response.status === StatusCodes.UNAUTHORIZED) {
          console.log("Unauthorized access");
        }
        setLoading(false);
      }
    );
  };


  const backward = () => {
    setLoading(true);
    readBackward(actualDate, byHoursOrder).then(
      (response) => {
        let tableColumsTemp: any = [];
        setTrafficTableLineList2(response.data.workingTimeLineList);
        setActualDate(response.data.actualDate);
        setClosedDay(response.data.closed);
        setWorkingHours(response.data.workingHours);

        response.data.tableColumnList.forEach((value: any) => {
          tableColumsTemp.push({ field: value.field, header: value.header });
        });
        setTrafficTableColumns(tableColumsTemp);
        setLoading(false);
      },
      (error) => {
        if (error.response.status === StatusCodes.UNAUTHORIZED) {
          console.log("Unauthorized access");
        }
        setLoading(false);
      }
    );
  };


  const forward = () => {
    setLoading(true);
    readForward(actualDate, byHoursOrder).then(
      (response) => {
        let tableColumsTemp: any = [];
        setTrafficTableLineList2(response.data.workingTimeLineList);
        setActualDate(response.data.actualDate);
        setClosedDay(response.data.closed);
        setWorkingHours(response.data.workingHours);

        response.data.tableColumnList.forEach((value: any) => {
          tableColumsTemp.push({ field: value.field, header: value.header });
        });
        setTrafficTableColumns(tableColumsTemp);
        setLoading(false);
      },
      (error) => {
        if (error.response.status === StatusCodes.UNAUTHORIZED) {
          console.log("Unauthorized access");
        }
        setLoading(false);
      }
    );
  };



  const refresh = (byHoursOrder: boolean) => {
    setLoading(true);
    console.log(byHoursOrder);
    refreshWorkingTime(actualDate, byHoursOrder).then(
      (response) => {
        let tableColumsTemp: any = [];
        setTrafficTableLineList2(response.data.workingTimeLineList);
        setActualDate(response.data.actualDate);
        setClosedDay(response.data.closed);
        setWorkingHours(response.data.workingHours);

        response.data.tableColumnList.forEach((value: any) => {
          tableColumsTemp.push({ field: value.field, header: value.header });
        });
        setTrafficTableColumns(tableColumsTemp);
        setLoading(false);
      },
      (error) => {
        if (error.response.status === StatusCodes.UNAUTHORIZED) {
          console.log("Unauthorized access");
        }
        setLoading(false);
      }
    );
  };

  const changeOrder = (value: boolean) => {
    setByHoursOrder(value);
    tableReference.current?.reset();
    refresh(value);
  };



  const closeWorkingDay = () => {
    setLoading(true);
    closeDay(actualDate, byHoursOrder).then(
      (response) => {
        let tableColumsTemp: any = [];
        setTrafficTableLineList2(response.data.workingTimeLineList);
        setActualDate(response.data.actualDate);
        setClosedDay(response.data.closed);

        response.data.tableColumnList.forEach((value: any) => {
          tableColumsTemp.push({ field: value.field, header: value.header });
        });
        setTrafficTableColumns(tableColumsTemp);
        setLoading(false);
        toast.current.show({
          severity: "success",
          summary: "Napzárás",
          detail: "A nap sikeresen lezárva",
        });
      },
      (error) => {
        console.log(error.response);
        if (error.response.status === StatusCodes.CONFLICT) {
          setMessageDialogHeader("Ellenőrzési hiba");
          setMessageDialogBody(
            "Zárja le a korábbi napot: " + error.response.data.openedDay
          );
        } else if (error.response.status === StatusCodes.EXPECTATION_FAILED) {
          setMessageDialogHeader("Ellenőrzési hiba");
          setMessageDialogBody(error.response.data.message);
        }

        setMessageDialogError(true);
        setMessageDialogShow(Date.now());
        setLoading(false);
      }
    );
  };

  const confirmCloseWorkingDay = () => {
    confirmDialog({
      message: "Valóban zárja a munkanapot?",
      header: "Megerősítés",
      icon: "pi pi-exclamation-triangle",
      acceptClassName: "p-button-text",
      rejectClassName: "p-button-text",
      accept: () => {
        closeWorkingDay();
      },
      reject: () => { },
    });
  };

  useEffect(() => {
    console.log("WorkingTime's useffect is called");
    setRole(getCurrentUser().role);
    setLoading(true);
    readToday(byHoursOrder).then(
      (response) => {
        let taskTypesTemp: any = [];
        let tableColumsTemp: any = [];

        //trafficTableLineList3 = [...response.data.trafficTableLineList];
        setTrafficTableLineList2(response.data.workingTimeLineList);
        setActualDate(response.data.actualDate);
        setClosedDay(response.data.closed);
        setWorkingHours(response.data.workingHours);

        response.data.tableColumnList.forEach((value: any) => {
          tableColumsTemp.push({ field: value.field, header: value.header });
        });

        response.data.workingTimeLineList.forEach(
          (value: any, index: number) => {
            taskTypesTemp.push({
              taskType: value.taskType,
              className: colors[value.taskType],
            });
          }
        );
        setTaskTypes(taskTypesTemp);
        setTrafficTableColumns(tableColumsTemp);
        setLoading(false);
      },
      (error) => {
        if (error.response.status === StatusCodes.UNAUTHORIZED) {
          console.log("Unauthorized access");
          logout();
          navigate("/login?status=unauthorized");
        }
        setLoading(false);
      }
    );
  }, []);

  const addToParticipantNames = (
    participantName: string,
    className: string
  ) => {
    taskTypes.push({ participantName: participantName, className: className });
  };

  const onHiddenTaskDialogClose = () => {
    setNewTaskDialogShow(false);
    setModifyTaskDialogShow(false);
    setHideTaskDialogShow(false);
    setRenameTaskDialogShow(false);

    refresh(byHoursOrder);
  };

  const editTrafficLineTemplate = (rowData: any) => {
    return (
      <>
        <Button
          className="p-button-rounded p-button-text"
          type="button"
          onClick={() => { }}
          icon="pi pi-pencil"
          aria-label="Edit"
        />
        <Button
          className="p-button-rounded p-button-text"
          type="button"
          onClick={() => { }}
          icon="pi pi-plus"
          aria-label="Add"
        />
        <Button
          className="p-button-rounded p-button-text"
          type="button"
          onClick={() => { }}
          icon="pi pi-user"
          aria-label="User"
        />
      </>
    );
  };

  const rowClass = (rowData: any) => {
    return {
      "text-orange-500": rowData.participantRole === "ACCOUNT",
      "text-red-500": rowData.participantRole === "CREATIVE",
      "text-blue-500": rowData.participantRole === "COWORKER",
    };
  };

  const classNameForRow = (rowData: any): string => {
    for (var item of taskTypes) {
      if (rowData.taskType === item.taskType) {
        return item.className;
      }
    }
    return "";
  };

  const visibleTask = (trafficTableLine: any, menuName: string) => {
    let result = false;
    if (
      trafficTableLine &&
      trafficTableLine.taskType === 3 &&
      trafficTableLine.taskType === 4
    ) {
      result = true;
    } else if (trafficTableLine && trafficTableLine.taskType === 2 && ('newTask' === menuName || 'renameTask' === menuName)) {
      result = true;
    }
    return result;
  };

  const disabledTask = (trafficTableLine: any, menuName: string) => {
    let result = false;
    if (
      trafficTableLine &&
      (trafficTableLine.taskType === 3 ||
        trafficTableLine.taskType === 4)
    ) {
      result = true;
    } else if (trafficTableLine && trafficTableLine.taskType === 2 && (menuName === 'renameTask' || menuName === 'newTask')) {
      result = true;
    }
    return result;
  };


  const disabledTask1 = (trafficTableLine: any) => {
    let result = false;
    if (
      trafficTableLine &&
      (trafficTableLine.taskType === 3 ||
        trafficTableLine.taskType === 4)
    ) {
      result = true;
    }
    return result;
  };

  const disabledTask2 = (trafficTableLine: any) => {
    let result = false;
    if (trafficTableLine && trafficTableLine.taskType === 2) {
      result = true;
    }
    return result;
  };

  const participantNameTemplate = (rowData: any) => {
    const stockClassName = classNames({
      "text-red-500": rowData.participantRole === "ACCOUNT",
      "text-green-500": rowData.participantRole === "CREATIVE",
      "text-blue-500": rowData.participantRole === "COWORKER",
    });

    return <div className={"a"}>{rowData.participantName}</div>;
  };

  const contextMenuModel = [
    {
      label: "Résztvevő felvétele / törlése feladathoz",
      icon: "pi pi-fw pi-clone",
      command: () => modifyTask(selectedWorkingTimeLine),
      disabled: disabledTask(selectedWorkingTimeLine, 'modifyTask'),
    },
    {
      label: "Új feladat felvétele",
      icon: "pi pi-fw pi-map",
      command: () => newTask(selectedWorkingTimeLine),
      disabled: disabledTask(selectedWorkingTimeLine, 'newTask'),
    },
    {
      label: "Feladat elrejtése",
      icon: "pi pi-fw pi-minus",
      command: () => hideTask(selectedWorkingTimeLine),
      disabled: disabledTask(selectedWorkingTimeLine, 'hideTask'),
    },
    {
      label: "Feladat átnevezése",
      icon: "pi pi-fw pi-tag",
      command: () => renameTask(selectedWorkingTimeLine),
      disabled: disabledTask(selectedWorkingTimeLine, 'renameTask'),
    },


  ];

  const showHiddenTask = () => {
    setHiddenTaskDialogShow(true);
    setHiddenTaskDialogChange(Date.now());
  };

  const updateTaskComment = (trafficTableLine: any) => {
    setTaskId(trafficTableLine.taskId);
    setTaskComment(trafficTableLine.comment);
    setUpdateTaskCommentDialogShow(true);
    setUpdateTaskCommentDialogChange(Date.now());
    //toast.current.show({ severity: 'info', summary: 'TrafficTableLine Selected', detail: trafficTableLine.participantName });
  };

  const updateTaskName = (trafficTableLine: any) => {
    setTaskId(trafficTableLine.taskId);
    setTaskName(trafficTableLine.taskName);
    setUpdateTaskNameDialogShow(true);
    setUpdateTaskNameDialogChange(Date.now());
    //toast.current.show({ severity: 'info', summary: 'TrafficTableLine Selected', detail: trafficTableLine.participantName });
  };

  const newTask = (workingTimeLine: any) => {
    setProjectId(workingTimeLine.projectId);
    setProjectShortIdentifier(workingTimeLine.projectShortIdentifier);
    setProjectName(workingTimeLine.projectName);
    setNewTaskDialogShow(true);
    setNewTaskDialogChange(Date.now());
  };

  const hideTask = (workingTimeLine: any) => {
    setProjectId(workingTimeLine.projectId);
    setTaskId(workingTimeLine.taskId);
    setTaskName(workingTimeLine.taskName);
    setProjectShortIdentifier(workingTimeLine.projectShortIdentifier);
    setProjectName(workingTimeLine.projectName);
    setHideTaskDialogShow(true);
    setHideTaskDialogChange(Date.now());
  };

  const renameTask = (workingTimeLine: any) => {
    setProjectId(workingTimeLine.projectId);
    setTaskId(workingTimeLine.taskId);
    setTaskName(workingTimeLine.taskName);
    setProjectShortIdentifier(workingTimeLine.projectShortIdentifier);
    setProjectName(workingTimeLine.projectName);
    setRenameTaskDialogShow(true);
    setRenameTaskDialogChange(Date.now());
  };

  const modifyTask = (workingTimeLine: any) => {
    console.log(workingTimeLine.taskId);
    setProjectId(workingTimeLine.projectId);
    setTaskId(workingTimeLine.taskId);
    setTaskName(workingTimeLine.taskName);
    setProjectShortIdentifier(workingTimeLine.projectShortIdentifier);
    setProjectName(workingTimeLine.projectName);
    setModifyTaskDialogShow(true);
    setModifyTaskDialogChange(Date.now());
  };

  const addCreative = (trafficTableLine: any) => {
    setProjectId(trafficTableLine.projectId);
    setParticipantId(trafficTableLine.participantId);
    setTaskName("");
    setTaskComment("");
    setAssignParticipantDialogShow(true);
    setAssignParticipantDialogChange(Date.now());
    //toast.current.show({ severity: 'info', summary: 'Assign creative is called', detail: '' });
  };

  const removeTask = (rowData: any) => {
    confirmDialog({
      message: "Valóban törli a feladatot?",
      header: "Megerősítés",
      icon: "pi pi-exclamation-triangle",
      acceptClassName: "p-button-text",
      rejectClassName: "p-button-text",
      accept: () => {
        deleteTask(rowData.taskId).then(
          (response) => {
            dispatch(
              setTrafficTableLineList(response.data.trafficTableLineList)
            );
          },
          (error) => {
            if (error.response.status === StatusCodes.UNAUTHORIZED) {
            }
          }
        );
      },
      reject: () => { },
    });
  };

  const onWorkingTimeReportDialogClose = (data: any) => {
    setWorkingTimeReportDialogShow(false);
    setBlockedPage(true);
    setBlockedMessage("Munkaóra jelentés készítése folyamatban...");
    let values: any = { fromDate: data.fromDate, toDate: data.toDate };
    createMyWorkingTimeReport(values).then(
      (response) => {
        console.log(response);
        setBlockedPage(false);
        let blob = new Blob([response.data]);
        console.log(getCurrentUser().name);

        FileSaver(
          blob,
          "Munkaórák-" +
          getCurrentUser().name.replaceAll(" ", "") +
          "-" +
          +new Date(values.fromDate)
            .toLocaleDateString("hu-HU")
            .replaceAll(" ", "")
            .replaceAll(".", "") +
          "-" +
          new Date(values.toDate)
            .toLocaleDateString("hu-HU")
            .replaceAll(" ", "")
            .replaceAll(".", "") +
          ".xlsx"
        );
      },
      (error) => {
          console.log(error);
        if (error.response.status === StatusCodes.EXPECTATION_FAILED) {
          setMessageDialogHeader("Ellenőrzési hiba");
          setMessageDialogBody(
            "A lekérdezési időszak lezáratlan napot tartalmaz. " +
            "Töltsd ki és zárd le előbb! (Ha a mai napot is le szeretnéd kérdezni, akkor várd meg a 17.30 órát, " +
            "zárd le a mai napot és utána azt is le tudod már kérdezni.)"
          );
        } else {
            setMessageDialogHeader("Időtúllépési hiba");
            setMessageDialogBody(
                "Túl hosszú kérés!"
            );
        }

        setMessageDialogError(true);
        setMessageDialogShow(Date.now());

        setBlockedPage(false);
      }
    );
  };

  return (
    <>
      <Toast ref={toast}></Toast>

      {role === "ADMIN" || role === "ACCOUNT" || role === "COWORKER" ? (
        <ContextMenu

          model={contextMenuModel}
          ref={cm}
          onHide={() => setSelectedWorkingTimeLine(null)}
        />
      ) : null}

      <BlockUI
        blocked={blockedPage}
        fullScreen
        template={
          <div
            className="text-center text-teal-50"
            style={{ fontSize: "2rem" }}
          >
            <i className="pi pi-spin pi-spinner" style={{ fontSize: "3rem" }} />
            <br />
            {blockedMessage}
          </div>
        }
      />

      <div className="border-2 surface-border border-round flex-auto surface-section p-3 md:p-4 lg:p-5">
        <MessageDialog
          messageDialogError={messageDialogError}
          messageDialogShow={messageDialogShow}
          messageDialogHeader={messageDialogHeader}
          messageDialogBody={messageDialogBody}
        />
        <div className="formgrid grid">
          <div className="field col-6">
            <div className="flex ml-2 align-items-start p-3 bg-yellow-100 border-round border-1 border-yellow-300">
              <i className="pi pi-info-circle text-yellow-900 text-2xl mr-3"></i>
              <div className="mr-1">
                <p className="m-0 p-0 text-yellow-700 line-height-3">
                  Ne felejtsd el a zöld gombbal lezárni a napot, miután beírtad
                  az órákat!
                </p>
              </div>
            </div>
          </div>
          <div className="field col-6">
            <div className="flex justify-content-end flex-wrap card-container green-container">
              <div className="flex align-items-center justify-content-center pt-2">
                <ToggleButton
                  className="p-panel p-button p-button-sm mr-3"
                  onIcon="pi pi-align-justify"
                  offIcon="pi pi-align-justify"
                  onLabel=""
                  offLabel=""
                  checked={byHoursOrder}
                  onChange={(e) => changeOrder(e.value)}
                  onClick={() => {
                    //changeOrder();
                  }}
                />

                <Button
                  className="p-panel p-button p-button-sm bg-red-500 border-red-500 mr-3"
                  icon="pi pi-cloud-download"
                  onClick={() => {
                    showWorkingTimeReportDialog();
                  }}
                />

                <Button
                  className="p-panel p-button p-button-sm p-button-success mr-3"
                  icon="pi pi-refresh"
                  onClick={() => {
                    refresh(byHoursOrder);
                  }}
                />

                <Button
                  className="p-panel p-button p-button-sm p-button-info  mr-3"
                  icon="pi pi-backward"
                  onClick={() => {
                    backward();
                  }}
                />
                <Button
                  className="p-panel p-button p-button-sm p-button-info  mr-3"
                  icon="pi pi-home"
                  onClick={() => {
                    today();
                  }}
                />
                <Button
                  className="p-panel p-button p-button-sm p-button-info mr-3"
                  icon="pi pi-forward"
                  onClick={() => {
                    forward();
                  }}
                />

                <Button
                  disabled={closedDay}
                  className="p-panel p-button p-button-sm p-button-success mr-3"
                  icon="pi pi-lock"
                  onClick={() => {
                    confirmCloseWorkingDay();
                  }}
                />

                {role !== "CREATIVE" ? (
                  <Button
                    className="p-panel p-button p-button-sm  p-button-warning mr-3"
                    icon="pi pi-verified"
                    onClick={() => {
                      showHiddenTask();
                    }}
                  />
                ) : null}

                <InputText
                  value={workingHours}
                  readOnly
                  style={{
                    width: "70px",
                    backgroundColor: "#F8F9FA",
                    textAlign: "center",
                  }}
                  className="mr-2"
                />

                {/* <Button className="p-panel p-button p-button-sm p-button-warning mb-3" icon="pi pi-clock"
                                    onClick={() => {

                                    }}
                                /> */}
              </div>
            </div>
          </div>
        </div>
        <div className="field col-12">
          <DataTable
            //className="admin-list"
            //style={{ width: "1060px" }}
            ref={tableReference}
            rowClassName={classNameForRow}
            value={trafficTableLineList2}
            size="small"
            scrollable
            //scrollHeight="800px"
            scrollHeight="68vh"
            loading={loading}
            scrollDirection="both"
            responsiveLayout="scroll"
            //editMode="cell" trafficTableLineList2[0][1] = 'ss'
            showGridlines
            removableSort={true}
            contextMenuSelection={selectedWorkingTimeLine}
            onContextMenuSelectionChange={(e) =>
              setSelectedWorkingTimeLine(e.value)
            }
            onContextMenu={(e) => {
              console.log(e);
              cm.current.show(e.originalEvent);
            }}
          >
            <Column
              field="rowIndex"
              header=""
              style={{ width: "50px" }}
              body={onRowIndexTemplate}
            />
            <Column
              sortable
              filter
              key="projectOwnerName"
              field="projectOwnerName"
              header="Account"
              style={{ width: "150px" }}
            />
            <Column
              sortable
              filter
              key="projectShortIdentifier"
              field="projectShortIdentifier"
              header="Rövid munkaszám"
              style={{ width: "290px" }}
            />
            <Column
              key="projectName"
              frozen
              sortable
              filter
              field="projectName"
              header="Munkanév"
              style={{ flexGrow: 1, flexBasis: "200px" }}
            />
            <Column
              sortable
              filter
              key="taskName"
              field="taskName"
              header="Feladat"
              style={{ flexGrow: 1, flexBasis: "200px" }}
            />
            <Column
              sortable
              filter
              key="taskComment"
              field="taskComment"
              header="Megjegyzés"
              style={{ flexGrow: 1, flexBasis: "200px" }}
            />
            {dynamicWorkingTimeColumns}
          </DataTable>
        </div>

        <NewTaskDialog
          refresh={onHiddenTaskDialogClose}
          projectId={projectId}
          taskId={taskId}
          projectShortIdentifier={projectShortIdentifier}
          projectName={projectName}
          taskName={taskName}
          taskComment={taskComment}
          show={newTaskDialogShow}
          change={newTaskDialogChange}
        />

        <ModifyTaskDialog
          refresh={onHiddenTaskDialogClose}
          projectId={projectId}
          taskId={taskId}
          projectShortIdentifier={projectShortIdentifier}
          projectName={projectName}
          taskName={taskName}
          show={modifyTaskDialogShow}
          change={modifyTaskDialogChange}
        />

        <HideTaskDialog
          refresh={onHiddenTaskDialogClose}
          projectId={projectId}
          taskId={taskId}
          projectShortIdentifier={projectShortIdentifier}
          projectName={projectName}
          taskName={taskName}
          show={hideTaskDialogShow}
          change={hideTaskDialogChange}
        />

        <RenameTaskDialog
          refresh={onHiddenTaskDialogClose}
          projectId={projectId}
          taskId={taskId}
          projectShortIdentifier={projectShortIdentifier}
          projectName={projectName}
          taskName={taskName}
          show={renameTaskDialogShow}
          change={renameTaskDialogChange}
        />

        <MyWorkingTimeReportDialog
          onClose={onWorkingTimeReportDialogClose}
          show={workingTimeReportDialogShow}
          change={workingTimeReportDialogChange}
        />

        <HiddenTaskDialog
          ownTasks={true}
          onClose={onHiddenTaskDialogClose}
          show={hiddenTaskDialogShow}
          change={hiddenTaskDialogChange}
        />
      </div>
    </>
  );
};

export default WorkingTime;
