import axios from 'axios';
import { server } from '../config/endpoints';

const API_URL = server;

/* export const readTrafficTable = () => {
    return axios
        .get(API_URL + '/protected/traffictable')
        .then((response) => {
            return response;
        });
}; */

export const readTrafficTable = (byHoursOrder: boolean) => {
    return axios
        .get(API_URL + '/protected/traffictable/' + byHoursOrder)
        .then((response) => {
            return response;
        });
};

export const readTrafficTableNotes = () => {
    return axios
        .get(API_URL + '/protected/traffictable/notes')
        .then((response) => {
            return response;
        });
};

export const updateTrafficTableNotes = (formData: any) => {
    return axios
        .post(API_URL + '/protected/traffictable/notes', formData)
        .then((response) => {
            return response;
        });
};

/* export const readBackward = (fromDate: string) => {
    return axios
        .get(API_URL + '/protected/traffictable/backward/' + fromDate)
        .then((response) => {
            return response;
        });
}; */

export const readBackward = (fromDate: string, byHoursOrder: boolean) => {
    return axios
        .get(API_URL + '/protected/traffictable/backward/' + fromDate + '/' + byHoursOrder)
        .then((response) => {
            return response;
        });
};

/* export const readForward = (fromDate: string) => {
    return axios
        .get(API_URL + '/protected/traffictable/forward/' + fromDate)
        .then((response) => {
            return response;
        });
}; */

export const readForward = (fromDate: string, byHoursOrder: boolean) => {
    return axios
        .get(API_URL + '/protected/traffictable/forward/' + fromDate  + '/' + byHoursOrder)
        .then((response) => {
            return response;
        });
};

/* export const refreshTrafficTable = (fromDate: string) => {
    return axios
        .get(API_URL + '/protected/traffictable/refresh/' + fromDate)
        .then((response) => {
            return response;
        });
}; */

export const refreshTrafficTable = (fromDate: string, byHoursOrder: boolean) => {
    return axios
        .get(API_URL + '/protected/traffictable/refresh/' + fromDate  + '/' + byHoursOrder)
        .then((response) => {
            return response;
        });
};

export const readHiddenTasks = () => {
    return axios
        .get(API_URL + '/protected/traffictable/task/hidden')
        .then((response) => {
            return response;
        });
};

export const readOwnHiddenTasks = () => {
    return axios
        .get(API_URL + '/protected/traffictable/task/hidden/own')
        .then((response) => {
            return response;
        });
};

export const unhideTasks = (formData: any) => {
    return axios
        .post(API_URL + '/protected/traffictable/task/unhide', formData)
        .then((response) => {
            return response;
        });
};

export const updateTaskComment = (formData: any) => {
    return axios
        .post(API_URL + '/protected/traffictable/taskcomment', formData)
        .then((response) => {
            return response;
        });
};


export const updateTaskName = (formData: any) => {
    return axios
        .post(API_URL + '/protected/traffictable/taskname', formData)
        .then((response) => {
            return response;
        });
};


export const addTask = (formData: any) => {
    return axios
        .post(API_URL + '/protected/traffictable/task', formData)
        .then((response) => {
            return response;
        });
};


export const updateTrafficTableCell = (formData: any) => {
    return axios
        .post(API_URL + '/protected/traffictable/cell', formData)
        .then((response) => {
            return response;
        });
};



export const deleteTask = (id: string) => {
    return axios
        .delete(API_URL + '/protected/traffictable/' + id)
        .then((response) => {
            return response;
        });
};

export const hideTask = (id: string) => {
    return axios
        .post(API_URL + '/protected/traffictable/task/hide/' + id)
        .then((response) => {
            return response;
        });
};


