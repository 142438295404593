import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";

const MessageDialog: React.FunctionComponent<any> = (props: any) => {
    const [messageDialogShow, setMessageDialogShow] = useState<boolean>(false);
    const [
        messageDialogNavigateAction,
        setMessageDialogNavigateAction
    ] = useState<string>("");
    const [messageDialogChange, setMessageDialogChange] = useState<number>(0);

    let navigate = useNavigate();

    useEffect(() => {
        if (
            props.messageDialogShow !== 0 &&
            props.messageDialogShow !== messageDialogChange
        ) {
            props.messageDialogNavigateAction
                ? setMessageDialogNavigateAction(
                      props.messageDialogNavigateAction
                  )
                : setMessageDialogNavigateAction("");
            setMessageDialogShow(true);
            setMessageDialogChange(props.messageDialogShow);
        }
    }, [messageDialogChange, props]);

    const messageDialogFooter = (
        <div className="flex justify-content-center">
            <Button
                label="OK"
                className="p-button-text"
                autoFocus
                onClick={() => {
                    setMessageDialogShow(false);
                    if (
                        messageDialogNavigateAction !== "" &&
                        messageDialogNavigateAction === "../dashboard"
                    ) {
                        navigate(messageDialogNavigateAction);
                        navigate(0);
                    } else {
                        navigate(messageDialogNavigateAction);
                    }
                }}
            />
        </div>
    );

    return (
        <Dialog
            visible={messageDialogShow}
            onHide={() => setMessageDialogShow(false)}
            position="top"
            footer={messageDialogFooter}
            showHeader={false}
            style={{ width: "70vw" }}
        >
            <div className="flex align-items-center text-center flex-column pt-6 px-2">
                {props.messageDialogError === true ||
                props.messageDialogError === "true" ? (
                    <i
                        className="pi pi-bell"
                        style={{
                            fontSize: "5rem",
                            color: "var(--yellow-500)"
                        }}
                    ></i>
                ) : (
                    <i
                        className="pi pi-check-circle"
                        style={{
                            fontSize: "5rem",
                            color: "var(--green-500)"
                        }}
                    ></i>
                )}
                <h3 >{props.messageDialogHeader}</h3>
                <p style={{whiteSpace: "pre-line"}}>{props.messageDialogBody}</p>
                <Divider />
            </div>
        </Dialog>
    );
};

export default MessageDialog;
