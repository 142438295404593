import axios from 'axios';
import { server } from '../config/endpoints';

const API_URL = server;

export const readAllCustomerInvoiceLoadResults = (company: string, filter: string) => {
    if (filter === 'Összes') {
        filter = 'ALL';
    } else if (filter === 'Sikeres') {
        filter = 'OK';
    } else {
        filter = 'ERROR';
    }
    return axios
        .get(API_URL + '/protected/customerinvoiceloadresult/all/' + company + '/' + filter)
        .then((response) => {
            return response;
        });
};
