import React, { useEffect, useState, Fragment, useRef } from "react";
import { useDispatch } from "react-redux";
import "primeflex/primeflex.css";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useNavigate } from "react-router";
import {
  readAllAccountSkyUsers,
  readAllCoworkerSkyUsers,
  readAllCreativeSkyUsers,
  readAllGenericSkyUsers,
} from "../../service/SkyUserService";
import { StatusCodes } from "http-status-codes";
import { logout } from "../../service/AuthService";
import * as Yup from "yup";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
//import { Password } from "primereact/password";
import { Divider } from "primereact/divider";
import { confirmDialog } from "primereact/confirmdialog";
import {
  checkProjectClosing,
  readAllProjects,
  readProject,
  saveProject,
} from "../../service/ProjectService";
import { setProjectList } from "../../state/projectSlice";
import {
  readProjectTypesByLevel,
  readProjectTypesByParent,
} from "../../service/ProjectTypeService";
import { readAllCustomers, readCustomer } from "../../service/CustomerService";
import { InputTextarea } from "primereact/inputtextarea";
import { DataTable } from "primereact/datatable";
import { Column, ColumnEventParams } from "primereact/column";
import { Checkbox, CheckboxChangeParams } from "primereact/checkbox";
import { InputNumber } from "primereact/inputnumber";
import { readAllVendorsAsLabelValue } from "../../service/VendorService";
//import { Calendar } from "primereact/calendar";
//import VerifyProjectVendorAssignment from "./VerifyProjectVendorAssignment";
import { ProjectVendorAssignment } from "../../type/ProjectVendorAssignment";
import { v4 as uuidv4 } from "uuid";
//import UpdateProjectVendorAssignment from "./UpdateProjectVendorAssignment";
import { Toast } from "primereact/toast";
import Percentage from "../../component/Percentage";
import { readAllContributorsAsLabelValue } from "../../service/ContributorService";
//import UpdateProjectContributorAssignment from "./UpdateProjectContributorAssignment";
import { onRowIndexTemplate } from "../../service/IndexColumn";
import { BlockUI } from 'primereact/blockui';
import { argv0 } from "process";
import MessageDialog from "../../component/MessageDialog";


const ProjectDetail: React.FC<any> = (props: any) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [projectDetailDialogShow, setProjectDetailDialogShow] =
    useState<boolean>(false);

  const [projectDetailDialogChange, setProjectDetailDialogChange] =
    useState<number>(0);

  const hideDialog = () => {
    setProjectDetailDialogShow(false);
    setProjectDto(null);
    formik.resetForm();
    //refreshProjectList();
    refresh();
  };

  const [loading, setLoading] = useState<boolean>(false);
  const [messageDialogShow, setMessageDialogShow] = useState<Number>(0);
  const [messageDialogHeader, setMessageDialogHeader] = useState<string>("");
  const [messageDialogBody, setMessageDialogBody] = useState<string>("");
  const [messageDialogError, setMessageDialogError] =
    useState<boolean>(false);

  const [projectDto, setProjectDto] = useState<any>();

  const projectId: any = props.id;
  const refresh = props.refresh;

  const businessOpportunityItems = [
    { label: "Válasszon projekt típust!", value: "" },
    { label: "NB/Tender", value: "NB" },
    { label: "Konkrét megbízás", value: "CP" },
    { label: "Belső munka", value: "IP" },
  ];

  const invoiceFromItems = [
    { label: "Válasszon céget!", value: "" },
    { label: "SKY", value: "SKY" },
    { label: "BPC", value: "BPC" },
  ];

  const yearItems = [
    { label: "Válasszon évet!", value: "" },
    { label: "2023", value: "2023" },
    { label: "2024", value: "2024" },
    { label: "2025", value: "2025" },
  ];
  const monthItems = [
    { label: "Válasszon hónapot!", value: "" },
    { label: "Január", value: "1" },
    { label: "Február", value: "2" },
    { label: "Március", value: "3" },
    { label: "Április", value: "4" },
    { label: "Május", value: "5" },
    { label: "Június", value: "6" },
    { label: "Július", value: "7" },
    { label: "Augusztus", value: "8" },
    { label: "Szeptember", value: "9" },
    { label: "Október", value: "10" },
    { label: "November", value: "11" },
    { label: "December", value: "12" },
  ];

  const advancePaymentNeededtems = [
    { label: "Nem", value: "NO" },
    { label: "Igen", value: "YES" },
  ];

  const [projectTypesLevel1, setProjectTypesLevel1] = useState<any>([]);
  const [projectTypesLevel2, setProjectTypesLevel2] = useState<any>([]);
  const [projectTypesLevel3, setProjectTypesLevel3] = useState<any>([]);
  const [projectTypesLevel4, setProjectTypesLevel4] = useState<any>([]);
  const [projectTypesLevel5, setProjectTypesLevel5] = useState<any>([]);
  const [customers, setCustomers] = useState<any>([]);
  const [branches, setBranches] = useState<any>([]);
  const [vendors, setVendors] = useState<any>([]);
  const [contributors, setContributors] = useState<any>([]);
  const [vendorAmount, setVendorAmount] = useState<number | undefined>();
  const [contributorAmount, setContributorAmount] = useState<number | undefined>();
  const [creativeWorkingHoursPercentage, setCreativeWorkingHoursPercentage] = useState<number | undefined>(0);
  const [accountWorkingHoursPercentage, setAccountWorkingHoursPercentage] = useState<number | undefined>(0);
  const [blockedPanel, setBlockedPanel] = useState(false);
  const [closeable, setCloseable] = useState(false);



  const [creativeWorkingHoursPercentageVisible, setCreativeWorkingHoursPercentageVisible] = useState<boolean>(true);
  const [accountWorkingHoursPercentageVisible, setAccountWorkingHoursPercentageVisible] = useState<boolean>(true);


  const [vendorContent, setVendorContent] = useState<string>("");
  const [contributorContent, setContributorContent] = useState<string>("");
  const [vendorSectionVisible, setVendorSectionVisible] = useState<boolean>(false);
  const toast: any = useRef(null);

  const [allAccountSkyUsers, setAllAccountSkyUsers] = useState<any>([]);
  const [allCreativeSkyUsers, setAllCreativeSkyUsers] = useState<any>([]);
  const [allCoworkerSkyUsers, setAllCoworkerSkyUsers] = useState<any>([]);
  const [allGenericSkyUsers, setAllGenericSkyUsers] = useState<any>([]);

  const [accountSkyUserList, setAccountSkyUserList] = useState<any>([]);
  const [creativeSkyUserList, setCreativeSkyUserList] = useState<any>([]);
  const [coworkerSkyUserList, setCoworkerSkyUserList] = useState<any>([]);
  const [genericSkyUserList, setGenericSkyUserList] = useState<any>([]);



  const [projectIdentifier, setProjectIdentifier] = useState<string>("");

  const [projectVendorAssignmentId, setProjectVendorAssignmentId] =
    useState<string>("");
  const [projectVendorAssignmentLine, setProjectVendorAssignmentLine] =
    useState<any>(null);
  const [projectContributorAssignmentLine, setProjectContributorAssignmentLine] =
    useState<any>(null);

  const [
    projectVendorAssignmentDialogShow,
    setProjectVendorAssignmentDialogShow,
  ] = useState<boolean>(false);
  const [
    projectVendorAssignmentDialogChange,
    setProjectVendorAssignmentDialogChange,
  ] = useState<number>(0);
  const [updateVendorAmountDialogShow, setUpdateVendorAmountDialogShow] =
    useState<boolean>(false);
  const [updateVendorAmountDialogChange, setUpdateVendorAmountDialogChange] =
    useState<number>(0);

  const [updateContributorAmountDialogShow, setUpdateContributorAmountDialogShow] =
    useState<boolean>(false);
  const [updateContributorAmountDialogChange, setUpdateContributorAmountDialogChange] =
    useState<number>(0);


  const salesContractLineFooterTemplate = () => {
    return (
      <React.Fragment>
        <td colSpan={6} style={{ textAlign: "left" }}></td>
        <td style={{ fontWeight: "bold", textAlign: "right" }}>
          {calculateSumOfWorkingHours(projectDto.taskList, "lowHours")}
        </td>
        <td style={{ fontWeight: "bold", textAlign: "right" }}>
          {calculateSumOfWorkingHours(projectDto.taskList, "mediumHours")}
        </td>
        <td style={{ fontWeight: "bold", textAlign: "right" }}>
          {calculateSumOfWorkingHours(projectDto.taskList, "highHours")}
        </td>
        <td style={{ fontWeight: "bold", textAlign: "right" }}>
          {calculateSumOfWorkingHours(projectDto.taskList, "workingHours")}
        </td>
      </React.Fragment>
    );
  };

  const calculateSumOfCustomerInvoiceAmount = (list: any) => {
    let total = 0;

    if (list) {
      for (let line of list) {
        total += line.amount;
      }
    }
    return formatCurrency(total);
  }

  const customerInvoiceFooterTemplate = () => {
    return (
      <React.Fragment>

        <td colSpan={2} style={{ fontWeight: "bold", textAlign: "right" }}>
          Összesen: {calculateSumOfCustomerInvoiceAmount(projectDto.customerInvoiceList)}
        </td>
        <td colSpan={3} style={{ fontWeight: "bold", textAlign: "right" }}>

        </td>
      </React.Fragment>
    );
  };

  /*   const projectContributorFooterTemplate = () => {
      return (
        <React.Fragment>
  
          <td colSpan={2} style={{ fontWeight: "bold", textAlign: "right" }}>
            Összesen: {calculateSumOfCustomerInvoiceAmount(projectContributorAssignmentList)}
          </td>
  
        </React.Fragment>
      );
    }; */

  const vendorInvoiceFooterTemplate = () => {
    return (
      <React.Fragment>

        <td colSpan={3} style={{ fontWeight: "bold", textAlign: "right" }}>
          Összesen: {calculateSumOfCustomerInvoiceAmount(projectDto.vendorInvoiceList)}
        </td>
        <td colSpan={4} style={{ fontWeight: "bold", textAlign: "right" }}>

        </td>
      </React.Fragment>
    );
  };




  const calculateSumOfWorkingHours = (
    salesContractLineList: any,
    field: string
  ) => {
    let total = 0;

    if (salesContractLineList) {
      for (let line of salesContractLineList) {
        total += line[field];
      }
    }
    return total;
  };

  const formatNumber = (value: any) => {
    return value.toLocaleString("hu-HU", {
      style: "decimal",
      maximumFractionDigits: 1,
    });
  };

  const monthlyFeeTemplate = (rowData: any) => {
    return <Checkbox inputId="binary" checked={rowData.monthlyFee} />;
  };

  const onProjectTypeLevel1Change = (e: { value: any }) => {
    formik.setFieldValue("projectTypeLevel1", e.value);
    formik.setFieldValue("projectTypeLevel2", "");
    formik.setFieldValue("projectTypeLevel3", "");
    formik.setFieldValue("projectTypeLevel4", "");
    formik.setFieldValue("projectTypeLevel5", "");
    setProjectTypesLevel2([]);
    setProjectTypesLevel3([]);
    setProjectTypesLevel4([]);
    setProjectTypesLevel5([]);

    readProjectTypesByParent("1" + e.value).then(
      (response) => {
        let projectTypeList: { label: any; value: any }[] = [];
        response.data.projectTypeList.forEach((item: any) => {
          projectTypeList.push({ label: item.name, value: item.code });
        });
        setProjectTypesLevel2(projectTypeList);
      },
      (error) => {
        console.log(error.response.status);
      }
    );
  };

  const onProjectTypeLevel2Change = (e: { value: any }) => {
    formik.setFieldValue("projectTypeLevel2", e.value);
    formik.setFieldValue("projectTypeLevel3", "");
    formik.setFieldValue("projectTypeLevel4", "");
    formik.setFieldValue("projectTypeLevel5", "");
    setProjectTypesLevel3([]);
    setProjectTypesLevel4([]);
    setProjectTypesLevel5([]);

    readProjectTypesByParent("2" + e.value).then(
      (response) => {
        let projectTypeList: { label: any; value: any }[] = [];
        response.data.projectTypeList.forEach((item: any) => {
          projectTypeList.push({ label: item.name, value: item.code });
        });
        setProjectTypesLevel3(projectTypeList);
      },
      (error) => {
        console.log(error.response.status);
      }
    );
  };

  const onProjectTypeLevel3Change = (e: { value: any }) => {
    formik.setFieldValue("projectTypeLevel3", e.value);
    formik.setFieldValue("projectTypeLevel4", "");
    formik.setFieldValue("projectTypeLevel5", "");
    setProjectTypesLevel4([]);
    setProjectTypesLevel5([]);

    readProjectTypesByParent("3" + e.value).then(
      (response) => {
        let projectTypeList: { label: any; value: any }[] = [];
        response.data.projectTypeList.forEach((item: any) => {
          projectTypeList.push({ label: item.name, value: item.code });
        });
        setProjectTypesLevel4(projectTypeList);
      },
      (error) => {
        console.log(error.response.status);
      }
    );
  };

  const onProjectTypeLevel4Change = (e: { value: any }) => {
    formik.setFieldValue("projectTypeLevel4", e.value);
    formik.setFieldValue("projectTypeLevel5", "");
    setProjectTypesLevel5([]);

    readProjectTypesByParent("4" + e.value).then(
      (response) => {
        let projectTypeList: { label: any; value: any }[] = [];
        response.data.projectTypeList.forEach((item: any) => {
          projectTypeList.push({ label: item.name, value: item.code });
        });
        setProjectTypesLevel5(projectTypeList);
      },
      (error) => {
        console.log(error.response.status);
      }
    );
  };

  const onProjectTypeLevel5Change = (e: { value: any }) => {
    formik.setFieldValue("projectTypeLevel5", e.value);
  };

  const onCustomerChange = (e: { value: any }) => {
    formik.setFieldValue("customerId", e.value);
    formik.setFieldValue("branchId", "");
    console.log(e.value);

    let branchList: { label: any; value: any }[] = [];
    readCustomer(e.value).then(
      (response) => {
        console.log(response.data);
        if (response.data.branchList) {
          response.data.branchList.forEach((item: any) => {
            branchList.push({ label: item.identifier, value: item.id });
          });
          setBranches(branchList);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  /*   const onNoVendorInvoiceChange = (e: CheckboxChangeParams) => {
      console.log('onNoVendorInvoiceChange');
      if (e.checked && (projectVendorAssignmentList.length !== 0 || projectContributorAssignmentList.length !== 0)) {
        toast.current.show({ severity: 'info', summary: 'Információs üzenet', detail: 'Az alvállalkozói tételek listája nem üres. Töröld először!', life: 3000 });
        return;
      }
      formik.setFieldValue("noVendorInvoice", e.checked);
      setVendorSectionVisible(e.checked);
      if (e.checked) {
        formik.setFieldValue("plannedProfit", formik.values.plannedTurnOver);
        formik.setFieldValue("plannedVendorCost", 0);
      } else {
        let plannedVendorCost: number = 0;
        for (var projectVendorAssignment of projectVendorAssignmentList) {
          plannedVendorCost += projectVendorAssignment.amount;
        }
        for (var projectContributorAssignment of projectContributorAssignmentList) {
          plannedVendorCost += projectContributorAssignment.amount;
        }
        formik.setFieldValue(
          "plannedVendorCost",
          plannedVendorCost
        );
        formik.setFieldValue(
          "plannedProfit",
          formik.values.plannedTurnOver - plannedVendorCost
        );
      }
    } */



  const onNoPlannedAccountWorkingHoursChange = (e: CheckboxChangeParams) => {
    console.log("onNoPlannedAccountWorkingHoursChange");
    formik.setFieldValue("noPlannedAccountWorkingHours", e.checked);
    if (e.checked) {
      formik.setFieldValue("plannedAccountWorkingHours", null);
    }

    if (e.checked || (formik.values.plannedAccountWorkingHours === null && formik.values.allAccountWorkingHours === null)) {
      setAccountWorkingHoursPercentageVisible(false);
    } else {
      setAccountWorkingHoursPercentageVisible(true);
      setAccountWorkingHoursPercentage(formik.values.allAccountWorkingHours / (formik.values.plannedAccountWorkingHours === null ? 1 : formik.values.plannedAccountWorkingHours) * 100);
    }


    // if (formik.values.allAccountWorkingHours === null || e.checked || formik.values.plannedAccountWorkingHours === null) {
    //   setAccountWorkingHoursPercentage(0);
    // } else {
    //   setAccountWorkingHoursPercentage(formik.values.allAccountWorkingHours / e.value * 100);
    // }

  }

  const onNoPlannedCreativeWorkingHoursChange = (e: CheckboxChangeParams) => {
    formik.setFieldValue("noPlannedCreativeWorkingHours", e.checked);
    if (e.checked) {
      formik.setFieldValue("plannedCreativeWorkingHours", null);
    }

    //console.log(formik.values.allCreativeWorkingHours);

    if (e.checked || (formik.values.plannedCreativeWorkingHours === null && formik.values.allCreativeWorkingHours === null)) {
      setCreativeWorkingHoursPercentageVisible(false);
    } else {
      setCreativeWorkingHoursPercentageVisible(true);
      setCreativeWorkingHoursPercentage(formik.values.allCreativeWorkingHours / (formik.values.plannedCreativeWorkingHours === null ? 1 : formik.values.plannedCreativeWorkingHours) * 100);
    }

    // if (formik.values.allCreativeWorkingHours === null || e.checked || formik.values.plannedCreativeWorkingHours === null) {
    //   setCreativeWorkingHoursPercentage(0);
    // } else {
    //   setCreativeWorkingHoursPercentage(formik.values.allCreativeWorkingHours / e.value * 100);
    // }

  }

  const onCustomerInit = (e: { value: any }, branchId: string) => {
    formik.setFieldValue("customerId", e.value);
    formik.setFieldValue("branchId", branchId);
    console.log(e.value);

    let branchList: { label: any; value: any }[] = [];
    readCustomer(e.value).then(
      (response) => {
        console.log(response.data);
        if (response.data.branchList) {
          response.data.branchList.forEach((item: any) => {
            branchList.push({ label: item.identifier, value: item.id });
          });
          setBranches(branchList);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const confirmCloseDialog = () => {
    console.log("onClose");
    formik.submitForm();
    setProjectDetailDialogShow(false);
    setProjectDto(null);
    formik.resetForm();
    refreshProjectList();
  };

  const confirmSave = (values: any) => {
    confirmDialog({
      message: "Valóban menti a módosításokat?",
      header: "Megerősítés",
      icon: "pi pi-exclamation-triangle",
      acceptClassName: "p-button-text",
      rejectClassName: "p-button-text",
      accept: () => {
        values.accountList = accountSkyUserList;
        values.creativeList = creativeSkyUserList;
        values.coworkerList = coworkerSkyUserList;
        values.genericList = genericSkyUserList;
        //alert(JSON.stringify(values, null, 2));
        saveProject(values).then(
          (response) => {
            setProjectDto(response.data);
            hideDialog();
          },
          (error) => {
            console.log(error);
            setMessageDialogHeader("Ellenőrzési hiba");
            if (error.response.status === StatusCodes.EXPECTATION_FAILED) {
              let jsonAsText = error.response.data.message;
              setMessageDialogBody(jsonAsText);
            } else {
              setMessageDialogBody("A munkát nem lehet menteni!");
            }
            setMessageDialogError(true);
            setMessageDialogShow(Date.now());
            //hideDialog();
          }
        );
      },
      reject: () => { },
    });
  };

  const confirmCancel = () => {
    confirmDialog({
      message: "Valóban bezárja az ablakot mentés nélkül?",
      header: "Megerősítés",
      icon: "pi pi-exclamation-triangle",
      acceptClassName: "p-button-text",
      rejectClassName: "p-button-text",
      accept: () => {
        hideDialog();
      },
      reject: () => { },
    });
  };

  const confirmCloseProject = (values: any) => {
    confirmDialog({
      message: "Valóban lezárja a projektet?",
      header: "Megerősítés",
      icon: "pi pi-exclamation-triangle",
      acceptClassName: "p-button-text",
      rejectClassName: "p-button-text",
      accept: () => {
        values.accountList = accountSkyUserList;
        values.creativeList = creativeSkyUserList;
        values.coworkerList = coworkerSkyUserList;
        values.genericList = genericSkyUserList;
        values.closed = true;

        checkProjectClosing(values.id).then(
          (response) => {
            saveProject(values).then(
              (response) => {
                setProjectDto(response.data);
              },
              (error) => {
                console.log(error);
              }
            );
          },
          (error) => {
            console.log(error);
            let jsonAsText = error.response.data.message;
            //let json = JSON.parse(jsonAsText)
            setMessageDialogHeader("Ellenőrzési hiba");
            setMessageDialogBody(jsonAsText);
            setMessageDialogError(true);
            setMessageDialogShow(Date.now());
            //setUpdateGenericReportDialogShow(false);
          }
        );


      },
      reject: () => { },
    });
  };

  const confirmCancelOfClosedProject = () => {
    confirmDialog({
      message: "Valóban bezárja az ablakot?",
      header: "Megerősítés",
      icon: "pi pi-exclamation-triangle",
      acceptClassName: "p-button-text",
      rejectClassName: "p-button-text",
      accept: () => {
        hideDialog();
      },
      reject: () => { },
    });
  };



  const confirmOpenProject = (values: any) => {
    confirmDialog({
      message: "Valóban vissza akaraja nyitni a projektet?",
      header: "Megerősítés",
      icon: "pi pi-exclamation-triangle",
      acceptClassName: "p-button-text",
      rejectClassName: "p-button-text",
      accept: () => {
        values.accountList = accountSkyUserList;
        values.creativeList = creativeSkyUserList;
        values.coworkerList = coworkerSkyUserList;
        values.genericList = genericSkyUserList;
        values.closed = false;
        saveProject(values).then(
          (response) => {
            setProjectDto(response.data);
            //hideDialog();
          },
          (error) => {
            console.log(error);
            //hideDialog();
          }
        );
      },
      reject: () => { hideDialog(); },
    });
  };

  const confirmDeleteAccount = (rowData: any) => {
    confirmDialog({
      message: "Valóban törli az account-ot?",
      header: "Megerősítés",
      icon: "pi pi-exclamation-triangle",
      acceptClassName: "p-button-text",
      rejectClassName: "p-button-text",
      accept: () => {
        let oldList = [...accountSkyUserList];
        oldList.forEach((value, index) => {
          if (value.id === rowData.id) oldList.splice(index, 1);
        });
        setAccountSkyUserList(oldList);
      },
      reject: () => { },
    });
  };

  const confirmDeleteCreative = (rowData: any) => {
    confirmDialog({
      message: "Valóban törli az kreatívot?",
      header: "Megerősítés",
      icon: "pi pi-exclamation-triangle",
      acceptClassName: "p-button-text",
      rejectClassName: "p-button-text",
      accept: () => {
        let oldList = [...creativeSkyUserList];
        oldList.forEach((value, index) => {
          if (value.id === rowData.id) oldList.splice(index, 1);
        });
        setCreativeSkyUserList(oldList);
      },
      reject: () => { },
    });
  };

  /*   const confirmDeleteVendor = (rowData: any) => {
      confirmDialog({
        message: "Valóban törli az alvállalkozót a listáról?",
        header: "Megerősítés",
        icon: "pi pi-exclamation-triangle",
        acceptClassName: "p-button-text",
        rejectClassName: "p-button-text",
        accept: () => {
          let oldList = [...projectVendorAssignmentList];
          oldList.forEach((value, index) => {
            if (value.rowId === rowData.rowId) oldList.splice(index, 1);
          });
          setProjectVendorAssignmentList(oldList);
  
          let plannedVendorCost: number = 0;
          for (var projectVendorAssignment of oldList) {
            plannedVendorCost += projectVendorAssignment.amount;
          }
          for (var projectContributorAssignment of projectContributorAssignmentList) {
            plannedVendorCost += projectContributorAssignment.amount;
          }
          formik.setFieldValue("plannedVendorCost", plannedVendorCost);
          formik.setFieldValue(
            "plannedProfit",
            formik.values.plannedTurnOver - plannedVendorCost
          );
        },
        reject: () => { },
      });
    }; */
  /* 
    const confirmDeleteContributor = (rowData: any) => {
      confirmDialog({
        message: "Valóban törli az egyéb közreműködőt a listáról?",
        header: "Megerősítés",
        icon: "pi pi-exclamation-triangle",
        acceptClassName: "p-button-text",
        rejectClassName: "p-button-text",
        accept: () => {
          let oldList = [...projectContributorAssignmentList];
          oldList.forEach((value, index) => {
            if (value.rowId === rowData.rowId) oldList.splice(index, 1);
          });
          setProjectContributorAssignmentList(oldList);
  
          let plannedVendorCost: number = 0;
          for (var projectVendorAssignment of oldList) {
            plannedVendorCost += projectVendorAssignment.amount;
          }
          for (var projectVendorAssignment of projectVendorAssignmentList) {
            plannedVendorCost += projectVendorAssignment.amount;
          }
          formik.setFieldValue("plannedVendorCost", plannedVendorCost);
          formik.setFieldValue(
            "plannedProfit",
            formik.values.plannedTurnOver - plannedVendorCost
          );
        },
        reject: () => { },
      });
    }; */



  const confirmDeleteGeneric = (rowData: any) => {
    confirmDialog({
      message: "Valóban törli az általános részvevőt?",
      header: "Megerősítés",
      icon: "pi pi-exclamation-triangle",
      acceptClassName: "p-button-text",
      rejectClassName: "p-button-text",
      accept: () => {
        let oldList = [...genericSkyUserList];
        oldList.forEach((value, index) => {
          if (value.id === rowData.id) oldList.splice(index, 1);
        });
        setGenericSkyUserList(oldList);
      },
      reject: () => { },
    });
  };

  const confirmDeleteCoworker = (rowData: any) => {
    confirmDialog({
      message: "Valóban törli az munkatársat?",
      header: "Megerősítés",
      icon: "pi pi-exclamation-triangle",
      acceptClassName: "p-button-text",
      rejectClassName: "p-button-text",
      accept: () => {
        let oldList = [...coworkerSkyUserList];
        oldList.forEach((value, index) => {
          if (value.id === rowData.id) oldList.splice(index, 1);
        });
        setCoworkerSkyUserList(oldList);
      },
      reject: () => { },
    });
  };

  const refreshProjectList = () => {
    readAllProjects().then(
      (response) => {
        dispatch(setProjectList(response.data.projectList));
      },
      (error) => {
        // console.log(error.response.status);
        if (error.response.status === StatusCodes.UNAUTHORIZED) {
          logout();
          navigate("/login?status=unauthorized");
        }
      }
    );
  };

  const deleteAccountTemplate = (rowData: any) => {
    return (
      <>
        <Button
          className="p-button-rounded p-button-text"
          type="button"
          onClick={() => {
            confirmDeleteAccount(rowData);
          }}
          icon="pi pi-trash"
          aria-label="Delete"
        />
      </>
    );
  };

  const deleteCreativeTemplate = (rowData: any) => {
    return (
      <>
        <Button
          className="p-button-rounded p-button-text"
          type="button"
          onClick={() => {
            confirmDeleteCreative(rowData);
          }}
          icon="pi pi-trash"
          aria-label="Delete"
        />
      </>
    );
  };

  /*   const vendorActionTemplate = (rowData: any) => {
      if (rowData.verifiedByAccount) {
        return (
          <>
            <Button
              className="p-button-rounded p-button-text"
              type="button"
              onClick={() => {
                confirmDeleteVendor(rowData);
              }}
              icon="pi pi-trash"
              aria-label="Delete"
            />
          </>
        );
      } else {
        return (
          <>
            <Button
              className="p-button-rounded p-button-text"
              type="button"
              onClick={() => {
                confirmDeleteVendor(rowData);
              }}
              icon="pi pi-trash"
              aria-label="Delete"
            />
            <Button
              className="p-button-rounded p-button-text"
              type="button"
              onClick={() => {
                setProjectVendorAssignmentLine(rowData);
                setUpdateVendorAmountDialogShow(true);
                setUpdateVendorAmountDialogChange(Date.now());
              }}
              icon="pi pi-pencil"
              aria-label="Verify"
            />
          </>
        );
      }
    }; */


  /*   const contributorActionTemplate = (rowData: any) => {
      return (
        <>
          <Button
            className="p-button-rounded p-button-text"
            type="button"
            onClick={() => {
              confirmDeleteContributor(rowData);
            }}
            icon="pi pi-trash"
            aria-label="Delete"
          />
          <Button
            className="p-button-rounded p-button-text"
            type="button"
            onClick={() => {
              setProjectContributorAssignmentLine(rowData);
              setUpdateContributorAmountDialogShow(true);
              setUpdateContributorAmountDialogChange(Date.now());
            }}
            icon="pi pi-pencil"
            aria-label="Verify"
          />
        </>
      );
    }; */

  const vendorVerifyTemplate = (rowData: any) => {
    return (
      <Button
        className="p-button-rounded p-button-text"
        type="button"
        onClick={() => {
          setProjectVendorAssignmentLine(rowData);
          setProjectVendorAssignmentDialogShow(true);
          setProjectVendorAssignmentDialogChange(Date.now());
        }}
        icon="pi pi-check"
        aria-label="Verify"
      />
    );


    // if (rowData.verifiedByAccount) {
    //   return (
    //     <Button
    //       className="p-button-rounded p-button-text"
    //       type="button"
    //       onClick={() => {
    //         setProjectVendorAssignmentLine(rowData);
    //         setProjectVendorAssignmentDialogShow(true);
    //         setProjectVendorAssignmentDialogChange(Date.now());
    //       }}
    //       icon="pi pi-eye"
    //       aria-label="Verify"
    //     />
    //   );
    // } else {
    //   return (
    //     <Button
    //       className="p-button-rounded p-button-text"
    //       type="button"
    //       onClick={() => {
    //         setProjectVendorAssignmentLine(rowData);
    //         setProjectVendorAssignmentDialogShow(true);
    //         setProjectVendorAssignmentDialogChange(Date.now());
    //       }}
    //       icon="pi pi-check"
    //       aria-label="Verify"
    //     />
    //   );
    // }
  };

  const deleteGenericTemplate = (rowData: any) => {
    return (
      <>
        <Button
          className="p-button-rounded p-button-text"
          type="button"
          onClick={() => {
            confirmDeleteGeneric(rowData);
          }}
          icon="pi pi-trash"
          aria-label="Delete"
        />
      </>
    );
  };

  const deleteCoworkerTemplate = (rowData: any) => {
    return (
      <>
        <Button
          className="p-button-rounded p-button-text"
          type="button"
          onClick={() => {
            confirmDeleteCoworker(rowData);
          }}
          icon="pi pi-trash"
          aria-label="Delete"
        />
      </>
    );
  };



  const formatCurrency = (value: any) => {
    return value.toLocaleString("hu-HU", {
      style: "currency",
      currency: "HUF",
      maximumFractionDigits: 0,
    });
  };

  const vendorAmountTemplate = (rowData: any) => {
    return formatCurrency(rowData.amount);
  };

  const vendorVerifiedAmountTemplate = (rowData: any) => {
    return formatCurrency(rowData.verifiedAmount);
  };

  const customerInvoiceAmountTemplate = (rowData: any) => {
    return formatCurrency(rowData.amount);


  };

  /*   const refreshProjectVendorAssignmentLine = (rowData: any) => {
      console.log(rowData);
  
      let oldList = [...projectVendorAssignmentList];
      console.log("saving the verified data");
      console.log(rowData);
  
      for (var item of oldList) {
        console.log(item.rowId);
        if (item.rowId === rowData.rowId) {
          item.amount = rowData.amount;
          item.vendorInvoiceVerificationList = rowData.vendorInvoiceVerificationList;
          let sum: number = 0;
          for (var item2 of item.vendorInvoiceVerificationList) {
            sum += item2.amount;
          }
          if (item.amount === sum) {
            item.verifiedByAccount = true;
          } else {
            item.verifiedByAccount = false;
          }
  
        }
      }
      setProjectVendorAssignmentList(oldList);
  
  
      let plannedVendorCost: number = 0;
      for (var projectContributorAssignment of projectContributorAssignmentList) {
        plannedVendorCost += projectContributorAssignment.amount;
      }
      for (var projectVendorAssignment of projectVendorAssignmentList) {
        plannedVendorCost += projectVendorAssignment.amount;
      }
      formik.setFieldValue("plannedVendorCost", plannedVendorCost);
      formik.setFieldValue(
        "plannedProfit",
        formik.values.plannedTurnOver - plannedVendorCost
      );
    }; */

  /* 
    const refreshProjectContributorAssignmentLine = (rowData: any) => {
      console.log(rowData);
  
      let oldList = [...projectContributorAssignmentList];
  
      for (var item of oldList) {
        console.log(item.rowId);
        if (item.rowId === rowData.rowId) {
          item.amount = rowData.amount;
          item.content = rowData.content;
        }
      }
      setProjectContributorAssignmentList(oldList);
  
      let plannedVendorCost: number = 0;
      for (var projectContributorAssignment of projectContributorAssignmentList) {
        plannedVendorCost += projectContributorAssignment.amount;
      }
      for (var projectVendorAssignment of projectVendorAssignmentList) {
        plannedVendorCost += projectVendorAssignment.amount;
      }
      formik.setFieldValue("plannedVendorCost", plannedVendorCost);
      formik.setFieldValue(
        "plannedProfit",
        formik.values.plannedTurnOver - plannedVendorCost
      );
  
    };
   */



  const statusBodyTemplate = (rowData: ProjectVendorAssignment) => {
    if (rowData.verifiedByAccount) {
      return <i className="pi pi-lock"></i>;
    } else {
      return <i className="pi pi-lock-open"></i>;
    }
  };

  const financialResultInText = (financialResult: number) => {
    if (financialResult < 0) {
      return "Eredményesség 0% alatt";
    } else if (financialResult >= 0 && financialResult < 10) {
      //return "Eredményesség 0 és 10% között";
      return "Fejlesztés alatt";
    } else if (financialResult >= 10 && financialResult < 20) {
      return "Eredményesség 10 és 20% között";
    } else if (financialResult >= 20 && financialResult < 30) {
      return "Eredményesség 20 és 30% között";
    } else {
      return "Eredményesség 30% felett";
    }
  };

  useEffect(() => {
    if (props.show === true) {
      console.log("ProjectDetail's useEffect");
      setLoading(true);
      setProjectDto(null);
      setAccountSkyUserList([]);
      setCreativeSkyUserList([]);
      setCoworkerSkyUserList([]);
      setGenericSkyUserList([]);

      setAllAccountSkyUsers([]);
      setAllCreativeSkyUsers([]);
      setAllCoworkerSkyUsers([]);
      setAllGenericSkyUsers([]);

      setVendorAmount(undefined);
      setVendorContent("");
      setVendorSectionVisible(true);
      setProjectIdentifier("");
      setUpdateVendorAmountDialogShow(false);
      setProjectVendorAssignmentDialogShow(false);
      setUpdateContributorAmountDialogShow(false);
      setMessageDialogError(false);
      setMessageDialogShow(0);


      readProjectTypesByParent("000").then(
        (response) => {
          let projectTypeList: { label: any; value: any }[] = [];
          response.data.projectTypeList.forEach((item: any) => {
            projectTypeList.push({ label: item.name, value: item.code });
          });
          setProjectTypesLevel1(projectTypeList);
        },
        (error) => {
          console.log(error.response.status);
        }
      );

      readAllCustomers().then(
        (response) => {
          let customerList: { label: any; value: any }[] = [];
          response.data.customerList.forEach((item: any) => {
            customerList.push({ label: item.shortName, value: item.id });
          });
          setCustomers(customerList);
        },
        (error) => {
          console.log(error.response.status);
        }
      );

      readAllVendorsAsLabelValue().then(
        (response) => {
          setVendors(response.data.labelValueList);
        },
        (error) => {
          console.log(error.response.status);
        }
      );

      readAllContributorsAsLabelValue().then(
        (response) => {
          setContributors(response.data.labelValueList);
        },
        (error) => {
          console.log(error.response.status);
        }
      );

      readAllAccountSkyUsers().then(
        (response) => {
          let skyUserList: { label: any; value: any }[] = [];
          response.data.skyUserList.forEach((item: any) => {
            skyUserList.push({ label: item.name, value: item.id });
          });
          setAllAccountSkyUsers(skyUserList);
        },
        (error) => {
          console.log(error.response.status);
        }
      );

      readAllCreativeSkyUsers().then(
        (response) => {
          let skyUserList: { label: any; value: any }[] = [];
          response.data.skyUserList.forEach((item: any) => {
            skyUserList.push({ label: item.name, value: item.id });
          });
          setAllCreativeSkyUsers(skyUserList);
        },
        (error) => {
          console.log(error.response.status);
        }
      );

      readAllCoworkerSkyUsers().then(
        (response) => {
          let skyUserList: { label: any; value: any }[] = [];
          response.data.skyUserList.forEach((item: any) => {
            skyUserList.push({ label: item.name, value: item.id });
          });
          setAllCoworkerSkyUsers(skyUserList);
        },
        (error) => {
          console.log(error.response.status);
        }
      );

      readAllGenericSkyUsers().then(
        (response) => {
          let skyUserList: { label: any; value: any }[] = [];
          response.data.skyUserList.forEach((item: any) => {
            skyUserList.push({ label: item.name, value: item.id });
          });
          setAllGenericSkyUsers(skyUserList);
        },
        (error) => {
          console.log(error.response.status);
        }
      );

      if (projectId !== null && projectId !== "") {
        readProject(projectId).then(
          (response) => {
            console.log(response.data);

            onProjectTypeLevel1Change({
              value: response.data.projectTypeLevel1,
            });
            onProjectTypeLevel2Change({
              value: response.data.projectTypeLevel2,
            });
            onProjectTypeLevel3Change({
              value: response.data.projectTypeLevel3,
            });
            onProjectTypeLevel4Change({
              value: response.data.projectTypeLevel4,
            });
            onProjectTypeLevel5Change({
              value: response.data.projectTypeLevel5,
            });
            onCustomerInit(
              { value: response.data.customerId },
              response.data.branchId
            );

            setProjectDto(response.data);
            setVendorSectionVisible(response.data.noVendorInvoice);

            setProjectIdentifier(response.data.identifier);

            setAccountSkyUserList(response.data.accountList);
            setCreativeSkyUserList(response.data.creativeList);
            setCoworkerSkyUserList(response.data.coworkerList);
            setGenericSkyUserList(response.data.genericList);


            if (response.data.noPlannedAccountWorkingHours || (response.data.plannedAccountWorkingHours === null && response.data.allAccountWorkingHours === 0)) {
              setAccountWorkingHoursPercentageVisible(false);
            } else {
              setAccountWorkingHoursPercentageVisible(true);
              setAccountWorkingHoursPercentage(response.data.allAccountWorkingHours / (response.data.plannedAccountWorkingHours === null ? 1 : response.data.plannedAccountWorkingHours) * 100);
            }

            if (response.data.noPlannedCreativeWorkingHours || (response.data.plannedCreativeWorkingHours === null && response.data.allCreativeWorkingHours === 0)) {
              setCreativeWorkingHoursPercentageVisible(false);
            } else {
              setCreativeWorkingHoursPercentageVisible(true);
              setCreativeWorkingHoursPercentage(response.data.allCreativeWorkingHours / (response.data.plannedCreativeWorkingHours === null ? 1 : response.data.plannedCreativeWorkingHours) * 100);
            }
            setLoading(false);
          },
          (error) => {
            setLoading(false);
            if (error.response.status === StatusCodes.METHOD_NOT_ALLOWED) {
              console.log("A név már szerepel a rendszerben");
            }
            if (error.response.status === StatusCodes.UNAUTHORIZED) {
              logout();
              navigate("/login?status=unauthorized");
            }
          }
        );
      } else {
        setLoading(false);
      }
      setProjectDetailDialogChange(props.change);
      setProjectDetailDialogShow(props.show);
      
    }
  }, [
    projectDetailDialogChange,
    navigate,
    props.show,
    props.change,
    projectId,
  ]);

  const formik = useFormik({
    initialValues: {
      financialData: "",
      accountWorkingHoursData: "",
      creativeWorkingHoursData: "",
      plannedVendorCostData: "",
      plannedTurnOverData: "",
      id: projectDto && projectDto.id ? projectDto.id : "",
      businessOpportunity:
        projectDto && projectDto.businessOpportunity
          ? projectDto.businessOpportunity
          : "",
      invoiceFrom:
        projectDto && projectDto.invoiceFrom ? projectDto.invoiceFrom : "",
      yearOfCompletion:
        projectDto && projectDto.yearOfCompletion
          ? projectDto.yearOfCompletion
          : "",
      monthOfCompletion:
        projectDto && projectDto.monthOfCompletion
          ? projectDto.monthOfCompletion
          : "",
      advancePaymentNeeded:
        projectDto && projectDto.advancePaymentNeeded
          ? projectDto.advancePaymentNeeded
          : "NO",
      advanceLetterSent:
        projectDto && projectDto.advanceLetterSent
          ? projectDto.advanceLetterSent
          : "NO",
      projectTypeLevel1:
        projectDto && projectDto.projectTypeLevel1
          ? projectDto.projectTypeLevel1
          : "",
      projectTypeLevel2:
        projectDto && projectDto.projectTypeLevel2
          ? projectDto.projectTypeLevel2
          : "",
      projectTypeLevel3:
        projectDto && projectDto.projectTypeLevel3
          ? projectDto.projectTypeLevel3
          : "",
      projectTypeLevel4:
        projectDto && projectDto.projectTypeLevel4
          ? projectDto.projectTypeLevel4
          : "",
      projectTypeLevel5:
        projectDto && projectDto.projectTypeLevel5
          ? projectDto.projectTypeLevel5
          : "",
      customerId:
        projectDto && projectDto.customerId ? projectDto.customerId : "",
      monogram: projectDto && projectDto.monogram ? projectDto.monogram : "",
      plannedTurnOver:
        projectDto && projectDto.plannedTurnOver != null
          ? projectDto.plannedTurnOver
          : null,
      plannedVendorCost:
        projectDto && projectDto.plannedVendorCost != null
          ? projectDto.plannedVendorCost
          : null,

      invoicedTurnOver:
        projectDto && projectDto.invoicedTurnOver
          ? projectDto.invoicedTurnOver
          : 0,
      invoicedVendorCost:
        projectDto && projectDto.invoicedVendorCost
          ? projectDto.invoicedVendorCost
          : 0,

      profitInHuf:
        projectDto && projectDto.profitInHuf !== null ? projectDto.profitInHuf : 0,
      profitInPercentage:
        projectDto && projectDto.profitInPercentage !== null ? projectDto.profitInPercentage : 0,
      financialResultInPercentage:
        projectDto && projectDto.financialResultInPercentage !== null ? projectDto.financialResultInPercentage : 0,

      plannedProfit:
        projectDto && projectDto.plannedProfit !== null ? projectDto.plannedProfit : null,
      invoicedProfit:
        projectDto && projectDto.invoicedProfit ? projectDto.invoicedProfit : 0,
      noVendorInvoice:
        projectDto && projectDto.noVendorInvoice
          ? projectDto.noVendorInvoice
          : 0,
      closed:
        projectDto && projectDto.closed
          ? projectDto.closed
          : 0,
      branchId: projectDto && projectDto.branchId ? projectDto.branchId : "",
      name: projectDto && projectDto.name ? projectDto.name : "",
      notes: projectDto && projectDto.notes ? projectDto.notes : "",
      financialNotes: projectDto && projectDto.financialNotes ? projectDto.financialNotes : "",
      identifier:
        projectDto && projectDto.identifier ? projectDto.identifier : "",
      administrative:
        projectDto && projectDto.administrative
          ? projectDto.administrative
          : false,
      monthlyPaid:
        projectDto && projectDto.monthlyPaid ? projectDto.monthlyPaid : false,
      selectedAccount: "",
      selectedCreative: "",
      selectedCoworker: "",
      selectedGeneric: "",
      selectedVendor: "",
      selectedContributor: "",
      plannedAccountWorkingHours: projectDto && projectDto.plannedAccountWorkingHours ? projectDto.plannedAccountWorkingHours : null,
      plannedCreativeWorkingHours: projectDto && projectDto.plannedCreativeWorkingHours ? projectDto.plannedCreativeWorkingHours : null,
      allAccountWorkingHours: projectDto && projectDto.allAccountWorkingHours ? projectDto.allAccountWorkingHours : null,
      allCreativeWorkingHours: projectDto && projectDto.allCreativeWorkingHours ? projectDto.allCreativeWorkingHours : null,
      noPlannedCreativeWorkingHours:
        projectDto && projectDto.noPlannedCreativeWorkingHours ? projectDto.noPlannedCreativeWorkingHours : false,
      noPlannedAccountWorkingHours:
        projectDto && projectDto.noPlannedAccountWorkingHours ? projectDto.noPlannedAccountWorkingHours : false,
    },
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: Yup.object({
      businessOpportunity: Yup.string()
        .min(2, "A névnek legalább 2 karakternek kell lennie")
        .required("Kötelező kitölteni!"),
      invoiceFrom: Yup.string()
        .min(2, "A névnek legalább 2 karakternek kell lennie")
        .required("Kötelező kitölteni!"),
      yearOfCompletion: Yup.string().required("Kötelező kitölteni!"),
      monthOfCompletion: Yup.string().required("Kötelező kitölteni!"),
      advancePaymentNeeded: Yup.string().required("Kötelező kitölteni!"),
      projectTypeLevel1: Yup.string().required("Kötelező kitölteni!"),
      projectTypeLevel2: Yup.string().required("Kötelező kitölteni!"),
      projectTypeLevel3: Yup.string().required("Kötelező kitölteni!"),
      projectTypeLevel4: Yup.string().required("Kötelező kitölteni!"),
      projectTypeLevel5: Yup.string().required("Kötelező kitölteni!"),
      customerId: Yup.string().required("Kötelező kitölteni!"),
      name: Yup.string().required("Kötelező kitölteni!"),
      branchId: Yup.string().required("Kötelező kitölteni!"),

      advanceLetterSent: Yup.string().when(
        "advancePaymentNeeded",
        (advanceLetterSent, schema) => {
          console.log("value business : ", advanceLetterSent);
          if (advanceLetterSent === "YES") {
            return schema.required("field required");
          } else {
            return schema;
          }
        }
      ),

      /*       financialData: Yup.string().test(
              "financialData",
              "Kötelező",
              function (value, context) {
                console.log(context.parent.plannedTurnOver);
                if (context.parent.plannedTurnOver && !context.parent.noVendorInvoice && projectVendorAssignmentList.length === 0 && projectContributorAssignmentList.length === 0) {
                  return this.createError({ message: 'Alvállalkozói tétel megadása közelező!' });
                } else if (!context.parent.plannedTurnOver && !context.parent.noVendorInvoice && projectVendorAssignmentList.length !== 0) {
                  return this.createError({ message: 'Kiszámlázandó forgalom megadása közelező!' });
                } else {
                  return true;
                }
              }
            ),
       */

      /*       creativeWorkingHoursData: Yup.string().test(
              "creativeWorkingHoursData",
              "Kötelező",
              function (value, context) {
                console.log(context.parent.plannedCreativeWorkingHours);
                if (!context.parent.plannedCreativeWorkingHours && !context.parent.noPlannedCreativeWorkingHours) {
                  return this.createError({ message: 'Kötelező kitölteni!' });
                } else {
                  return true;
                }
              }
            ), */


      /*       accountWorkingHoursData: Yup.string().test(
              "accountWorkingHoursData",
              "Kötelező",
              function (value, context) {
                console.log(context.parent.plannedAccountWorkingHours);
                if (!context.parent.plannedAccountWorkingHours && !context.parent.noPlannedAccountWorkingHours) {
                  return this.createError({ message: 'Kötelező kitölteni!' });
                } else {
                  return true;
                }
              }
            ), */


      creativeWorkingHoursData: Yup.string().test(
        "creativeWorkingHoursData",
        "Kötelező",
        function (value, context) {
          console.log('creativeWorkingHoursData ' + context.parent.plannedCreativeWorkingHours);
          if ((context.parent.plannedTurnOver != null || context.parent.plannedVendorCost != null || context.parent.plannedAccountWorkingHours 
            || context.parent.noPlannedCreativeWorkingHours || context.parent.noPlannedAccountWorkingHours) &&
            !context.parent.noPlannedCreativeWorkingHours && !context.parent.plannedCreativeWorkingHours) {
            return this.createError({ message: 'Kötelező kitölteni!' });
          } else {
            return true;
          }
        }
      ),


      accountWorkingHoursData: Yup.string().test(
        "accountWorkingHoursData",
        "Kötelező",
        function (value, context) {
          console.log('accountWorkingHoursData ' + context.parent.plannedAccountWorkingHours);
          if ((context.parent.plannedTurnOver != null || context.parent.plannedVendorCost != null || context.parent.plannedCreativeWorkingHours
            || context.parent.noPlannedCreativeWorkingHours || context.parent.noPlannedAccountWorkingHours) &&
            !context.parent.noPlannedAccountWorkingHours && !context.parent.plannedAccountWorkingHours) {
            return this.createError({ message: 'Kötelező kitölteni!' });
          } else {
            return true;
          }
        }
      ),


      plannedTurnOverData: Yup.string().test(
        "plannedTurnOverData",
        "Kötelező",
        function (value, context) {
          if ((context.parent.plannedCreativeWorkingHours || context.parent.plannedAccountWorkingHours || context.parent.plannedVendorCost != null
            || context.parent.noPlannedCreativeWorkingHours || context.parent.noPlannedAccountWorkingHours) &&
            context.parent.plannedTurnOver == null
          ) {
            return this.createError({ message: 'Kötelező kitölteni!' });
          } else {
            return true;
          }
        }
      ),

      plannedVendorCostData: Yup.string().test(
        "plannedVendorCostData",
        "Kötelező",
        function (value, context) {
          if ((context.parent.plannedCreativeWorkingHours || context.parent.plannedAccountWorkingHours || context.parent.plannedTurnOver != null
            || context.parent.noPlannedCreativeWorkingHours || context.parent.noPlannedAccountWorkingHours) &&
            context.parent.plannedVendorCost == null
          ) {
            return this.createError({ message: 'Kötelező kitölteni!' });
          } else {
            return true;
          }
        }
      ),



    }),
    onSubmit: (values) => {
      if (projectDetailDialogShow) {
        //console.log(values);
        confirmSave(values);
      }
    },
  });

  const turnOverLevel = () => {
    if (formik.values.plannedTurnOver === formik.values.invoicedTurnOver){
      return ( <span className="text-green-700 font-bold"> MEGEGYEZIK</span> );
    } else if (formik.values.plannedTurnOver === null) {
      return ( <span className="text-red-700 font-bold"> KITÖLTENDŐ</span> );
    } else {
      return (<span className="text-red-700 font-bold"> KÜLÖNBÖZIK</span>);
    }
  };

  const vendorCostLevel = () => {
    if (formik.values.plannedVendorCost === formik.values.invoicedVendorCost){
      return ( <span className="text-green-700 font-bold"> MEGEGYEZIK</span> );
    } else if (formik.values.plannedVendorCost === null) {
      return ( <span className="text-red-700 font-bold"> KITÖLTENDŐ</span> );
    } else {
      return (<span className="text-red-700 font-bold"> KÜLÖNBÖZIK</span>);
    }
  };



  const header = (
    <>
      <div className="formgrid grid">
        <div className="field col-12 md:col-8">
          <div className="formgrid grid">
            <div className="field mb-0">
              <label
                htmlFor="identifier"
                className="block text-900 font-medium mt-2 ml-2"
              >
                Munka Adatlap
              </label>
            </div>
            <div className="field col-9 mb-0">
              <InputText
                className="w-full mb-3"
                readOnly
                id="identifier"
                name="identifier"
                value={projectIdentifier}
              />
            </div>
          </div>

          {projectDto && projectDto.closed ? (
            <div className="formgrid grid mb-0">
              <div className="field mb-0">
                <Button
                  className="p-button-text"
                  type="button"
                  label="Bezárás"
                  icon="pi pi-times"
                  onClick={() => {
                    confirmCancelOfClosedProject();
                  }}
                />
              </div>
              <div className="field mb-0">
                <Button
                  className="p-button-text"
                  type="button"
                  label="A MUNKA LEZÁRVA DE SZERETNÉM ÚJRA SZERKESZTENI"
                  icon="pi pi-check"
                  onClick={() => {
                    confirmOpenProject(formik.values);
                  }}
                />
              </div>



            </div>
          ) : (
            <div className="formgrid grid mb-0">
              <div className="field mb-0">
                <Button
                  className="p-button-text"
                  type="button"
                  label="Bezárás mentés nélkül"
                  icon="pi pi-times"
                  onClick={() => {
                    confirmCancel();
                  }}
                />
              </div>

              <div className="field mb-0">
                <Button
                  className="p-button-text"
                  type="button"
                  label="Mentés"
                  icon="pi pi-check"
                  onClick={() => {
                    formik.submitForm();
                  }}
                />
              </div>
            </div>
          )}
        </div>
        <div className="field col-12 md:col-4">
          <div className="flex ml-2 align-items-start p-1 bg-orange-100 border-round border-1 border-yellow-300">
            <i className="pi pi-info-circle text-yellow-900 text-2xl mr-3 mt-1"></i>
            <div className="mr-1 text-base font-light">

              <p className="m-0 p-0 text-yellow-700 line-height-4">
                Soha ne hagyd nyitva ezt az ablakot,
              </p>
              <p className="m-0 p-0 text-yellow-700 line-height-4">
                vagy mentsd el a változtatásokat,
              </p>
              <p className="m-0 p-0 text-yellow-700 line-height-4">
                vagy zárd be mentés nélkül!
              </p>

            </div>
          </div>

          {projectDto && projectDto.closed ? (
            <div className="field mb-0">
              <div className="flex ml-2 align-items-start p-1 bg-purple-100 border-round border-1 border-purple-300 mt-3">
                <i className="pi pi-info-circle text-yellow-900 text-2xl mr-3 mt-1"></i>
                <div className="mr-1 text-base font-bold">
                  <p className="m-0 p-0 text-purple-700 line-height-4">
                    Munka lezárva
                  </p>
                </div>
              </div>
            </div>
          ) : null}

          {!formik.isValid ? (
            <div className="field mb-0">
              <div className="flex ml-2 align-items-start p-1 bg-red-100 border-round border-1 border-red-300 mt-3">
                <i className="pi pi-info-circle text-yellow-900 text-2xl mr-3 mt-1"></i>
                <div className="mr-1 text-base font-bold">
                  <p className="m-0 p-0 text-red-700 line-height-4">
                    KERESD MEG A JELZETT KITÖLTÉSI HIÁNYOSSÁGOT!
                  </p>
                </div>
              </div>
            </div>
          ) : null}

        </div>
      </div>


      <Divider className="mb-0" align="center"></Divider>
    </>
  );


  return (
    <>
      <Dialog
        //header="Munka Adatlap"
        header={header}
        visible={projectDetailDialogShow}
        //maximizable
        maximized
        modal
        closable={false}
        style={{ width: "98vw" }}
        onHide={() => confirmCloseDialog()}
      >
        <BlockUI className="opacity-10" blocked={(projectDto && projectDto.closed) || loading}>

          <MessageDialog
            messageDialogError={messageDialogError}
            messageDialogShow={messageDialogShow}
            messageDialogHeader={messageDialogHeader}
            messageDialogBody={messageDialogBody}
          />

          <form onSubmit={formik.handleSubmit} className="p-fluid">
            <Toast ref={toast} />
            <Divider align="center" type="dashed">
              <span className="p-tag">Alapadatok</span>
            </Divider>

            <div className="formgrid grid">
              <div className="field col-12 md:col-6">
                <label
                  htmlFor="businessOpportunity"
                  className="block text-900 font-medium mb-2"
                >
                  Tender vagy konkrét megbízás
                </label>
                <Dropdown
                  id="businessOpportunity"
                  className="w-full mb-3"
                  {...formik.getFieldProps("businessOpportunity")}
                  options={businessOpportunityItems}
                  onChange={formik.handleChange}
                  value={formik.values.businessOpportunity}
                  placeholder="Válasszon cégformát!"
                />
                {formik.touched.businessOpportunity &&
                  formik.errors.businessOpportunity ? (
                  <small className="p-error">
                    {formik.errors.businessOpportunity.toString()}
                  </small>
                ) : null}
              </div>

              <div className="field col-12 md:col-6">
                <label
                  htmlFor="invoiceFrom"
                  className="block text-900 font-medium mb-2"
                >
                  Számla kibocsájtó cégünk
                </label>
                <Dropdown
                  id="invoiceFrom"
                  className="w-full mb-3"
                  {...formik.getFieldProps("invoiceFrom")}
                  options={invoiceFromItems}
                  onChange={formik.handleChange}
                  value={formik.values.invoiceFrom}
                  placeholder="Válasszon cégformát!"
                />
                {formik.touched.invoiceFrom && formik.errors.invoiceFrom ? (
                  <small className="p-error">
                    {formik.errors.invoiceFrom.toString()}
                  </small>
                ) : null}
              </div>
            </div>

            <div className="formgrid grid">
              <div className="field col-12 md:col-3">
                <label
                  htmlFor="yearOfCompletion"
                  className="block text-900 font-medium mb-2"
                >
                  Évszám, ami a teljesítésként a számlára kerül
                </label>
                <Dropdown
                  id="yearOfCompletion"
                  className="w-full mb-3"
                  {...formik.getFieldProps("yearOfCompletion")}
                  options={yearItems}
                  onChange={formik.handleChange}
                  value={formik.values.yearOfCompletion}
                  placeholder="Válasszon cégformát!"
                />
                {formik.touched.yearOfCompletion &&
                  formik.errors.businessOpportunity ? (
                  <small className="p-error">
                    {formik.errors.yearOfCompletion?.toString()}
                  </small>
                ) : null}
              </div>

              <div className="field col-12 md:col-3">
                <label
                  htmlFor="monthOfCompletion"
                  className="block text-900 font-medium mb-2"
                >
                  Hónap, ami teljesítésként a számlára kerül
                </label>
                <Dropdown
                  id="monthOfCompletion"
                  className="w-full mb-3"
                  {...formik.getFieldProps("monthOfCompletion")}
                  options={monthItems}
                  onChange={formik.handleChange}
                  value={formik.values.monthOfCompletion}
                  placeholder="Válasszon cégformát!"
                />
                {formik.touched.monthOfCompletion &&
                  formik.errors.businessOpportunity ? (
                  <small className="p-error">
                    {formik.errors.monthOfCompletion?.toString()}
                  </small>
                ) : null}
              </div>

              <div className="field col-12 md:col-3">
                <label
                  htmlFor="advancePaymentNeeded"
                  className="block text-900 font-medium mb-2"
                >
                  Fogunk előleget kérni?
                </label>
                <Dropdown
                  id="advancePaymentNeeded"
                  className="w-full mb-3"
                  {...formik.getFieldProps("advancePaymentNeeded")}
                  options={advancePaymentNeededtems}
                  onChange={formik.handleChange}
                  value={formik.values.advancePaymentNeeded}
                />
                {formik.touched.advancePaymentNeeded &&
                  formik.errors.advancePaymentNeeded ? (
                  <small className="p-error">
                    {formik.errors.advancePaymentNeeded?.toString()}
                  </small>
                ) : null}
              </div>

              <div className="field col-12 md:col-3">
                <label
                  htmlFor="advancePaymentNeeded"
                  className="block text-900 font-medium mb-2"
                >
                  Előlegbekérő kiment?
                </label>
                {formik.values.advancePaymentNeeded === "YES" ? (
                  <Dropdown
                    id="advanceLetterSent"
                    className="w-full mb-3"
                    {...formik.getFieldProps("advanceLetterSent")}
                    options={advancePaymentNeededtems}
                    onChange={formik.handleChange}
                    value={formik.values.advanceLetterSent}
                  />
                ) : (
                  <InputText
                    className="w-full mb-3"
                    id="advanceLetterSent"
                    name="advanceLetterSent"
                    value="Nem kérünk előleget"
                  />
                )}
                {formik.touched.advancePaymentNeeded &&
                  formik.errors.advancePaymentNeeded ? (
                  <small className="p-error">
                    {formik.errors.advancePaymentNeeded?.toString()}
                  </small>
                ) : null}
              </div>
            </div>

            <div className="formgrid grid">
              <div className="field col-12 md:col-4">
                <label
                  htmlFor="customerId"
                  className="block text-900 font-medium mb-2"
                >
                  Ügyfél
                </label>
                <Dropdown
                  id="customerId"
                  className="w-full mb-3"
                  {...formik.getFieldProps("customerId")}
                  options={customers}
                  onChange={onCustomerChange}
                  value={formik.values.customerId}
                  placeholder="Válasszon!"
                />
                {formik.touched.customerId && formik.errors.customerId ? (
                  <small className="p-error">
                    {formik.errors.customerId?.toString()}
                  </small>
                ) : null}
              </div>

              <div className="field col-12 md:col-4">
                <label
                  htmlFor="branchId"
                  className="block text-900 font-medium mb-2"
                >
                  Üzletág / Termék / Terület
                </label>
                <Dropdown
                  id="branchId"
                  className="w-full mb-3"
                  {...formik.getFieldProps("branchId")}
                  options={branches}
                  onChange={formik.handleChange}
                  value={formik.values.branchId}
                  placeholder="Válasszon!"
                />
                {formik.touched.branchId && formik.errors.branchId ? (
                  <small className="p-error">
                    {formik.errors.branchId?.toString()}
                  </small>
                ) : null}
              </div>

              <div className="field col-12 md:col-4">
                <label htmlFor="name" className="block text-900 font-medium mb-2">
                  Írj be egy pontos, leíró munkanevet!
                </label>
                <InputText
                  className="w-full mb-3"
                  id="name"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                />
                {formik.touched.name && formik.errors.name ? (
                  <small className="p-error">
                    {formik.errors.name.toString()}
                  </small>
                ) : null}
              </div>
            </div>

            <Divider align="center" type="dashed">
              <span className="p-tag">Munka típus kódjai</span>
            </Divider>

            <div className="formgrid grid">
              <div className="field col-12 md:col-3">
                <label
                  htmlFor="projectTypeLevel1"
                  className="block text-900 font-medium mb-2"
                >
                  Munka típuskódja 1
                </label>
                <Dropdown
                  id="projectTypeLevel1"
                  className="w-full mb-3"
                  {...formik.getFieldProps("projectTypeLevel1")}
                  options={projectTypesLevel1}
                  //onChange={formik.handleChange}
                  onChange={onProjectTypeLevel1Change}
                  value={formik.values.projectTypeLevel1}
                  placeholder="Válasszon!"
                />
                {formik.touched.projectTypeLevel1 &&
                  formik.errors.projectTypeLevel1 ? (
                  <small className="p-error">
                    {formik.errors.projectTypeLevel1?.toString()}
                  </small>
                ) : null}
              </div>

              <div className="field col-12 md:col-2">
                <label
                  htmlFor="projectTypeLevel2"
                  className="block text-900 font-medium mb-2"
                >
                  Munka típuskódja 2
                </label>
                <Dropdown
                  id="projectTypeLevel2"
                  className="w-full mb-3"
                  {...formik.getFieldProps("projectTypeLevel2")}
                  options={projectTypesLevel2}
                  onChange={onProjectTypeLevel2Change}
                  //onChange={formik.handleChange}
                  value={formik.values.projectTypeLevel2}
                  placeholder="Válasszon!"
                />
                {formik.touched.projectTypeLevel2 &&
                  formik.errors.projectTypeLevel2 ? (
                  <small className="p-error">
                    {formik.errors.projectTypeLevel2?.toString()}
                  </small>
                ) : null}
              </div>

              <div className="field col-12 md:col-3">
                <label
                  htmlFor="projectTypeLevel3"
                  className="block text-900 font-medium mb-2"
                >
                  Munka típuskódja 3
                </label>
                <Dropdown
                  id="projectTypeLevel3"
                  className="w-full mb-3"
                  {...formik.getFieldProps("projectTypeLevel3")}
                  options={projectTypesLevel3}
                  onChange={onProjectTypeLevel3Change}
                  //onChange={formik.handleChange}
                  value={formik.values.projectTypeLevel3}
                  placeholder="Válasszon!"
                />
                {formik.touched.projectTypeLevel3 &&
                  formik.errors.projectTypeLevel3 ? (
                  <small className="p-error">
                    {formik.errors.projectTypeLevel3?.toString()}
                  </small>
                ) : null}
              </div>

              <div className="field col-12 md:col-2">
                <label
                  htmlFor="projectTypeLevel4"
                  className="block text-900 font-medium mb-2"
                >
                  Munka típuskódja 4
                </label>
                <Dropdown
                  id="projectTypeLevel4"
                  className="w-full mb-3"
                  {...formik.getFieldProps("projectTypeLevel4")}
                  options={projectTypesLevel4}
                  onChange={onProjectTypeLevel4Change}
                  //onChange={formik.handleChange}
                  value={formik.values.projectTypeLevel4}
                  placeholder="Válasszon!"
                />
                {formik.touched.projectTypeLevel4 &&
                  formik.errors.projectTypeLevel4 ? (
                  <small className="p-error">
                    {formik.errors.projectTypeLevel4?.toString()}
                  </small>
                ) : null}
              </div>

              <div className="field col-12 md:col-2">
                <label
                  htmlFor="projectTypeLevel5"
                  className="block text-900 font-medium mb-2"
                >
                  Munka típuskódja 5
                </label>
                <Dropdown
                  id="projectTypeLevel5"
                  className="w-full mb-3"
                  {...formik.getFieldProps("projectTypeLevel5")}
                  options={projectTypesLevel5}
                  onChange={formik.handleChange}
                  value={formik.values.projectTypeLevel5}
                  placeholder="Válasszon!"
                />
                {formik.touched.projectTypeLevel5 &&
                  formik.errors.projectTypeLevel5 ? (
                  <small className="p-error">
                    {formik.errors.projectTypeLevel5?.toString()}
                  </small>
                ) : null}
              </div>
            </div>

            <Divider align="center" type="dashed">
              <span className="p-tag">Résztvevők</span>
            </Divider>

            <div className="formgrid grid">
              <div className="field col-12 md:col-3">
                <label
                  htmlFor="selectedAccount"
                  className="block text-900 font-medium mb-2"
                >
                  Account részvevők, mint Te
                </label>

                <div className="formgrid grid">
                  <div className="col-10">
                    <Dropdown
                      id="selectedAccount"
                      className="w-full mb-3"
                      {...formik.getFieldProps("selectedAccount")}
                      options={allAccountSkyUsers}
                      onChange={formik.handleChange}
                      value={formik.values.selectedAccount}
                      placeholder="Válasszon!"
                    />
                  </div>
                  <div className="col-2">
                    <Button
                      type="button"
                      className="w-full mb-2 p-button-outlined"
                      icon="pi pi-plus"
                      onClick={() => {
                        console.log(formik.values.selectedAccount);
                        if (formik.values.selectedAccount !== "") {
                          let oldList = [...accountSkyUserList];
                          let emptySkyUser = { id: "0", name: "" };

                          for (var oldItem of oldList) {
                            if (formik.values.selectedAccount === oldItem.id) {
                              return;
                            }
                          }

                          for (var accountUser of allAccountSkyUsers) {
                            if (
                              formik.values.selectedAccount === accountUser.value
                            ) {
                              emptySkyUser.id = accountUser.value;
                              emptySkyUser.name = accountUser.label;
                            }
                          }

                          oldList.push(emptySkyUser);
                          setAccountSkyUserList(oldList);
                        }
                      }}
                    />
                  </div>
                </div>

                <DataTable
                  className="admin-list"
                  size="small"
                  value={accountSkyUserList}
                  responsiveLayout="scroll"
                >
                  <Column header="Törlés" body={deleteAccountTemplate} />
                  <Column field="id" header="ID" />
                  <Column field="name" header="Név" />
                </DataTable>
              </div>

              <div className="field col-12 md:col-3">
                <label
                  htmlFor="selectedCoworker"
                  className="block text-900 font-medium mb-2"
                >
                  Munkatárs részvevők, csak órát írnak
                </label>

                <div className="formgrid grid">
                  <div className="col-10">
                    <Dropdown
                      id="selectedCoworker"
                      className="w-full mb-3"
                      {...formik.getFieldProps("selectedCoworker")}
                      options={allCoworkerSkyUsers}
                      onChange={formik.handleChange}
                      value={formik.values.selectedCoworker}
                      placeholder="Válasszon!"
                    />
                  </div>
                  <div className="col-2">
                    <Button
                      type="button"
                      className="w-full mb-2 p-button-outlined"
                      icon="pi pi-plus"
                      onClick={() => {
                        console.log(formik.values.selectedAccount);
                        if (formik.values.selectedCoworker !== "") {
                          let oldList = [...coworkerSkyUserList];
                          let emptySkyUser = { id: "0", name: "" };

                          for (var oldItem of oldList) {
                            if (formik.values.selectedAccount === oldItem.id) {
                              return;
                            }
                          }

                          for (var coworkerUser of allCoworkerSkyUsers) {
                            if (
                              formik.values.selectedCoworker ===
                              coworkerUser.value
                            ) {
                              emptySkyUser.id = coworkerUser.value;
                              emptySkyUser.name = coworkerUser.label;
                            }
                          }

                          oldList.push(emptySkyUser);
                          setCoworkerSkyUserList(oldList);
                        }
                      }}
                    />
                  </div>
                </div>

                <DataTable
                  className="admin-list"
                  size="small"
                  value={coworkerSkyUserList}
                  responsiveLayout="scroll"
                >
                  <Column header="Törlés" body={deleteCoworkerTemplate} />
                  <Column field="id" header="ID" />
                  <Column field="name" header="Név" />
                </DataTable>
              </div>

              <div className="field col-12 md:col-3">
                <label
                  htmlFor="selectedCreative"
                  className="block text-900 font-medium mb-2"
                >
                  Kreatív részvevők, csak órát írnak
                </label>

                <div className="formgrid grid">
                  <div className="col-10">
                    <Dropdown
                      id="selectedCreative"
                      className="w-full mb-3"
                      {...formik.getFieldProps("selectedCreative")}
                      options={allCreativeSkyUsers}
                      onChange={formik.handleChange}
                      value={formik.values.selectedCreative}
                      placeholder="Válasszon!"
                    />
                  </div>
                  <div className="col-2">
                    <Button
                      type="button"
                      className="w-full mb-2 p-button-outlined"
                      icon="pi pi-plus"
                      onClick={() => {
                        console.log(formik.values.selectedCreative);
                        if (formik.values.selectedCreative !== "") {
                          let oldList = [...creativeSkyUserList];
                          let emptySkyUser = { id: "0", name: "" };

                          for (var oldItem of oldList) {
                            if (formik.values.selectedAccount === oldItem.id) {
                              return;
                            }
                          }

                          for (var creativeUser of allCreativeSkyUsers) {
                            if (
                              formik.values.selectedCreative ===
                              creativeUser.value
                            ) {
                              emptySkyUser.id = creativeUser.value;
                              emptySkyUser.name = creativeUser.label;
                            }
                          }

                          oldList.push(emptySkyUser);
                          setCreativeSkyUserList(oldList);
                        }
                      }}
                    />
                  </div>
                </div>

                <DataTable
                  className="admin-list"
                  size="small"
                  value={creativeSkyUserList}
                  responsiveLayout="scroll"
                >
                  <Column header="Törlés" body={deleteCreativeTemplate} />
                  <Column field="id" header="ID" />
                  <Column field="name" header="Név" />
                </DataTable>
              </div>

              <div className="field col-12 md:col-3">
                <label
                  htmlFor="selectedCreative"
                  className="block text-900 font-medium mb-2"
                >
                  Általános részvevők - EZT CSAK AZ ADMIN HASZNÁLJA
                </label>

                <div className="formgrid grid">
                  <div className="col-10">
                    <Dropdown
                      id="selectedGeneric"
                      className="w-full mb-3"
                      {...formik.getFieldProps("selectedGeneric")}
                      options={allGenericSkyUsers}
                      onChange={formik.handleChange}
                      value={formik.values.selectedGeneric}
                      placeholder="Válasszon!"
                    />
                  </div>
                  <div className="col-2">
                    <Button
                      type="button"
                      className="w-full mb-2 p-button-outlined"
                      icon="pi pi-plus"
                      onClick={() => {
                        console.log(formik.values.selectedGeneric);
                        if (formik.values.selectedGeneric !== "") {
                          let oldList = [...genericSkyUserList];
                          let emptySkyUser = { id: "0", name: "" };

                          for (var oldItem of oldList) {
                            if (formik.values.selectedGeneric === oldItem.id) {
                              return;
                            }
                          }

                          for (var genericUser of allGenericSkyUsers) {
                            if (
                              formik.values.selectedGeneric === genericUser.value
                            ) {
                              emptySkyUser.id = genericUser.value;
                              emptySkyUser.name = genericUser.label;
                            }
                          }

                          oldList.push(emptySkyUser);
                          setGenericSkyUserList(oldList);
                        }
                      }}
                    />
                  </div>
                </div>

                <DataTable
                  className="admin-list"
                  size="small"
                  value={genericSkyUserList}
                  responsiveLayout="scroll"
                >
                  <Column header="Törlés" body={deleteGenericTemplate} />
                  <Column field="id" header="ID" />
                  <Column field="name" header="Név" />
                </DataTable>
              </div>
            </div>

            <Divider align="center" type="dashed">
              <span className="p-tag">Egyéb nem kötelező adatok</span>
            </Divider>

            <div className="formgrid grid">
              <div className="field col-12">
                <label
                  htmlFor="notes"
                  className="block text-900 font-medium mb-2"
                >
                  Megjegyzés - ide írhatsz bármilyen megjegyzést, de nem kötelező
                </label>

                <InputTextarea
                  className="w-full mb-3"
                  rows={2}
                  id="notes"
                  name="notes"
                  value={formik.values.notes}
                  onChange={formik.handleChange}
                />
                {formik.touched.notes && formik.errors.notes ? (
                  <small className="p-error">
                    {formik.errors.notes.toString()}
                  </small>
                ) : null}
              </div>
            </div>

            <div className="formgrid grid">
              <div className="field col-12 md:col-3">
                <label htmlFor="name" className="block text-900 font-medium mb-2">
                  Ügyfél menedzser monogramja - nem kötelező
                </label>
                <InputText
                  className="w-full mb-3"
                  id="monogram"
                  name="monogram"
                  maxLength={4}
                  value={formik.values.monogram}
                  onChange={formik.handleChange}
                />
              </div>

              <div className="field col-12 md:col-5">
                <div className="field-checkbox col">
                  <Checkbox
                    className="mt-5"
                    id="monthlyPaid"
                    name="monthlyPaid"
                    checked={formik.values.monthlyPaid}
                    onChange={formik.handleChange}
                  />
                  <label
                    htmlFor="monthlyPaid"
                    className="block text-900 font-medium mt-5"
                  >
                    Havi elszámolás - ezt csak az E.ON munkákhoz használjuk
                  </label>
                </div>
                {formik.touched.monthlyPaid && formik.errors.monthlyPaid ? (
                  <small className="p-error">
                    {formik.errors.monthlyPaid.toString()}
                  </small>
                ) : null}
              </div>

              <div className="field col-12 md:col-4">
                <div className="field-checkbox col">
                  <Checkbox
                    className="mt-5"
                    id="administrative"
                    name="administrative"
                    checked={formik.values.administrative}
                    onChange={formik.handleChange}
                  />
                  <label
                    htmlFor="administrative"
                    className="block text-900 font-medium mt-5"
                  >
                    Adminisztratív - ezt csak az admin használja
                  </label>
                </div>
                {formik.touched.administrative && formik.errors.administrative ? (
                  <small className="p-error">
                    {formik.errors.administrative.toString()}
                  </small>
                ) : null}
              </div>
            </div>

            <div className="formgrid grid">
              <div className="field col-12">
                <div className="flex align-items-center justify-content-center p-3 bg-gray-500 border-round border-1 border-gray-300">
                  <div className="mr-0">
                    <p className="m-0 p-0 text-100 line-height-3 font-bold">
                      Munkaóra elszámolás eredményessége az eddigi tényórák alapján{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>


            <div className="formgrid grid">
              <div className="field col-12">
                <label
                  htmlFor="taskList"
                  className="block text-900 font-medium mb-2"
                >
                  Eddigi tényórák
                </label>

                <DataTable
                  className="admin-list"
                  size="small"
                  value={projectDto ? projectDto.taskList : null}
                  responsiveLayout="scroll"
                  rowGroupMode="subheader"
                  rowGroupFooterTemplate={salesContractLineFooterTemplate}
                >
                  <Column field="rowIndex" header="" frozen style={{ width: '50px' }} body={onRowIndexTemplate} />
                  <Column field="id" header="ID" />
                  <Column
                    field="monthlyFee"
                    header="Havi"
                    body={monthlyFeeTemplate}
                  />
                  <Column field="name" header="Feladat neve" sortable />
                  <Column field="participantName" header="Résztvevő" sortable />
                  <Column field="role" header="Szerepkör" sortable />
                  <Column
                    align="right"
                    field="lowHours"
                    header="LOW órák száma"
                  />
                  <Column
                    align="right"
                    field="mediumHours"
                    header="MED órák száma"
                  />
                  <Column align="right" field="highHours" header="HIGH száma" />
                  <Column
                    align="right"
                    field="workingHours"
                    header="Munkaórák száma"
                  />
                </DataTable>
              </div>
            </div>


            <div className="formgrid grid mt-5">
              <div className="col-12">
                <div className="flex mb-4 align-items-start p-3 bg-orange-100 border-round border-1 border-yellow-300">
                  <i className="pi pi-info-circle text-yellow-900 text-2xl mr-3"></i>
                  <div className="mr-1">
                    <p className="m-0 p-0 text-yellow-700 line-height-3">
                      Csak akkor töltsd ki a KREATÍV/ACCOUNT munkaóra darabszámokat, ha azok dbszám-szerűen szerepelnek az árajánlatban, <br />
                      ÉS ha az adott árajánlatban a kreatív/account munka nincs más egyéb formában IS elszámolva pl. kreatív eszközdíjban, mert ha igen akkor a boksz-ot pipáld.<br />
                      (A koncepció díj, ügynökségi jutalék nem számít olyan konkrét más egyéb kreatív/account munka elszámolási formának mint az óradíj vagy a kreatív eszközdíj)
                    </p>
                  </div>
                </div>
              </div>
            </div>





            <div className="formgrid grid">
              <div className="field col-12 md:col-4">

                <div className="formgrid grid">
                  <div className="field col-6 mb-0">
                    <label
                      htmlFor="plannedCreativeWorkingHours"
                      className="block text-900 font-medium mb-2"
                    >
                      Árajánlatban szereplő <br />Összes KREATÍV <br />munkaóra db száma
                    </label>
                  </div>
                  <div className="field col-6 mb-0">
                    <div className="field-checkbox col">
                      <Checkbox
                        id="noPlannedCreativeWorkingHours"
                        name="noPlannedCreativeWorkingHours"
                        checked={formik.values.noPlannedCreativeWorkingHours}
                        onChange={onNoPlannedCreativeWorkingHoursChange}
                      />
                      <label
                        htmlFor="noPlannedCreativeWorkingHours"
                        className="block text-900 font-medium  mb-0"
                      >
                        Árajánlatban nem <br />került feltüntetésre
                      </label>
                    </div>
                  </div>
                </div>

                <InputNumber
                  className="mb-3"
                  value={formik.values.plannedCreativeWorkingHours}
                  onValueChange={(e: any) => {
                    formik.setFieldValue("plannedCreativeWorkingHours", e.value);

                    if (e.value === null && formik.values.allCreativeWorkingHours === null) {
                      setCreativeWorkingHoursPercentageVisible(false);
                    } else {
                      setCreativeWorkingHoursPercentageVisible(true);
                      setCreativeWorkingHoursPercentage(formik.values.allCreativeWorkingHours / (e.value === null ? 1 : e.value) * 100);
                    }

                  }
                  }
                  mode="decimal"
                  readOnly={formik.values.noPlannedCreativeWorkingHours}
                  maxFractionDigits={0}
                  currency="HUF"
                  locale="hu-HU"
                />
                {formik.touched.creativeWorkingHoursData && formik.errors.creativeWorkingHoursData ? (
                  <small className="p-error">
                    {formik.errors.creativeWorkingHoursData.toString()}
                  </small>
                ) : null}
              </div>
              <div className="field col-12 md:col-4">
                <label
                  htmlFor="allCreativeWorkingHours"
                  className="block text-900 font-medium"
                  style={{ marginTop: '38px' }}
                >
                  Összes Tény KREATÍV munkaóra db száma
                </label>
                <InputNumber
                  value={formik.values.allCreativeWorkingHours}
                  mode="decimal"
                  readOnly
                  maxFractionDigits={1}
                  currency="HUF"
                  locale="hu-HU"
                />
              </div>

              <div className="field col-12 md:col-4">
                {creativeWorkingHoursPercentageVisible ? (
                  <Percentage role='KREATÍV' percentage={creativeWorkingHoursPercentage} />
                ) : null}
              </div>
            </div>

            <div className="formgrid grid">
              <div className="field col-12 md:col-4">
                <div className="formgrid grid mt-1">
                  <div className="field col-6 mb-0">
                    <label
                      htmlFor="plannedAccountWorkingHours"
                      className="block text-900 font-medium mb-2"
                    >
                      Árajánlatban szereplő <br />Összes ACCOUNT <br />munkaóra db száma
                    </label>
                  </div>
                  <div className="field col-6 mb-0">
                    <div className="field-checkbox col">
                      <Checkbox
                        id="noPlannedAccountWorkingHours"
                        name="noPlannedAccountWorkingHours"
                        checked={formik.values.noPlannedAccountWorkingHours}
                        onChange={onNoPlannedAccountWorkingHoursChange}
                      />
                      <label
                        htmlFor="noPlannedAccountWorkingHours"
                        className="block text-900 font-medium  mb-0"
                      >
                        Árajánlatban nem <br />került feltüntetésre
                      </label>
                    </div>
                  </div>
                </div>
                <InputNumber
                  value={formik.values.plannedAccountWorkingHours}
                  onValueChange={(e: any) => {
                    formik.setFieldValue("plannedAccountWorkingHours", e.value);


                    if (e.value === null && formik.values.allAccountWorkingHours === null) {
                      setAccountWorkingHoursPercentageVisible(false);
                    } else {
                      setAccountWorkingHoursPercentageVisible(true);
                      setAccountWorkingHoursPercentage(formik.values.allAccountWorkingHours / (e.value === null ? 1 : e.value) * 100);
                    }
                  }
                  }
                  mode="decimal"
                  readOnly={formik.values.noPlannedAccountWorkingHours}
                  maxFractionDigits={0}
                  currency="HUF"
                  locale="hu-HU"
                />
                {formik.touched.accountWorkingHoursData && formik.errors.accountWorkingHoursData ? (
                  <small className="p-error">
                    {formik.errors.accountWorkingHoursData.toString()}
                  </small>
                ) : null}
              </div>

              <div className="field col-12 md:col-4">
                <label
                  htmlFor="allAccountWorkingHours"
                  className="block text-900 font-medium"
                  style={{ marginTop: '42px' }}
                >
                  Összes Tény ACCOUNT munkaóra db száma
                </label>
                <InputNumber
                  value={formik.values.allAccountWorkingHours}
                  mode="decimal"
                  readOnly
                  maxFractionDigits={1}
                  currency="HUF"
                  locale="hu-HU"
                />
              </div>

              <div className="field col-12 md:col-4">
                {accountWorkingHoursPercentageVisible ? (
                  <Percentage role='ACCOUNT' percentage={accountWorkingHoursPercentage} />
                ) : null}
              </div>
            </div>





            <div className="formgrid grid">
              <div className="field col-12">
                <div className="flex align-items-center justify-content-center p-3 bg-gray-500 border-round border-1 border-gray-300">
                  <div className="mr-0">
                    <p className="m-0 p-0 text-100 line-height-3 font-bold">
                      Üzleti adatok és eredmények{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <Divider align="center">
              <span className="p-tag">Pénzügyi alapadatok és Üzleti eredményesség</span>
            </Divider>


            <div className="formgrid grid">
              <div className="field col-4">
                <label
                  htmlFor="plannedTurnOver"
                  className="block text-900 font-medium mb-2"
                >
                  Teljes kiszámlázandó FORGALOM (Árbevétel)
                </label>
                <label
                  htmlFor="plannedTurnOver"
                  className="block text-900 font-medium mb-2 text-sm "
                >
                  (Árajánlat(ok) és/vagy Médiaterv(ek) összesen végösszege)
                </label>
                <InputNumber
                  value={formik.values.plannedTurnOver}
                  onValueChange={(e: any) => {
                    formik.setFieldValue("plannedTurnOver", e.value);
                    var profitInHuf = (e.value === null ? 0 : e.value) - formik.values.plannedVendorCost;
                    formik.setFieldValue("profitInHuf", profitInHuf);
                    formik.setFieldValue("profitInPercentage", (profitInHuf / (e.value === null ? 1 : e.value) * 100));
                  }
                  }
                  mode="currency"
                  min={0}
                  maxFractionDigits={0}
                  currency="HUF"
                  locale="hu-HU"
                />
                {formik.values.plannedTurnOver === formik.values.invoicedTurnOver ||
                  (formik.values.plannedTurnOver === null && formik.values.invoicedTurnOver === 0)
                  ? (
                    <label
                      htmlFor="plannedTurnOver"
                      className="block text-900 font-medium text-sm mt-2 text-green-700"
                    >
                      Ebből eddig kiszámlázva: {formatCurrency(formik.values.invoicedTurnOver)}
                    </label>
                  ) : (
                    <label
                      htmlFor="plannedTurnOver"
                      className="block text-900 font-medium text-sm mt-2 text-red-700"
                    >
                      Ebből eddig kiszámlázva: {formatCurrency(formik.values.invoicedTurnOver)}
                    </label>
                  )}
                {formik.touched.plannedTurnOverData && formik.errors.plannedTurnOverData ? (
                  <small className="p-error">
                    {formik.errors.plannedTurnOverData.toString()}
                  </small>
                ) : null}



                <Divider className="mt-5 mb-5" align="center">
                  {/* <span className="p-tag"></span> */}
                </Divider>


                <label
                  htmlFor="plannedVendorCost"
                  className="block text-900 font-medium mt-3"
                >
                  ALVÁLLALKOZÓI költségek Összesen
                </label>
                <label
                  htmlFor="plannedVendorCost"
                  className="block text-900 font-medium mt-2 text-sm "
                >
                  (Árajánlat(ok)hoz és/vagy Médiaterv(ek)hez tartozó összes alvállalkozói költség egy összegben)
                </label>
                <InputNumber
                  value={formik.values.plannedVendorCost}
                  onValueChange={(e: any) => {
                    formik.setFieldValue("plannedVendorCost", e.value);
                    var profitInHuf = (formik.values.plannedTurnOver === null ? 0 : formik.values.plannedTurnOver) - e.value;
                    formik.setFieldValue("profitInHuf", profitInHuf);
                    formik.setFieldValue("profitInPercentage", (profitInHuf / (formik.values.plannedTurnOver === null ? 1 : formik.values.plannedTurnOver) * 100));
                  }
                  }
                  mode="currency"
                  min={0}
                  maxFractionDigits={0}
                  currency="HUF"
                  locale="hu-HU"
                />

                {formik.values.plannedVendorCost === formik.values.invoicedVendorCost ||
                  (formik.values.plannedVendorCost === null && formik.values.invoicedVendorCost === 0) ? (
                  <label
                    htmlFor="plannedTurnOver"
                    className="block text-900 font-medium text-sm mt-2 text-green-700"
                  >
                    Ebből eddig bejött leigazolva: {formatCurrency(formik.values.invoicedVendorCost)}
                  </label>
                ) : (
                  <label
                    htmlFor="plannedTurnOver"
                    className="block text-900 font-medium text-sm mt-2 text-red-700"
                  >
                    Ebből eddig bejött leigazolva: {formatCurrency(formik.values.invoicedVendorCost)}
                  </label>
                )}

                {formik.touched.plannedVendorCostData && formik.errors.plannedVendorCostData ? (
                  <small className="p-error">
                    {formik.errors.plannedVendorCostData.toString()}
                  </small>
                ) : null}

              </div>


              <div className="field col-4">
                <div className="formgrid grid">
                  <div className="field col-12">
                    <div className="flex align-items-start p-3 bg-orange-100 border-round border-1 border-yellow-300 h-21rem">
                      <i className="pi pi-info-circle text-yellow-900 text-2xl mr-3"></i>
                      <div className="mr-1">
                        <p className="m-0 p-0 text-yellow-700 line-height-3">
                          Ha már nagyjából tudod a büdzsét és a költségeket, <br />

                          vagy elkészültél a Munkaszámhoz tartozó Árajánlatt(okk)al és/vagy Médiaterv(ekk)el,<br />

                          akkor TÖLTSD KI MINDKÉT CELLÁT!<br />

                          Változás esetén mindig módosítsd!
                        </p>
                      </div>
                    </div>


                  </div>

                </div>

              </div>


              <div className="field col-4">
                <label
                  htmlFor="financialNotes"
                  className="block text-900 font-medium"
                >
                  Megjegyzés (opcionális)
                </label>
                <InputTextarea
                  className="w-full mb-3"
                  rows={16}
                  id="financialNotes"
                  name="financialNotes"
                  value={formik.values.financialNotes}
                  onChange={formik.handleChange} />
              </div>




              {/*               <VerifyProjectVendorAssignment
                rowData={projectVendorAssignmentLine}
                invoiceFrom={projectDto ? projectDto.invoiceFrom : ""}
                refresh={refreshProjectVendorAssignmentLine}
                show={projectVendorAssignmentDialogShow}
                change={projectVendorAssignmentDialogChange}
              /> */}
              {/*               <UpdateProjectVendorAssignment
                rowData={projectVendorAssignmentLine}
                refresh={refreshProjectVendorAssignmentLine}
                show={updateVendorAmountDialogShow}
                change={updateVendorAmountDialogChange}
              /> */}
              {/*               <UpdateProjectContributorAssignment
                rowData={projectContributorAssignmentLine}
                refresh={refreshProjectContributorAssignmentLine}
                show={updateContributorAmountDialogShow}
                change={updateContributorAmountDialogChange}
              /> */}
            </div>


            <div className="formgrid grid mt-5">
              <div className="field col-4">
                <label
                  htmlFor="profitInHuf"
                  className="block text-900 font-medium "
                  style={{ marginTop: '25px' }}
                >
                  Pénzügyi NYERESÉG Ft-ban.
                </label>
                <InputNumber
                  value={formik.values.profitInHuf}
                  onValueChange={(e: any) => {
                    formik.setFieldValue("profitInHuf", e.value);
                  }
                  }
                  mode="currency"
                  readOnly
                  maxFractionDigits={0}
                  currency="HUF"
                  locale="hu-HU"
                />
                <label
                  htmlFor="profitInHuf"
                  className="block text-900 font-medium text-sm mt-2"
                >
                  (Kiszámlázandó forgalom és alvállalkozói költségek különbsége)
                </label>
              </div>

              <div className="field col-4">
                <label
                  htmlFor="profitInPercentage"
                  className="block text-900 font-medium mt-4"
                >
                  Pénzügyi NYERESÉG %-ban.
                </label>

                <InputNumber
                  value={formik.values.profitInPercentage}
                  onValueChange={(e: any) =>
                    formik.setFieldValue("profitInPercentage", e.value)
                  }
                  //mode="percentage"
                  readOnly
                  maxFractionDigits={0}
                  suffix="%"
                //currency="HUF"
                //locale="hu-HU"
                />
                <label
                  htmlFor="profitInPercentage"
                  className="block text-900 font-medium text-sm mt-2"
                >
                  (Pénzügyi Nyereség a Forgalomhoz viszonyítva.)
                </label>

              </div>
              <div className="field col-4">
                <label
                  htmlFor="financialResultInPercentage"
                  className="block text-900 font-medium mt-4"
                >
                  Üzleti EREDMÉNYESSÉG %-ban.
                </label>
                <InputText
                  readOnly
                  value={financialResultInText(formik.values.financialResultInPercentage)}
                  className="w-full"
                />
                <label
                  htmlFor="financialResultInPercentage"
                  className="block text-900 font-medium text-sm mt-2"
                >
                  (Tény Munkaórák és a Rezsi összköltségének levonása után maradó Eredmény a Pénzügyi Nyereséghez viszonyítva.)
                </label>

              </div>
            </div>



            <div className="formgrid grid mt-5">
              <div className="field col-7 pr-2 pt-5">


                <label
                  htmlFor="plannedTurnOver"
                  className="block text-900 font-medium mt-2 text-right"
                >
                  „Teljes kiszámlázandó FORGALOM” és a „Vevői számlák” összege:
                  {turnOverLevel()}
                  
                  {/* {formik.values.plannedTurnOver === formik.values.invoicedTurnOver ||
                    (formik.values.plannedTurnOver === null && formik.values.invoicedTurnOver === 0) ? (
                    <span className="text-green-700 font-bold"> MEGEGYEZIK</span>
                  ) : (
                    <span className="text-red-700 font-bold"> KÜLÖNBÖZIK</span>
                  )} */}
                </label>




                <label
                  htmlFor="plannedTurnOver"
                  className="block text-900 font-medium mt-2 text-right"
                >
                  „ALVÁLLALAKOZÓI költségek Összesen” és a Leigazolt Alvállalkozói számlák” összege:
                  {vendorCostLevel()}
                  {/* {formik.values.plannedVendorCost === formik.values.invoicedVendorCost ||
                    (formik.values.plannedVendorCost === null && formik.values.invoicedVendorCost === 0) ? (
                    <span className="text-green-700 font-bold"> MEGEGYEZIK</span>
                  ) : (
                    <span className="text-red-700 font-bold"> KÜLÖNBÖZIK</span>
                  )} */}
                </label>
              </div>

              <div className="field col-2">
                {(formik.values.plannedVendorCost === formik.values.invoicedVendorCost  &&

                  formik.values.plannedTurnOver === formik.values.invoicedTurnOver ) ? (
                  <Button type="button" label="MINDEN EGYEZIK A MUNKÁT LEZÁROM" className="p-button-lg h-8rem bg-green-500" onClick={() => { confirmCloseProject(formik.values) }} />
                ) : (
                  <Button disabled type="button" label="A MUNKA MÉG NEM LEZÁRHATÓ" className="p-button-lg h-8rem bg-red-500" />
                )
                }
              </div>


              <div className="field col-3">
                <div className="flex align-items-start p-3 bg-orange-100 border-round border-1 border-yellow-300">
                  <i className="pi pi-info-circle text-yellow-900 text-2xl mr-3"></i>
                  <div className="mr-1">
                    <p className="m-0 p-0 text-yellow-700 line-height-3">
                      Akkor zárd le a Munkát ha meggyőződtél róla, hogy minden hozzáadott résztvevő beírta és lezárta az idevonatkozó munkaóráit.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <Divider align="center">
              <span className="p-tag">Leigazolt alvállalkozói számlák</span>
            </Divider>

            <DataTable
              className="admin-list"
              size="small"
              value={projectDto ? projectDto.vendorInvoiceList : null}
              responsiveLayout="scroll"
              rowGroupMode="subheader"
              rowGroupFooterTemplate={vendorInvoiceFooterTemplate}

            >
              <Column field="rowIndex" header="" frozen style={{ width: '50px' }} body={onRowIndexTemplate} />

              <Column style={{ width: "300px" }} field="vendorShortName" header="Alvállalkozó" />
              <Column
                alignHeader="right"
                style={{ textAlign: "right", width: "150px" }}
                field="amount"
                header="Alvállalkozói számlán szereplő díj"
                body={vendorAmountTemplate}
              />
              {/* <Column
                alignHeader="right"
                style={{ textAlign: "right", width: "150px" }}
                field="verifiedAmount"
                header="Leigazolt alvállalkozói díj"
                body={vendorVerifiedAmountTemplate}
              /> */}
              <Column style={{ width: "300px" }} field="invoiceNo" header="Alvállalkozói számla sorszáma" />
              <Column field="dateOfCompletion" header="Alvállalkozó számlán szereplő TELJESÍTÉSI dátum" />
              <Column field="paymentDue" header="Alvállalkozó számlán szereplő FIZETÉSI határidő" />
            </DataTable>


            {/*             <div className="formgrid grid mt-5">
              <div className="col-12">
                <div className="flex ml-2 align-items-start p-3 bg-orange-100 border-round border-1 border-yellow-300">
                  <i className="pi pi-info-circle text-yellow-900 text-2xl mr-3"></i>
                  <div className="mr-1">
                    <p className="m-0 p-0 text-yellow-700 line-height-3">
                      EGYÉB KÖZREMŰKÖDŐK összesített számláinak (ügyvéd, futár stb.) HAVONKÉNTI leigazolásakor az ezt a Munkaszámot terhelő
                      tételeket <br />ALÁBB VEZESD BE MANUÁLISAN, közreműködőnként egy összegben.
                      Ha több hónapon át jelentkezik költség ugyanattól a közreműködőtől, akkor a már létrehozott tételsorát
                      nyisd meg, és módosítsd az összeget.
                    </p>
                  </div>
                </div>
              </div>
            </div>
 */}
            {/*             <div className="formgrid grid mt-5">

              <div className="col-12">
                <div className="formgrid grid">
                  <div className="col-4">
                    <label
                      htmlFor="selectedCreative"
                      className="block text-900 font-medium mb-2"
                    >
                      Egyéb közreműködő (futár, ügyvéd stb.)
                    </label>
                    <Dropdown
                      id="selectedContributor"
                      className="w-full mb-3"
                      {...formik.getFieldProps("selectedContributor")}
                      options={contributors}
                      onChange={formik.handleChange}
                      value={formik.values.selectedContributor}
                      placeholder="Válasszon!"
                    />
                  </div>
                  <div className="col-4">
                    <label
                      htmlFor="content"
                      className="block text-900 font-medium mb-2"
                    >
                      Tétel tartalma
                    </label>
                    <InputText
                      className="w-full mb-2"
                      id="contributorContent"
                      name="contributorContent"
                      value={contributorContent}
                      onChange={(e) => setContributorContent(e.target.value)}
                    />
                  </div>
                  <div className="col-3">
                    <label
                      htmlFor="setContributorAmount"
                      className="block text-900 font-medium mb-2"
                    >
                      Egyéb közreműködői díj
                    </label>
                    <InputNumber
                      value={contributorAmount}
                      onValueChange={(e: any) => setContributorAmount(e.value)}
                      mode="currency"
                      maxFractionDigits={0}
                      currency="HUF"
                      locale="hu-HU"
                    />
                  </div>

                  <div className="col-1">
                    <Button
                      type="button"
                      className="w-full p-button-outlined"
                      style={{ marginTop: "27px" }}
                      icon="pi pi-plus"
                      onClick={() => {
                        if (
                          formik.values.selectedContributor === "" ||
                          contributorAmount === undefined ||
                          contributorAmount === null
                        ) {
                          return;
                        }

                        const selectedContributor = contributors.filter((obj: any) => {
                          return obj.value === formik.values.selectedContributor;
                        });
                        console.log(selectedContributor);
                        console.log(projectContributorAssignmentList);
                        console.log(formik.values.selectedContributor);
                        const alreadyAddedContributor = projectContributorAssignmentList.filter((obj: any) => {
                          return obj.contributorId === Number(formik.values.selectedContributor);
                        });

                        console.log(alreadyAddedContributor);

                        if (alreadyAddedContributor.length !== 0) {
                          return;
                        }

                        let oldList = [...projectContributorAssignmentList];

                        let emptyPojectContributorAssignment: ProjectContributorAssignment =
                        {
                          id: 0,
                          projectId: projectDto.id,
                          contributorId: Number(formik.values.selectedContributor),
                          amount: contributorAmount,
                          contributorShortName: selectedContributor[0].label,
                          rowId: uuidv4(),
                          content: contributorContent
                        };

                        setContributorAmount(undefined);
                        setContributorContent("");
                        oldList.push(emptyPojectContributorAssignment);
                        setProjectContributorAssignmentList(oldList);

                        let plannedVendorCost: number = 0;
                        for (var projectContributorAssignment of oldList) {
                          plannedVendorCost += projectContributorAssignment.amount;
                        }
                        for (var projectVendorAssignment of projectVendorAssignmentList) {
                          plannedVendorCost += projectVendorAssignment.amount;
                        }
                        formik.setFieldValue(
                          "plannedVendorCost",
                          plannedVendorCost
                        );
                        formik.setFieldValue(
                          "plannedProfit",
                          formik.values.plannedTurnOver - plannedVendorCost
                        );
                        formik.setFieldValue("selectedContributor", "");
                      }}
                    />
                  </div>
                </div>
                <div className="formgrid grid">
                  <div className="field col-12">
                    <DataTable
                      className="admin-list"
                      size="small"
                      value={projectContributorAssignmentList}
                      responsiveLayout="scroll"
                      rowGroupMode="subheader"
                      rowGroupFooterTemplate={projectContributorFooterTemplate}
                    >
                      <Column
                        header=""
                        body={contributorActionTemplate}
                        style={{ width: "150px" }}
                      />
                      <Column field="contributorShortName" header="Név" />
                      <Column field="content" header="Tétel tartalma" />
                      <Column
                        alignHeader="right"
                        style={{ textAlign: "right", width: "200px" }}
                        field="amount"
                        header="Egyéb közreműködői díj"
                        body={vendorAmountTemplate}
                      />
                    </DataTable>
                  </div>
                </div>
              </div>

            </div> */}

            {/*             <div className="formgrid grid mt-4">
              <div className="field col-4">
                <label
                  htmlFor="selectedCreative"
                  className="block text-900 font-medium mb-2"
                >
                  MINDÖSSZESEN
                </label>
                <InputNumber
                  value={vendorAmount}
                  readOnly
                  onValueChange={(e: any) => setVendorAmount(e.value)}
                  mode="currency"
                  maxFractionDigits={0}
                  currency="HUF"
                  locale="hu-HU"
                />
                <label
                  htmlFor="selectedCreative"
                  className="block text-900 font-medium mb-2 text-sm"
                >
                  (Leigazolt alvállalkozói számlák és Egyéb közreműködők díjai mindösszesen.)
                </label>
              </div>




            </div> */}


            <Divider align="center">
              <span className="p-tag">Kiszámlázott vevői számlák</span>
            </Divider>

            <div className="formgrid grid">
              <div className="field col-12">


                <DataTable
                  className="admin-list"
                  size="small"
                  value={projectDto ? projectDto.customerInvoiceList : null}
                  rowGroupMode="subheader"
                  rowGroupFooterTemplate={customerInvoiceFooterTemplate}
                >
                  <Column field="rowIndex" header="" frozen style={{ width: '50px' }} body={onRowIndexTemplate} />

                  <Column style={{ textAlign: "right", width: "450px" }}
                    align="right"
                    field="amount"
                    header="Vevői számlán szereplő díj"
                    body={customerInvoiceAmountTemplate}
                  />
                  {/*<Column field="id" header="ID" />*/}
                  <Column style={{ width: "300px" }} field="invoiceNo" header="Vevői számla sorszáma" />
                  <Column field="dateOfCompletion" header="Vevői számlán szereplő TELJESÍTÉSI dátum" />
                  <Column field="paymentDue" header=" Vevői számlán szereplő FIZETÉSI határidő" />


                </DataTable>
              </div>
            </div>
          </form>
        </BlockUI>
      </Dialog>
    </>
  );
};

export default ProjectDetail;
