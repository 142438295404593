import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "primeflex/primeflex.css";
import { Button } from "primereact/button";
import SkyUserDetail from "./SkyUserDetail";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { RootState } from "../../state/store";
import { readAllSkyUsers } from "../../service/SkyUserService";
import { setSkyUserList } from "../../state/skyUserSlice";
import { StatusCodes } from "http-status-codes";
import { logout, setAuthToken } from "../../service/AuthService";
import { useNavigate } from "react-router";
import { setActiveMenu, setActiveView } from "../../state/navigationSlice";
import "../../css/Admin.css";
import { Panel } from "primereact/panel";
import { onRowIndexTemplate } from "../../service/IndexColumn";

const SkyUserList: React.FC = () => {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    setAuthToken();
    dispatch(setActiveView("Felhasználók"));
    dispatch(setActiveMenu("userlist"));

    const [skyUserId, setSkyUserId] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);


    const [
        skyUserDetailDialogShow,
        setSkyUserDetailDialogShow,
    ] = useState<boolean>(false);

    const [
        skyUserDetailDialogChange,
        setSkyUserDetailDialogChange,
    ] = useState<number>(0);

    const skyUserList: [] = useSelector(
        (state: RootState) => state.skyUser.skyUserList
    );

    useEffect(() => {
        console.log("SkyUserList's useEffect");
        setLoading(true);
        readAllSkyUsers().then(
            (response) => {
                dispatch(
                    setSkyUserList(
                        response.data.skyUserList
                    )
                );
                setLoading(false);
            },
            (error) => {
                setLoading(false);
                if (error.response.status === StatusCodes.UNAUTHORIZED) {
                    logout();
                    navigate("/login?status=unauthorized");
                }
            }
        );
    }, [navigate, dispatch]);

    const editSkyUserTemplate = (rowData: any) => {
        return (
            <>
                <Button
                    className="p-button-rounded p-button-text"
                    style={{height: '30px', width: '30px'}}
                    type="button"
                    onClick={() => {

                        setSkyUserId(rowData.id);
                        setSkyUserDetailDialogShow(true);
                        setSkyUserDetailDialogChange(Date.now());
                    }}
                    icon="pi pi-pencil"
                    aria-label="Edit"
                />
            </>
        );
    };


    return (
        <>
            <div className="border-2 surface-border border-round surface-section flex-auto p-3 md:p-4 lg:p-5">
                <div className="flex justify-content-end flex-wrap card-container green-container">
                    <div className="flex align-items-center justify-content-center">
                        <Button className="p-panel p-button p-button-sm p-button-info mb-3 p-button-text" icon="pi pi-plus" label="Új felhasználó"
                            onClick={() => {
                                setSkyUserId("");
                                setSkyUserDetailDialogShow(true);
                                setSkyUserDetailDialogChange(Date.now());
                            }}
                        />
                        <SkyUserDetail
                            id={skyUserId}
                            show={skyUserDetailDialogShow}
                            change={skyUserDetailDialogChange}
                        />
                    </div>
                </div>

                <DataTable
                    //className="admin-list"
                    value={skyUserList}
                    size="small"
                    responsiveLayout="scroll"
                    scrollHeight="70vh"
                    //scrollDirection="both"
                    scrollable
                    stripedRows
                    loading={loading}

                >
                    <Column field="rowIndex" header="" style={{ maxWidth: '50px' }} body={onRowIndexTemplate} />
                    <Column bodyClassName="p-0" header="Művelet" style={{ maxWidth: '100px' }} body={editSkyUserTemplate} />
                    <Column field="name" header="Név" sortable filter />
                    <Column field="shortName" header="Rövid név" sortable filter />
                    <Column field="role" header="Szerepkör" sortable filter />
                </DataTable>

            </div>
        </>
    );
};

export default SkyUserList;

