import React, { useEffect, useState, Fragment } from "react";
import { useDispatch } from "react-redux";
import "primeflex/primeflex.css";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useNavigate } from "react-router";
import { StatusCodes } from "http-status-codes";
import * as Yup from "yup";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { confirmDialog } from "primereact/confirmdialog";
import { Calendar } from "primereact/calendar";
import { readFirstWorkingDayBySkyUser } from "../../service/SkyUserService";




const MyWorkingTimeReportDialog: React.FC<any> = (props: any) => {

    let navigate = useNavigate();

    const [
        firstWorkingDay,
        setFirstWorkingDay,
    ] = useState<Date>();


    const [
        dialogShow,
        setDialogShow,
    ] = useState<boolean>(false);

    const [
        dialogChange,
        setDialogChange,
    ] = useState<number>(0);

    const hideDialog = () => {
        setDialogShow(false);
        formik.resetForm();
    };

    const onClose = props.onClose;

    const onCloseModal = (values: any) => {
        onClose(values);
    }

    const confirmSave = (values: any) => {
        confirmDialog({
            message: 'Valóban letölti a munkaóra jelentést?',
            header: 'Megerősítés',
            icon: 'pi pi-exclamation-triangle',
            acceptClassName: 'p-button-text',
            rejectClassName: 'p-button-text',
            accept: () => {
                //values = {taskId: taskId, comment: values.coment};
                hideDialog();
                //alert(JSON.stringify(values, null, 2));
                onCloseModal(values);
            },
            reject: () => {
                hideDialog();
            }
        });
    }

    useEffect(() => {
        if (
            props.show === true
        ) {
            setDialogChange(props.change);
            setDialogShow(props.show);

            readFirstWorkingDayBySkyUser().then(
                (response) => {

                    setFirstWorkingDay(new Date(response.data.firstWorkingDay));
                },
                (error) => {
                    console.log(error.response.status);
                }
            );
        }
    }, [dialogChange, navigate, props.show, props.change]);

    const formik = useFormik({
        initialValues: {
            fromDate: null,
            toDate: null,
        },
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: Yup.object({
            fromDate: Yup.date()
                .required("Kötelező kitölteni!")
                .typeError("Kötelező kitölteni!"),
            toDate: Yup.date()
                .required("Kötelező kitölteni!")
                .typeError("Kötelező kitölteni!")
                .min(
                    Yup.ref('fromDate'),
                    "A záró dátum később kell, hogy legyen, mint a kezdeti dátum!"
                )
        }),
        onSubmit: (values) => {

            if (dialogShow) {

                confirmSave(values);
            }
        },
    });


    return (
        <>
            <Dialog header="Munkaórák letöltése" visible={dialogShow} closable={false}
                maximizable modal style={{ width: "70vw" }} onHide={() => hideDialog()}>
                <form onSubmit={formik.handleSubmit} className="p-fluid">
                    <div className="formgrid grid">
                        <div className="field col-12 md:col-6">
                            <label
                                htmlFor="fromDate"
                                className="block text-900 font-medium mb-2"
                            >
                                Kezdeti dátum
                            </label>
                            <Calendar
                                className={
                                    formik.errors.fromDate
                                        ? "w-full p-invalid"
                                        : "w-full"
                                }
                                minDate={firstWorkingDay}
                                maxDate={new Date()}
                                showIcon
                                dateFormat="yy.mm.dd"
                                id="fromDate"  {...formik.getFieldProps("fromDate")} />
                            {formik.errors.fromDate ? (
                                <small className="p-error">
                                    {formik.errors.fromDate}
                                </small>
                            ) : null}
                        </div>

                        <div className="field col-12 md:col-6">
                            <label
                                htmlFor="toDate"
                                className="block text-900 font-medium mb-2"
                            >
                                Záró dátum
                            </label>
                            <Calendar
                                className={
                                    formik.errors.toDate
                                        ? "w-full p-invalid"
                                        : "w-full"
                                }
                                minDate={firstWorkingDay}
                                maxDate={new Date()}
                                showIcon
                                dateFormat="yy.mm.dd"
                                id="toDate"  {...formik.getFieldProps("toDate")} />
                            {formik.errors.toDate ? (
                                <small className="p-error">
                                    {formik.errors.toDate}
                                </small>
                            ) : null}
                        </div>


                    </div>

                    <div className="formgrid grid">
                        <div className="field">
                            <Button
                                className="p-button-text"
                                type="button"
                                label="Bezárás"
                                icon="pi pi-times"
                                onClick={() => {
                                    hideDialog();
                                }}
                            />
                        </div>

                        <div className="field">
                            <Button
                                className="p-button-text"
                                type="submit"
                                label="Létrehozás"
                                icon="pi pi-check"
                            />
                        </div>
                    </div>
                </form>
            </Dialog>
        </>
    );
};

export default MyWorkingTimeReportDialog;





