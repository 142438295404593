import axios from 'axios';
import { server } from '../config/endpoints';

const API_URL = server;


export const readProjectTypesByLevel = (level: string) => {
    return axios
        .get(API_URL + '/protected/projecttypebylevel/' + level)
        .then((response) => {
            return response;
        });
};


export const readProjectTypesByParent = (parent: string) => {
    return axios
        .get(API_URL + '/protected/projecttypebyparent/' + parent)
        .then((response) => {
            return response;
        });
};