import { createSlice, PayloadAction } from "@reduxjs/toolkit";


interface CustomerType {
    customerList: [];
}


const initialState: CustomerType = {
    customerList: []
};

export const customerSlice = createSlice({
    name: "CustomerAction",
    initialState: initialState,
    reducers: {
        setCustomerList: (state, action: PayloadAction<any>) => {
            state.customerList = action.payload;
        },
        cleanCustomerList: (state) => {
            state.customerList = [];
        },
    },
});

export const { setCustomerList } = customerSlice.actions;
export const { cleanCustomerList } = customerSlice.actions;

export default customerSlice.reducer;
