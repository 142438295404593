import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "primeflex/primeflex.css";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { RootState } from "../../state/store";
import { readAllCustomers } from "../../service/CustomerService";
import { StatusCodes } from "http-status-codes";
import { logout, setAuthToken } from "../../service/AuthService";
import { useNavigate } from "react-router";
import { setActiveMenu, setActiveView } from "../../state/navigationSlice";
import "../../css/Admin.css";

import { setCustomerList } from "../../state/customerSlice";
import CustomerDetail from "./CustomerDetail";
import { onRowIndexTemplate } from "../../service/IndexColumn";

const CustomerList: React.FC = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  setAuthToken();
  dispatch(setActiveView("Ügyfelek"));
  dispatch(setActiveMenu("customerlist"));

  const [customerId, setCustomerId] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);


  const [customerDetailDialogShow, setCustomerDetailDialogShow] =
    useState<boolean>(false);

  const [customerDetailDialogChange, setCustomerDetailDialogChange] =
    useState<number>(0);

  const customerList: [] = useSelector(
    (state: RootState) => state.customer.customerList
  );

  useEffect(() => {
    console.log("CustomerList's useEffect");
    setLoading(true);
    readAllCustomers().then(
      (response) => {
        dispatch(setCustomerList(response.data.customerList));
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        if (error.response.status === StatusCodes.UNAUTHORIZED) {
          logout();
          navigate("/login?status=unauthorized");
        }
      }
    );
  }, [navigate, dispatch]);

  const editCustomerTemplate = (rowData: any) => {
    return (
      <>
        <Button
          className="p-button-rounded p-button-text"
          style={{height: '30px', width: '30px'}}
          type="button"
          onClick={() => {
            setCustomerId(rowData.id);
            setCustomerDetailDialogShow(true);
            setCustomerDetailDialogChange(Date.now());
          }}
          icon="pi pi-pencil"
          aria-label="Edit"
        />
      </>
    );
  };

  return (
    <>
      <div className="border-2 surface-border border-round surface-section flex-auto p-3 md:p-4 lg:p-5">
        <div className="flex justify-content-end flex-wrap card-container green-container">
          <div className="flex align-items-center justify-content-center">
            <Button
              className="p-panel p-button p-button-sm p-button-info mb-3 p-button-text"
              icon="pi pi-plus"
              label="Új ügyfél"
              onClick={() => {
                setCustomerId("");
                setCustomerDetailDialogShow(true);
                setCustomerDetailDialogChange(Date.now());
              }}
            />
            <CustomerDetail
              id={customerId}
              show={customerDetailDialogShow}
              change={customerDetailDialogChange}
            />
          </div>
        </div>
        <DataTable
          //className="admin-list"
          value={customerList}
          size="small"
          //paginator
          scrollable
          scrollHeight="70vh"
          //scrollDirection="both"
          responsiveLayout="scroll"
          stripedRows
          loading={loading}
          // paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          // currentPageReportTemplate="Lista: {first}-{last} | Összesen: {totalRecords}"
          // rows={10}
          // rowsPerPageOptions={[10, 20, 50, 100]}
        >
          <Column field="rowIndex" header="" style={{ maxWidth: '50px' }} body={onRowIndexTemplate} />
          <Column bodyClassName="p-0" header="Művelet" style={{maxWidth: '100px'}} body={editCustomerTemplate} />
          <Column field="shortName" header="Rövid név" sortable filter />
          <Column field="name" header="Név" sortable filter />
        </DataTable>
      </div>
    </>
  );
};

export default CustomerList;
