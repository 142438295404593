import { createSlice, PayloadAction } from "@reduxjs/toolkit";


interface businessOpportunity {
    projectList: [];
}


const initialState: businessOpportunity = {
    projectList: []
};

export const projectSlice = createSlice({
    name: "ProjectAction",
    initialState: initialState,
    reducers: {
        setProjectList: (state, action: PayloadAction<any>) => {
            state.projectList = action.payload;
        },
        cleanProjectList: (state) => {
            state.projectList = [];
        },
    },
});

export const { setProjectList } = projectSlice.actions;
export const { cleanProjectList } = projectSlice.actions;

export default projectSlice.reducer;
