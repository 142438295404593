import axios from 'axios';
import { server } from '../config/endpoints';

const API_URL = server;

/* export const readToday = () => {
    return axios
        .get(API_URL + '/protected/workingtime')
        .then((response) => {
            return response;
        });
}; */

export const readToday = (byHoursOrder: boolean) => {
    return axios
        .get(API_URL + '/protected/workingtime/' + byHoursOrder)
        .then((response) => {
            return response;
        });
};


export const updateWorkingTimeCell = (formData: any) => {
    return axios
        .post(API_URL + '/protected/workingtime/cell', formData)
        .then((response) => {
            return response;
        });
};

/* export const readBackward = (actualDate: string) => {
    return axios
        .get(API_URL + '/protected/workingtime/backward/' + actualDate)
        .then((response) => {
            return response;
        });
}; */

export const readBackward = (actualDate: string, byHoursOrder: boolean) => {
    return axios
        .get(API_URL + '/protected/workingtime/backward/' + actualDate + '/' + byHoursOrder)
        .then((response) => {
            return response;
        });
};

/* export const readForward = (actualDate: string) => {
    return axios
        .get(API_URL + '/protected/workingtime/forward/' + actualDate)
        .then((response) => {
            return response;
        });
}; */

export const readForward = (actualDate: string, byHoursOrder: boolean) => {
    return axios
        .get(API_URL + '/protected/workingtime/forward/' + actualDate + '/' + byHoursOrder)
        .then((response) => {
            return response;
        });
};

/* export const refreshWorkingTime = (actualDate: string) => {
    return axios
        .get(API_URL + '/protected/workingtime/refresh/' + actualDate)
        .then((response) => {
            return response;
        });
}; */

export const refreshWorkingTime = (actualDate: string, byHoursOrder: boolean) => {
    return axios
        .get(API_URL + '/protected/workingtime/refresh/' + actualDate + '/' + byHoursOrder)
        .then((response) => {
            return response;
        });
};

/* export const closeDay = (actualDate: string) => {
    return axios
        .get(API_URL + '/protected/workingtime/closeday/' + actualDate)
        .then((response) => {
            return response;
        });
}; */

export const closeDay = (actualDate: string, byHoursOrder: boolean) => {
    return axios
        .get(API_URL + '/protected/workingtime/closeday/' + actualDate + '/' + byHoursOrder)
        .then((response) => {
            return response;
        });
};