import React, { useEffect, useState, Fragment } from "react";
import { useDispatch } from "react-redux";
import "primeflex/primeflex.css";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useNavigate } from "react-router";
import { StatusCodes } from "http-status-codes";
import * as Yup from "yup";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { confirmDialog } from "primereact/confirmdialog";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { readAllCustomers, readAllCustomersByCaller, readBranchesOfCustomer, readCustomer } from "../../service/CustomerService";
import { MultiSelect } from 'primereact/multiselect';
import { readAllAccountSkyUsers, readSkyUsersOfBranches } from "../../service/SkyUserService";
import { Divider } from "primereact/divider";
import { readAllProjects } from "../../service/ProjectService";




const WorkingTimeReportDialog: React.FC<any> = (props: any) => {

    let navigate = useNavigate();

    const [
        dialogShow,
        setDialogShow,
    ] = useState<boolean>(false);

    const [
        dialogChange,
        setDialogChange,
    ] = useState<number>(0);

    const hideDialog = () => {
        setDialogShow(false);
        formik.resetForm();
    };

    const onClose = props.onClose;
    const onReject = props.onReject;

    const onCloseModal = (values: any) => {
        onClose(values);
    }

    const onRejectModal = () => {
        onReject();
    }

    const [customers, setCustomers] = useState<any>([]);
    const [branches, setBranches] = useState<any>([]);
    const [allSkyUsers, setAllSkyUsers] = useState<any>([]);

    const reportTypeItems = [
        { label: "Naptár nézet egyben", value: "1" },
        // { label: "Naptár nézet emberenként", value: "2" },
        // { label: "E.ON Havidíj kimutatás", value: "3" },
        // { label: "E.ON Fix/Óradíjas kimutatás", value: "3" },
    ];



    const confirmSave = (values: any) => {
        confirmDialog({
            message: 'Valóban letölti a munkaóra jelentést?',
            header: 'Megerősítés',
            icon: 'pi pi-exclamation-triangle',
            acceptClassName: 'p-button-text',
            rejectClassName: 'p-button-text',
            accept: () => {
                //alert(JSON.stringify(values, null, 2));
                hideDialog();

                onCloseModal(values);
            },
            reject: () => {
                console.log('reject is called');
                hideDialog();
                onRejectModal();
            }
        });
    }

    useEffect(() => {
        if (
            props.show === true
        ) {
            setDialogChange(props.change);
            setDialogShow(props.show);
            setCustomers([]);
            setBranches([]);
            setAllSkyUsers([]);
            readAllCustomersByCaller().then(
                (response) => {
                    // let customerList: { label: any; value: any }[] = [];
                    // response.data.customerList.forEach((item: any) => {
                    //     customerList.push({ label: item.shortName, value: item.id });
                    // });
                    setCustomers(response.data.labelValueList);
                },
                (error) => {
                    console.log(error.response.status);
                }
            );
        }
    }, [dialogChange, navigate, props.show, props.change]);

    const formik = useFormik({
        initialValues: {
            fromDate: null,
            toDate: null,
            customerId: "",
            branchIds: null,
            skyUserIds: null,
            reportTypeId: "",
        },
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: Yup.object({
            customerId: Yup.string().required("Kötelező kitölteni!"),
            reportTypeId: Yup.string().required("Kötelező kitölteni!"),
            branchIds: Yup.array().required("Kötelező kitölteni!")
                .typeError("Kötelező kitölteni!"),
            skyUserIds: Yup.array().required("Kötelező kitölteni!")
                .typeError("Kötelező kitölteni!"),
            fromDate: Yup.date()
                .required("Kötelező kitölteni!")
                .typeError("Kötelező kitölteni!"),
            toDate: Yup.date()
                .required("Kötelező kitölteni!")
                .typeError("Kötelező kitölteni!")
                .min(
                    Yup.ref('fromDate'),
                    "A záró dátum később kell, hogy legyen, mint a kezdeti dátum!"
                )
        }),
        onSubmit: (values) => {

            if (dialogShow) {

                confirmSave(values);
            }
        },
    });

    const onCustomerChange = (e: { value: any }) => {
        formik.setFieldValue("customerId", e.value);
        formik.setFieldValue("branchIds", null);
        formik.setFieldValue("skyUserIds", null);
        setBranches([]);
        setAllSkyUsers([]);
        console.log(e.value);

        let branchList: { label: any; value: any }[] = [];
        readBranchesOfCustomer(e.value).then(
            (response) => {
                console.log(response.data);
                setBranches(response.data.labelValueList);

            },
            (error) => {
                console.log(error);

            }
        );
    };


    const onBranchChange = (e: { value: any }) => {
        formik.setFieldValue("branchIds", e.value);
        if (e.value.length === 0) {
            formik.setFieldValue("branchIds", null);
        }
        formik.setFieldValue("skyUserIds", null);
        setAllSkyUsers([]);
        console.log(e.value);

        readSkyUsersOfBranches({ branchIdList: e.value }).then(
            (response) => {
                console.log(response.data);
                setAllSkyUsers(response.data.skyUserList);

            },
            (error) => {
                console.log(error);

            }
        );


    };


    return (
        <>
            <Dialog header="Munkaóra jelentés létrehozása" visible={dialogShow} closable={false}
                maximizable modal style={{ width: "90vw" }} onHide={() => hideDialog()}>
                <form onSubmit={formik.handleSubmit} className="p-fluid">

                    <div className="formgrid grid">
                        <div className="field col-12 md:col-6">
                            <label
                                htmlFor="customerId"
                                className="block text-900 font-medium mb-2"
                            >
                                Ügyfél
                            </label>
                            <Dropdown
                                id="customerId"
                                className="w-full mb-3"
                                {...formik.getFieldProps("customerId")}
                                options={customers}
                                onChange={onCustomerChange}
                                value={formik.values.customerId}
                                placeholder="Válasszon!"
                            />
                            {formik.touched.customerId && formik.errors.customerId ? (
                                <small className="p-error">
                                    {formik.errors.customerId?.toString()}
                                </small>
                            ) : null}
                        </div>

                        <div className="field col-12 md:col-6">
                            <label
                                htmlFor="branchIds"
                                className="block text-900 font-medium mb-2"
                            >
                                Üzletág / Termék / Terület
                            </label>
                            <MultiSelect
                                id="branchIds"
                                className="w-full mb-3"
                                {...formik.getFieldProps("branchIds")}
                                options={branches}
                                onChange={onBranchChange}
                                value={formik.values.branchIds}
                                placeholder="Válasszon!"
                            />
                            {formik.touched.branchIds && formik.errors.branchIds ? (
                                <small className="p-error">
                                    {formik.errors.branchIds?.toString()}
                                </small>
                            ) : null}
                        </div>
                    </div>

                    <div className="formgrid grid">
                        <div className="field col-12 md:col-6">
                            <label
                                htmlFor="fromDate"
                                className="block text-900 font-medium mb-2"
                            >
                                Kezdeti dátum
                            </label>
                            <Calendar
                                className={
                                    formik.errors.fromDate
                                        ? "w-full p-invalid"
                                        : "w-full"
                                }
                                maxDate={new Date()}
                                showIcon
                                dateFormat="yy.mm.dd"
                                id="fromDate"  {...formik.getFieldProps("fromDate")} />
                            {formik.errors.fromDate ? (
                                <small className="p-error">
                                    {formik.errors.fromDate}
                                </small>
                            ) : null}
                        </div>

                        <div className="field col-12 md:col-6">
                            <label
                                htmlFor="toDate"
                                className="block text-900 font-medium mb-2"
                            >
                                Záró dátum
                            </label>
                            <Calendar
                                className={
                                    formik.errors.toDate
                                        ? "w-full p-invalid"
                                        : "w-full"
                                }
                                maxDate={new Date()}
                                showIcon
                                dateFormat="yy.mm.dd"
                                id="toDate"  {...formik.getFieldProps("toDate")} />
                            {formik.errors.toDate ? (
                                <small className="p-error">
                                    {formik.errors.toDate}
                                </small>
                            ) : null}
                        </div>

                    </div>

                    <div className="formgrid grid">
                        <div className="field col-12">
                            <div className="flex ml-0 align-items-start p-3 bg-yellow-100 border-round border-1 border-yellow-300">
                                <i className="pi pi-info-circle text-yellow-900 text-2xl mr-3"></i>
                                <div className="mr-1">
                                    <p className="m-0 p-0 text-yellow-700 line-height-3">
                                    Ne feledd, hogy egy adott munkán a kiválasztott időszakon kívül is lehetnek munkaórák!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>




                    <div className="formgrid grid">
                        <div className="field col-12 md:col-6">
                            <label
                                htmlFor="skyUserIds"
                                className="block text-900 font-medium mb-2"
                            >
                                Résztvevők
                            </label>
                            <MultiSelect
                                id="skyUserIds"
                                className="w-full mb-3"
                                {...formik.getFieldProps("skyUserIds")}
                                options={allSkyUsers}
                                onChange={formik.handleChange}
                                value={formik.values.skyUserIds}
                                placeholder="Válasszon!"
                            />
                            {formik.touched.skyUserIds && formik.errors.skyUserIds ? (
                                <small className="p-error">
                                    {formik.errors.skyUserIds?.toString()}
                                </small>
                            ) : null}
                        </div>

                        <div className="field col-12 md:col-6">
                            <label
                                htmlFor="skyUserIds"
                                className="block text-900 font-medium mb-2"
                            >
                                Jelentés típusa
                            </label>
                            <Dropdown
                                id="reportTypeId"
                                className="w-full mb-3"
                                {...formik.getFieldProps("reportTypeId")}
                                options={reportTypeItems}
                                onChange={formik.handleChange}
                                value={formik.values.reportTypeId}
                                placeholder="Válasszon!"
                            />
                            {formik.touched.reportTypeId && formik.errors.reportTypeId ? (
                                <small className="p-error">
                                    {formik.errors.reportTypeId?.toString()}
                                </small>
                            ) : null}
                        </div>


                    </div>




                    <div className="formgrid grid">
                        <div className="field">
                            <Button
                                className="p-button-text"
                                type="button"
                                label="Bezárás"
                                icon="pi pi-times"
                                onClick={() => {
                                    hideDialog();
                                    onRejectModal();
                                }}
                            />
                        </div>

                        <div className="field">
                            <Button
                                className="p-button-text"
                                type="submit"
                                label="Létrehozás"
                                icon="pi pi-check"
                            />
                        </div>
                    </div>
                </form>
            </Dialog>
        </>
    );
};

export default WorkingTimeReportDialog;





