import { configureStore } from '@reduxjs/toolkit';
import searchSalesContractReducer from './salesContractSearchSlice';
import navigationReducer from './navigationSlice';
import skyUserReducer from './skyUserSlice';
import projectReducer from './projectSlice';
import customerReducer from './customerSlice';
import vendorReducer from './vendorSlice';
import contributorReducer from './contributorSlice';
import vendorInvoiceReducer from './vendorInvoiceSlice';
import customerInvoiceReducer from './customerInvoiceSlice';
import branchReducer from './branchSlice';
import trafficTableReducer from './trafficTableSlice';
import woringHoursReducer from './workingHoursSlice';

export const store = configureStore({
    reducer: {
        salesContractSearch: searchSalesContractReducer,
        navigation: navigationReducer,
        skyUser: skyUserReducer,
        project: projectReducer,
        customer: customerReducer,
        vendor: vendorReducer,
        contributor: contributorReducer,
        vendorInvoice: vendorInvoiceReducer,
        customerInvoice: customerInvoiceReducer,
        branch: branchReducer,
        trafficTable: trafficTableReducer,
        workingHours: woringHoursReducer
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
