import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import "primeflex/primeflex.css";
import {Button} from "primereact/button";
import SkyUserDetail from "./SkyUserDetail";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {RootState} from "../../state/store";
import {readAllProjects, readAllProjectsByFilters} from "../../service/ProjectService";
import {setProjectList} from "../../state/projectSlice";
import {StatusCodes} from "http-status-codes";
import {getCurrentUser, logout, setAuthToken} from "../../service/AuthService";
import {useNavigate} from "react-router";
import {setActiveMenu, setActiveView} from "../../state/navigationSlice";
import ProjectDetail from "./ProjectDetail";
import "../../css/Admin.css";
import {onRowIndexTemplate} from "../../service/IndexColumn";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {Toast} from "primereact/toast";
import {Checkbox} from "primereact/checkbox";
import {SelectButton} from "primereact/selectbutton";

const ProjectList: React.FC = () => {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    setAuthToken();
    dispatch(setActiveView("Munkák"));
    dispatch(setActiveMenu("projectlist"));
    const toast: any = useRef(null);

    const [projectId, setProjectId] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [role, setRole] = useState<String>("");

    const [projectDetailDialogShow, setProjectDetailDialogShow] =
        useState<boolean>(false);

    const [projectDetailDialogChange, setProjectDetailDialogChange] =
        useState<number>(0);

/*     const projectList: [] = useSelector(
        (state: RootState) => state.project.projectList
    ); */
    const [projectList, setProjectList] = useState<any>([]);

    const [company, setCompany] = useState('Összes');
    const [closedFilter, setClosedFilter] = useState('Összes');


    const refresh = (company: string, closingFilter: string) => {
        console.log("ProjectList's useEffect");
        setRole(getCurrentUser().role);
        setLoading(true);
        readAllProjectsByFilters(company, closingFilter).then(
            (response) => {
                //dispatch(setProjectList(response.data.projectList));
                setProjectList(response.data.projectList)
                setLoading(false);
            },
            (error) => {
                setLoading(false);
                if (error.response.status === StatusCodes.UNAUTHORIZED) {
                    logout();
                    navigate("/login?status=unauthorized");
                }
            }
        );
    }

    const onCloseProjectDetail = () => {
        console.log("ProjectList's useEffect");
        setRole(getCurrentUser().role);
        setLoading(true);
        readAllProjectsByFilters(company, closedFilter).then(
            (response) => {
                //dispatch(setProjectList(response.data.projectList));
                setProjectList(response.data.projectList)
                setLoading(false);
            },
            (error) => {
                setLoading(false);
                if (error.response.status === StatusCodes.UNAUTHORIZED) {
                    logout();
                    navigate("/login?status=unauthorized");
                }
            }
        );
    }


    useEffect(() => {
        refresh(company, closedFilter);
    }, [navigate, dispatch]);

    const editProjectTemplate = (rowData: any) => {
        return (
            <>
            {role !==  'FINANCE' ? (
                <Button
                    className="p-button-rounded p-button-text"
                    style={{height: '30px', width: '30px'}}
                    type="button"
                    onClick={() => {
                        setProjectId(rowData.id);
                        setProjectDetailDialogShow(true);
                        setProjectDetailDialogChange(Date.now());
                    }}
                    icon="pi pi-pencil"
                    aria-label="Edit"
                />) : null }
                <CopyToClipboard text={rowData.shortIdentifier}>
                    <Button
                        style={{height: '30px', width: '30px'}}
                        className="p-button-rounded p-button-text "
                        //type="button"
                        onClick={() => {
                            toast.current.show({
                                severity: 'info',
                                summary: 'Vágólapra másolva',
                                detail: rowData.shortIdentifier,
                                life: 2000
                            });
                        }}
                        icon="pi pi-copy"
                        aria-label="Copy"
                    />
                </CopyToClipboard>
            </>
        );
    };

    const closedTemplate = (rowData: any) => {
        return <Checkbox inputId="binary" checked={rowData.closed}/>;
    };

    const options = ['Összes', 'SKY', 'BPC'];
    const options2 = ['Összes', 'Nyitott', 'Lezárt'];

    return (
        <>

            <div className="border-2 surface-border border-round surface-section flex-auto p-3 md:p-4 lg:p-5">
                <Toast ref={toast}/>

                <div className="grid">
                    <div className="col-3">
                        <SelectButton unselectable={false} style={{width: '300px'}}
                                      className='p-panel p-button p-button-sm  mb-3 p-button-text flex align-items-center'
                                      value={company} options={options} onChange={(e: any) => {
                            setCompany(e.value);
                            refresh(e.value, closedFilter);
                        }}/>
                    </div>
                    <div className="col-3">
                        <SelectButton unselectable={false} style={{width: '320px'}}
                                      className='p-panel p-button p-button-sm  mb-3 p-button-text flex align-items-center'
                                      value={closedFilter} options={options2} onChange={(e: any) => {
                            setClosedFilter(e.value);
                            refresh(company, e.value);
                        }}/>
                    </div>
                    <div className="col-6">
                        <div className="flex justify-content-end flex-wrap card-container green-container">
                            <div className="flex align-items-center justify-content-center">
                                <Button
                                    className="p-panel p-button p-button-sm p-button-info mb-3 p-button-text"
                                    icon="pi pi-plus"
                                    label="Új munka"
                                    onClick={() => {
                                        setProjectId("");
                                        setProjectDetailDialogShow(true);
                                        setProjectDetailDialogChange(Date.now());
                                    }}
                                />
                                <ProjectDetail
                                    id={projectId}
                                    show={projectDetailDialogShow}
                                    change={projectDetailDialogChange}
                                    refresh={onCloseProjectDetail}
                                />
                            </div>
                        </div>
                    </div>

                </div>
                <DataTable
                    //className="admin-list"
                    stripedRows
                    value={projectList}
                    size="small"
                    scrollHeight="70vh"
                    //scrollDirection="both"
                    responsiveLayout="scroll"
                    scrollable
                    loading={loading}
                >
                    <Column
                        field="rowIndex"
                        header=""
                        style={{maxWidth: "50px"}}
                        body={onRowIndexTemplate}
                    />
                    <Column
                        bodyClassName="p-0"
                        header="Művelet"
                        align="center"
                        style={{maxWidth: "80px"}}
                        body={editProjectTemplate}
                    />
                    <Column
                        field="shortIdentifier"
                        header="Rövid munkaszám"
                        sortable
                        filter
                        style={{maxWidth: "290px"}}
                    />
                    <Column field="transformedName" header="Munkanév" sortable filter/>
                    <Column
                        bodyClassName="p-0"
                        align="center"
                        header="Munka lezárva"
                        style={{maxWidth: "150px"}}
                        body={closedTemplate}
                    />
                </DataTable>
            </div>
        </>
    );
};

export default ProjectList;
