import { createSlice, PayloadAction } from "@reduxjs/toolkit";


interface BranchType {
    branchList: [];
}


const initialState: BranchType = {
    branchList: []
};

export const branchSlice = createSlice({
    name: "BranchAction",
    initialState: initialState,
    reducers: {
        setBranchList: (state, action: PayloadAction<any>) => {
            state.branchList = action.payload;
        },
        cleanBranchList: (state) => {
            state.branchList = [];
        },
    },
});

export const { setBranchList } = branchSlice.actions;
export const { cleanBranchList } = branchSlice.actions;

export default branchSlice.reducer;
