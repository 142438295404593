import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "primeflex/primeflex.css";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { RootState } from "../../state/store";
import { readAllCustomers } from "../../service/CustomerService";
import { StatusCodes } from "http-status-codes";
import { authHeader, logout, setAuthToken } from "../../service/AuthService";
import { useNavigate } from "react-router";
import { setActiveMenu, setActiveView } from "../../state/navigationSlice";
import "../../css/Admin.css";

import { setCustomerList } from "../../state/customerSlice";
import CustomerDetail from "./CustomerDetail";
import {readAllVendors} from "../../service/VendorService";
import {setVendorList} from "../../state/vendorSlice";
import VendorDetail from "./VendorDetail";
import { FileUpload, FileUploadUploadParams } from "primereact/fileupload";
import { server } from "../../config/endpoints";
import { Toast } from "primereact/toast";
import ContributorDetail from "./ContributorDetail";
import {readAllContributors} from "../../service/ContributorService";
import {setContributorList} from "../../state/contributorSlice";
import { onRowIndexTemplate } from "../../service/IndexColumn";

const ContributorList: React.FC = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  setAuthToken();
  dispatch(setActiveView("Egyéb közreműködők"));
  dispatch(setActiveMenu("contributorlist"));
  const API_URL = server;

  const [contributorId, setContributorId] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const toast: any = useRef(null);


  const [contributorDetailDialogShow, setContributorDetailDialogShow] =
    useState<boolean>(false);

  const [contributorDetailDialogChange, setContributorDetailDialogChange] =
    useState<number>(0);

  const contributorList: [] = useSelector(
    (state: RootState) => state.contributor.contributorList
  );


  const refresh = () => {
    console.log("CustomerList's useEffect");
    setLoading(true);
    readAllContributors().then(
      (response) => {
        dispatch(setContributorList(response.data.contributorList));
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        if (error.response.status === StatusCodes.UNAUTHORIZED) {
          logout();
          navigate("/login?status=unauthorized");
        }
      }
    );
  };



  useEffect(() => {
    refresh();
  }, [navigate, dispatch]);

  const editContributorTemplate = (rowData: any) => {
    return (
      <>
        <Button
          className="p-button-rounded p-button-text"
          type="button"
          onClick={() => {
            setContributorId(rowData.id);
            setContributorDetailDialogShow(true);
            setContributorDetailDialogChange(Date.now());
          }}
          icon="pi pi-pencil"
          aria-label="Edit"
        />
      </>
    );
  };


  return (
    <>
      <div className="border-2 surface-border border-round surface-section flex-auto p-3 md:p-4 lg:p-5">
      <Toast ref={toast} />
        <div className="flex justify-content-end flex-wrap card-container green-container">
          <div className="flex align-items-center justify-content-center">
            <Button
              className="p-panel p-button p-button-sm p-button-info mb-3 p-button-text"
              icon="pi pi-plus"
              label="Új egyéb közreműködő"
              onClick={() => {
                setContributorId("");
                setContributorDetailDialogShow(true);
                setContributorDetailDialogChange(Date.now());
              }}
            />
            <ContributorDetail
              id={contributorId}
              show={contributorDetailDialogShow}
              change={contributorDetailDialogChange}
            />
          </div>
        </div>
        <DataTable
          //className="z-1"
          value={contributorList}
          size="small"
          scrollable
          scrollHeight="70vh"
          //scrollDirection="both"
          responsiveLayout="scroll"
          stripedRows
          loading={loading}
        >
          <Column field="rowIndex" header="" style={{ maxWidth: '50px' }} body={onRowIndexTemplate} />
          <Column bodyClassName="p-0" header="Művelet" style={{maxWidth: '100px'}} body={editContributorTemplate} />
          <Column field="shortName" header="Rövid név" sortable filter />
          <Column field="name" header="Név" sortable filter />
        </DataTable>
      </div>
    </>
  );
};

export default ContributorList;
