import { createSlice, PayloadAction } from "@reduxjs/toolkit";


interface VendorInvoiceType {
    vendorInvoiceList: [];
}


const initialState: VendorInvoiceType = {
    vendorInvoiceList: []
};

export const vendorInvoiceSlice = createSlice({
    name: "VendorInvoiceAction",
    initialState: initialState,
    reducers: {
        setVendorInvoiceList: (state, action: PayloadAction<any>) => {
            state.vendorInvoiceList = action.payload;
        },
        cleanVendorInvoiceList: (state) => {
            state.vendorInvoiceList = [];
        },
    },
});

export const { setVendorInvoiceList } = vendorInvoiceSlice.actions;
export const { cleanVendorInvoiceList } = vendorInvoiceSlice.actions;

export default vendorInvoiceSlice.reducer;
