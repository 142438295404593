import { createSlice, PayloadAction } from "@reduxjs/toolkit";


interface WorkingHoursType {
    trafficTableLineList: any[];
}


const initialState: WorkingHoursType = {
    trafficTableLineList: []
};

export const workingHoursSlice = createSlice({
    name: "WorkingHoursAction",
    initialState: initialState,
    reducers: {
        setTrafficTableLineList: (state, action: PayloadAction<any>) => {
            state.trafficTableLineList = action.payload;
        },
    },
});

export const { setTrafficTableLineList } = workingHoursSlice.actions;

export default workingHoursSlice.reducer;
