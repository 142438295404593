import { Button } from "primereact/button";
import { Ripple } from "primereact/ripple";
import React, { useEffect, useRef, useState } from "react";
import zepterCl100LogoLightBlue from "../../asset/zepter_cl100_logo_light_blue.png";
import skyGroupLogo from "../../asset/sky-group-logo.png";
import { InputTextarea } from "primereact/inputtextarea";
import { readTrafficTableNotes, updateTrafficTableNotes } from "../../service/TrafficTableService";
import { BlockUI } from "primereact/blockui";
import { getCurrentUser } from "../../service/AuthService";

const TrafficTableNotes: React.FunctionComponent<any> = (props: any) => {

    const [notes, setNotes] = useState<any>();
    const [notes2, setNotes2] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);
    const [role, setRole] = useState<String>("");



    const trafficTableNotesOnBlur = (event: any) => {
        if (role !== 'ACCOUNT' && role !== 'ADMIN') {
            return;
        }
        console.log("onBlur");
        console.log(notes);
        updateTrafficTableNotes({ notes: notes, notes2: notes2 }).then(
            (response) => {

            },
            (error) => {
                console.log(error.response.status);
            }
        );
    }

    const trafficTableNotesOnChange = (event: any) => {
        setNotes(event.target.value);
    }

    const trafficTableNotes2OnChange = (event: any) => {
        setNotes2(event.target.value);
    }

    useEffect(() => {
        setRole(getCurrentUser().role);
        setLoading(true);
        readTrafficTableNotes().then(
            (response) => {
                setNotes(response.data.notes);
                setNotes2(response.data.notes2);
                setLoading(false);
            },
            (error) => {
                console.log(error.response.status);
                setLoading(false);
            }
        );

    }, []);

    return (
        <>
            <div className="ml-5">
                <BlockUI blocked={loading} template={<i className="pi pi-spin pi-spinner ml-5" style={{ 'fontSize': '1rem' }} />}>

                    <div className="formgrid grid" style={{ width: "60vw" }}>
                        <div className="field col-12 md:col-6">
                            <InputTextarea
                                className="w-full"
                                onBlur={(e) => { trafficTableNotesOnBlur(e) }}
                                onChange={(e) => trafficTableNotesOnChange(e)}
                                value={notes}
                                id="tafficTableNotes"
                                readOnly={role !== 'ACCOUNT' && role !== 'ADMIN'}
                                style={{ resize: "none" }}
                                rows={3} />
                        </div>
                        <div className="field col-12 md:col-6">
                            <InputTextarea
                                className="w-full"
                                onBlur={(e) => { trafficTableNotesOnBlur(e) }}
                                onChange={(e) => trafficTableNotes2OnChange(e)}
                                value={notes2}
                                id="tafficTableNotes2"
                                readOnly={role !== 'ACCOUNT' && role !== 'ADMIN'}
                                style={{ resize: "none", }}
                                rows={3} />
                        </div>
                    </div>


                </BlockUI>
            </div>
        </>
    );
};

export default TrafficTableNotes;
