import { createSlice, PayloadAction } from "@reduxjs/toolkit";


interface NavigationType {
    activeView: string,
    activeMenu: string
}

const initialState: NavigationType = {
    activeView: "Dashboard",
    activeMenu: "dashboard"
};

export const navigationSlice = createSlice({
    name: "NavigationSliceAction",
    initialState: initialState,
    reducers: {
        setActiveView: (state, action: PayloadAction<any>) => {
            state.activeView = action.payload;
        },
        setActiveMenu: (state, action: PayloadAction<any>) => {
            state.activeMenu = action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const { setActiveView: setActiveView } = navigationSlice.actions;
export const { setActiveMenu: setActiveMenu } = navigationSlice.actions;
export default navigationSlice.reducer;
