import axios from 'axios';
import { server } from '../config/endpoints';

const API_URL = server;

export const readAllVendors = () => {
    return axios
        .get(API_URL + '/protected/vendor')
        .then((response) => {
            return response;
        });
};

export const readAllVendorsAsLabelValue = () => {
    return axios
        .get(API_URL + '/protected/vendor/lv')
        .then((response) => {
            return response;
        });
};

export const readVendor = (id: string) => {
    return axios
        .get(API_URL + '/protected/vendor/' + id)
        .then((response) => {
            return response;
        });
};


export const deleteVendor = (id: string) => {
    return axios
        .delete(API_URL + '/protected/vendor/' + id)
        .then((response) => {
            return response;
        });
};


export const saveVendor = (formData: any) => {
    return axios
        .post(API_URL + '/protected/vendor', formData)
        .then((response) => {
            return response;
        });
};