import { createSlice, PayloadAction } from "@reduxjs/toolkit";


interface TrafficTableType {
    trafficTableLineList: any[];
}


const initialState: TrafficTableType = {
    trafficTableLineList: []
};

export const trafficTableSlice = createSlice({
    name: "TrafficTableAction",
    initialState: initialState,
    reducers: {
        setTrafficTableLineList: (state, action: PayloadAction<any>) => {
            state.trafficTableLineList = action.payload;
        },
        cleanTrafficTableLineList: (state) => {
            state.trafficTableLineList = [];
        },
        setTrafficTableLineCell: (state, action: PayloadAction<any>) => {
            state.trafficTableLineList = state.trafficTableLineList.map((line: any, index: number)=> {
                if(index===action.payload.index){
                     return {...line, [action.payload.field]: action.payload.value};
                }
                return line;
            });
        },
    },
});

export const { setTrafficTableLineList } = trafficTableSlice.actions;
export const { cleanTrafficTableLineList } = trafficTableSlice.actions;
export const { setTrafficTableLineCell } = trafficTableSlice.actions;

export default trafficTableSlice.reducer;
