import React, { useEffect, useState } from "react";

const Percentage: React.FunctionComponent<any> = (props: any) => {

    const [color, setColor] = useState<string>();

    const [text, setText] = useState<string>();

    useEffect(() => {
        if (props.percentage <= 75) {
            setColor('green');
            setText( Number(props.percentage).toFixed() + '%-on állunk a Tény ' + props.role + ' órákkal az Árajánlathoz képest, EZ JÓ ARÁNY.');
        } else  if (props.percentage > 75 && props.percentage <= 90) {
            setColor('blue');
            setText(Number(props.percentage).toFixed() + '%-on állunk a Tény ' + props.role + ' órákkal az Árajánlathoz képest, BIZTOSAN ELÉG LESZ AZ ÁRAJÁNLATBAN BETERVEZETT ÓRA?');
        } else  if (props.percentage > 90 && props.percentage <= 99) {
            setColor('purple');
            setText(Number(props.percentage).toFixed() + '%-on állunk a Tény ' + props.role + ' órákkal az Árajánlathoz képest, NEM KÉNE MEGEMELT ÓRASZÁMMAL ÚJ ÁRAJÁNLATOT KIKÜLDENED?');
        } else {
            setColor('red');
            setText(Number(props.percentage).toFixed() + '%-on állunk a Tény ' + props.role + ' órákkal az Árajánlathoz képest, AZONNAL KÜLDJ KI MEGEMELT ÓRASZÁMMAL ÚJ ÁRAJÁNLATOT!');
        }
    }, [props.percentage, props.percentageVisible]);




    return (
        <>
            <div className={"flex ml-2 align-items-start p-3 bg-" + color + "-100 border-round border-1 mt-0 h-7rem " + "border-" + color + "-300"}>
                <i className={"pi pi-info-circle  text-2xl mr-3 " + "text-" + color + "-900"} ></i>
                <div className="mr-1">
                    <p className={"m-0 p-0  line-height-3 font-bold " + "text-" + color + "-700"}>
                        {text}
                    </p>
                </div>
            </div>
        </>
    );
};

export default Percentage;
