import React from "react";
import { Navigate, Outlet } from "react-router";
import { getCurrentUser } from "../service/AuthService";

const WelcomeLoginRoute: React.FC = () => {
    let user = getCurrentUser();
    if (user) {
        return <Navigate to="/main2/dashboard" replace />;
    }
    return <Outlet />;
};

export default WelcomeLoginRoute;
