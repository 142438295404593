import { createSlice, PayloadAction } from "@reduxjs/toolkit";


interface ContributorType {
    contributorList: [];
}


const initialState: ContributorType = {
    contributorList: []
};

export const contributorSlice = createSlice({
    name: "ContributorAction",
    initialState: initialState,
    reducers: {
        setContributorList: (state, action: PayloadAction<any>) => {
            state.contributorList = action.payload;
        },
        cleanContributorList: (state) => {
            state.contributorList = [];
        },
    },
});

export const { setContributorList } = contributorSlice.actions;
export const { cleanContributorList } = contributorSlice.actions;

export default contributorSlice.reducer;
