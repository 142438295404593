import axios from 'axios';
import { server } from '../config/endpoints';

const API_URL = server;

export const readAllContributors = () => {
    return axios
        .get(API_URL + '/protected/contributor')
        .then((response) => {
            return response;
        });
};

export const readAllContributorsAsLabelValue = () => {
    return axios
        .get(API_URL + '/protected/contributor/lv')
        .then((response) => {
            return response;
        });
};

export const readContributor = (id: string) => {
    return axios
        .get(API_URL + '/protected/contributor/' + id)
        .then((response) => {
            return response;
        });
};


export const deleteContributor = (id: string) => {
    return axios
        .delete(API_URL + '/protected/contributor/' + id)
        .then((response) => {
            return response;
        });
};


export const saveContributor = (formData: any) => {
    return axios
        .post(API_URL + '/protected/contributor', formData)
        .then((response) => {
            return response;
        });
};