import React, {useState} from "react";
import {useNavigate} from "react-router";
import {useSearchParams} from "react-router-dom";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {Password} from "primereact/password";
import {useFormik} from "formik";
import * as Yup from "yup";
import StatusCodes from "http-status-codes";
import RightLayoutBenefits2 from "../../component/RightLayoutBenefits2";
import MessageDialog from "../../component/MessageDialog";
import {login} from "../../service/AuthService";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "../../css/Login.css";
import loginImage from "../../asset/data-points.svg";
import {BlockUI} from 'primereact/blockui';


const Login: React.FC = () => {
    //   console.log('Login is called');
    let [searchParams] = useSearchParams();
    const status: any = searchParams.get("status");

    let navigate = useNavigate();

    const [messageDialogShow, setMessageDialogShow] = useState<Number>(0);
    const [messageDialogHeader, setMessageDialogHeader] = useState<string>("");
    const [messageDialogBody, setMessageDialogBody] = useState<string>("");
    const [messageDialogError, setMessageDialogError] =
        useState<boolean>(false);
    const [blockLogin, setBlockLogin] = useState<boolean>(false);


    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: Yup.object({
            password: Yup.string()
                .min(5, "Minimum 5 karakter kell, hogy legyen!")
                .required("Kötelező kitölteni!"),
            email: Yup.string()
                .min(5, "Minimum 5 karakter kell, hogy legyen!")
                .email("Hibás email cím!")
                .required("Kötelező kitölteni!"),
        }),
        onSubmit: (values) => {
            setMessageDialogHeader("Értesítés");
            setMessageDialogBody("");
            setBlockLogin(true);
            //alert(JSON.stringify(values, null, 2));

            login(values.email, values.password).then(
                () => {
                    setBlockLogin(false);
                    //navigate("/main/dashboard");
                    navigate("/main2/dashboard");
                },
                (error) => {
                    //   console.log(error.response.status);
                    setBlockLogin(false);
                    const responseMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    if (error.response.status === StatusCodes.UNAUTHORIZED) {
                        setMessageDialogHeader("Hibás bejelentkezés!");
                        setMessageDialogBody(
                            "Hibás email cím és/vagy jelszó!"
                        );
                    } else if (
                        error.response.status === StatusCodes.FORBIDDEN
                    ) {
                        setMessageDialogHeader("Bejelentkezési hiba!");
                        setMessageDialogBody(
                            "Hibás email cím és/vagy jelszó. Kérem próbálja újra!"
                        );
                    } else {
                        setMessageDialogHeader("Hiba");
                        if (
                            responseMessage === "Network Error" ||
                            error.response.status ===
                            StatusCodes.METHOD_NOT_ALLOWED
                        ) {
                            setMessageDialogBody(
                                "Hálozati hiba, kérjük próbáljon meg pár perc múlva bejelentkezni."
                            );
                        } else {
                            setMessageDialogBody(responseMessage);
                        }
                    }
                    setMessageDialogError(true);
                    setMessageDialogShow(Date.now());
                }
            );
        },
    });

    return (
        <>
            <div className="px-4 mt-4 md:px-6 lg:px-8">
                <MessageDialog
                    messageDialogError={messageDialogError}
                    messageDialogShow={messageDialogShow}
                    messageDialogHeader={messageDialogHeader}
                    messageDialogBody={messageDialogBody}
                />
                {status === "validated" ? (
                    <div
                        className="mb-5 flex align-items-start p-4 bg-green-100 border-round border-1 border-green-300">
                        <i className="pi pi-check-circle text-green-900 text-2xl mr-3"></i>
                        <div className="mr-3">
                            <div className="text-green-900 font-medium text-xl mb-2 line-height-1">
                                Gratulálunk!
                            </div>
                            <p className="m-0 p-0 text-green-700 line-height-3">
                                Sikeresen validálta az e-mail címét.
                            </p>
                        </div>
                    </div>
                ) : null}
                {status === "passwordchanged" ? (
                    <div
                        className="mb-5 flex align-items-start p-4 bg-green-100 border-round border-1 border-green-300">
                        <i className="pi pi-check-circle text-green-900 text-2xl mr-3"></i>
                        <div className="mr-3">
                            <div className="text-green-900 font-medium text-xl mb-2 line-height-1">
                                Gratulálunk!
                            </div>
                            <p className="m-0 p-0 text-green-700 line-height-3">
                                Sikeresen beállította az új jelszavát.
                            </p>
                        </div>
                    </div>
                ) : null}
                {status === "sentpasswordrequestemail" ? (
                    <div
                        className="mb-5 flex align-items-start p-4 bg-green-100 border-round border-1 border-green-300">
                        <i className="pi pi-check-circle text-green-900 text-2xl mr-3"></i>
                        <div className="mr-3">
                            <div className="text-green-900 font-medium text-xl mb-2 line-height-1">
                                Értesítés!
                            </div>
                            <p className="m-0 p-0 text-green-700 line-height-3">
                                Sikeresen elküldtük a jelszó beállításhoz
                                szükséges linket, kérjük ellenőrizze az e-mail
                                fiókját!
                            </p>
                        </div>
                    </div>
                ) : null}
                {status === "registrationrequired" ? (
                    <div className="mb-5 flex align-items-start p-4 bg-red-100 border-round border-1 border-red-300">
                        <i className="pi pi-times-circle text-red-900 text-2xl mr-3"></i>
                        <div className="mr-3">
                            <div className="text-red-900 font-medium text-xl mb-2 line-height-1">
                                Regisztráció szükséges
                            </div>
                            <p className="m-0 p-0 text-red-700 line-height-3">
                                Sajnos a jelszó igénylésnél megadott e-mail cím
                                nem szerepel a Zepter Club Live 100
                                adatbázisában, így nem tudunk jelszót beállítani
                                a fiókjához, új regisztráció szükséges.
                                <br/>
                                Regisztrációhoz kérjen meghívó linket bármely
                                klubtagunktól vagy tanácsadónktól!
                            </p>
                        </div>
                    </div>
                ) : null}
                {status === "connectionerror" ? (
                    <div className="mb-5 flex align-items-start p-4 bg-red-100 border-round border-1 border-red-300">
                        <i className="pi pi-times-circle text-red-900 text-2xl mr-3"></i>
                        <div className="mr-3">
                            <div className="text-red-900 font-medium text-xl mb-2 line-height-1">
                                Kommunikációs hiba
                            </div>
                            <p className="m-0 p-0 text-red-700 line-height-3">
                                Sajnos hiba lépett fel a Zepter központi
                                rendszeréhez törénő csatlakozás során.
                                <br/>
                                Kérjük próbáljon meg később belépni.
                            </p>
                        </div>
                    </div>
                ) : null}
                {status === "unauthorized" ? (
                    <div
                        className="mb-5 flex align-items-start p-4 bg-yellow-100 border-round border-1 border-yellow-300">
                        <i className="pi pi-times-circle text-yellow-900 text-2xl mr-3"></i>
                        <div className="mr-3">
                            <div className="text-yellow-900 font-medium text-xl mb-2 line-height-1">
                                Lejárt a munkamenet
                            </div>
                            <p className="m-0 p-0 text-yellow-700 line-height-3">
                                Kérjük jelentkezzen be újra.
                            </p>
                        </div>
                    </div>
                ) : null}
                <div className="flex flex-wrap shadow-2">
                    <div className="grid p-4 pb-0 lg:p-7 lg:pb-0">
                        <div className="col-12 md:col-6 lg:col-5">
                            <img
                                src={loginImage}
                                className="responsive"
                                alt="bi.skymarketing.hu bejelentkezés kép"
                            />
                        </div>
                        <div className="col-12 md:col-6 lg:col-7">
                            <div className="text-xl text-black-alpha-90 font-500 mb-3 sm:mt-3">
                                SKY-Business Intelligence
                            </div>
                            <p className="text-xl text-black-alpha-50 line-height-3 mt-0 mb-3">
                                Ha még nincs hozzáférése, kérjük forduljon a rendszergazdához!
                            </p>
                        </div>
                    </div>
                    <div className="mt-0 w-full p-4 surface-card lg:w-6 lg:p-7">
                        <form
                            onSubmit={formik.handleSubmit}
                            className="p-fluid"
                        >
                            <div className="formgrid grid">
                                <div className="field col-12 md:col-12">
                                    <label
                                        htmlFor="userName"
                                        className="block text-900 font-medium mb-2"
                                    >
                                        Email cím
                                    </label>
                                    <InputText
                                        className="w-full mb-3"
                                        id="email"
                                        // value={formik.values.email}
                                        {...formik.getFieldProps("email")}
                                        onBlur={(val: any) => {
                                            const emailTrim =
                                                formik.values.email
                                                    .trim()
                                                    .toLocaleLowerCase();
                                            formik.setFieldValue(
                                                "email",
                                                emailTrim
                                            );
                                        }}
                                    />
                                    {formik.touched.email &&
                                    formik.errors.email ? (
                                        <small className="p-error">
                                            {formik.errors.email}
                                        </small>
                                    ) : null}
                                </div>
                            </div>
                            <div className="formgrid grid">
                                <div className="field col-12 md:col-12">
                                    <label
                                        htmlFor="password"
                                        className="block text-900 font-medium mb-2"
                                    >
                                        Jelszó
                                    </label>
                                    <Password
                                        id="password"
                                        className="w-full mb-3"
                                        feedback={false}
                                        {...formik.getFieldProps("password")}
                                    />
                                    {formik.touched.password &&
                                    formik.errors.password ? (
                                        <small className="p-error">
                                            {formik.errors.password}
                                        </small>
                                    ) : null}
                                </div>
                            </div>
                           
                                <div className="formgrid grid">
                                    <div className="field col-12 md:col-12">
                                    <BlockUI blocked={blockLogin}
                                     template={<i className="pi pi-spin pi-spinner text-teal-50" style={{'fontSize': '2rem'}}/>}>
                                        <Button
                                            className="w-full"
                                            label="Bejelentkezés"
                                            type="submit"
                                        />
                            </BlockUI>

                                    </div>
                                </div>
                        </form>
                    </div>
                    <RightLayoutBenefits2/>
                </div>
            </div>
        </>
    );
};

export default Login;
