import axios from 'axios';
import { server } from '../config/endpoints';

const API_URL = server;


export const createMyWorkingTimeReport = (formData: any) => {
    // axios.defaults.timeout = 1000000;
    return axios
        .post(API_URL + '/protected/report/myworkingtime', formData,
        {
            responseType: "blob",
            timeout: 1000000
        })
        .then((response) => {
            return response;
        })
        // .catch((error) => {
        //     // Error 😨
        //     if (error.response) {
        //         /*
        //          * The request was made and the server responded with a
        //          * status code that falls out of the range of 2xx
        //          */
        //         console.log(error.response.data);
        //         console.log(error.response.status);
        //         console.log(error.response.headers);
        //     } else if (error.request) {
        //         /*
        //          * The request was made but no response was received, `error.request`
        //          * is an instance of XMLHttpRequest in the browser and an instance
        //          * of http.ClientRequest in Node.js
        //          */
        //         console.log(error.request);
        //     } else {
        //         // Something happened in setting up the request and triggered an Error
        //         console.log('Error', error.message);
        //     }
        //     console.log(error.config);
        //     return error;
        // })
        ;
};

