import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "primeflex/primeflex.css";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { RootState } from "../../state/store";

import { StatusCodes } from "http-status-codes";
import { getCurrentUser, logout, setAuthToken } from "../../service/AuthService";
import { useNavigate } from "react-router";
import { setActiveMenu, setActiveView } from "../../state/navigationSlice";
import "../../css/Admin.css";
import { FileUpload, FileUploadUploadParams } from 'primereact/fileupload';
import { readAllVendorInvoices } from "../../service/VendorInvoiceService";
import { setVendorInvoiceList } from "../../state/vendorInvoiceSlice";
import VendorInvoiceDetail from "./VendorInvoiceDetail";
import { Toast } from "primereact/toast";
import { server } from "../../config/endpoints";
import { authHeader } from "../../service/AuthService";
import { Checkbox } from "primereact/checkbox";
import { SelectButton } from 'primereact/selectbutton';
import { onRowIndexTemplate } from "../../service/IndexColumn";
import { readAllCustomerInvoiceLoadResults } from "../../service/CustomerInvoiceLoadResultService";



const CustomerInvoiceLoadResultList: React.FC = () => {
  const ExcelJS = require("exceljs");
  const dispatch = useDispatch();
  let navigate = useNavigate();
  setAuthToken();
  dispatch(setActiveView("Vevői számla betöltések"));
  dispatch(setActiveMenu("customerInvoiceLoadResultList"));

  const [loading, setLoading] = useState<boolean>(false);
  const toast: any = useRef(null);

  const [customerInvoiceLoadResultList, setCustomerInvoiceLoadResultList] = useState<any>([]);


  const [company, setCompany] = useState('SKY');
  const [verification, setVerification] = useState('Sikeres');
  const [role, setRole] = useState<String>("");

  const timestamp = () => {
    let today: Date = new Date;
    return today.getFullYear() + 
    today.toLocaleString("hu-HU", { month: "2-digit" }) + 
    today.toLocaleString("hu-HU", { day: "2-digit" })  +
    today.toLocaleTimeString("hu-HU", { hour: "2-digit" })  +
    today.toLocaleTimeString("hu-HU", { minute: "2-digit" })  +
    today.toLocaleTimeString("hu-HU", { second: "2-digit" }) ;
  };

  const saveAsExcelFile = (buffer: any, fileName: any) => {
    import('file-saver').then(module => {
      if (module && module.default) {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data = new Blob([buffer], {
          type: EXCEL_TYPE
        });

        module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
      }
    });
  }


  const exportExcel = () => {
    import('xlsx').then(xlsx => {
      let headings = [['Sorszám', 'Létrehozva', 'ID', 'Sor', 'Cég' , 'Számla sorszáma', 'Rövid munkaszám', 'Account', 'Eredmény', 'Üzenet', 'Betöltés' ]];
      const worksheet = xlsx.utils.json_to_sheet([]);
      xlsx.utils.sheet_add_aoa(worksheet, headings);
      xlsx.utils.sheet_add_json(worksheet, customerInvoiceLoadResultList, { origin: 'A2', skipHeader: true });
      worksheet['!cols'] = [{ width: 10 }, { width: 20 }, { width: 10 }, { width: 150 }, { width: 10 }, { width: 30 }, { width: 30 }, { width: 40 }, { width: 10 }, { width: 40 }, { width: 10 } ]; 
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      saveAsExcelFile(excelBuffer, 'CustomerInvoiceLoadResult');
    });
  }

  const exportExcel2 = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("VendorInvoiceLoadResult");
/*     sheet.getCell('A1')
    .border = {
      top: { style: "thick", color: { argb: "FFFF0000" } },
      left: { style: "thick", color: { argb: "000000FF" } },
      bottom: { style: "thick", color: { argb: "F08080" } },
      right: { style: "thick", color: { argb: "FF00FF00" } },
    }; */
    let columns = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];

    for (let index = 0; index < columns.length; index++) {
      const element = columns[index];
      sheet.getCell(element + 1).fill = {
        type: "pattern",
        pattern: "darkVertical",
        fgColor: { argb: "FFFF00" },
      };
      sheet.getCell(element + 1).font = {
        name: "Arial",
        family: 4,
        size: 14,
        bold: true,
      };      
    }    
    sheet.columns = [
      { header: "Index", key: "rowNo",width: 15, },
      { header: "Betöltés", key: "loadId",width: 15, },
      { header: "Cég", key: "company",width: 10, },
      { header: "Létrehozva", key: "created", width: 30,},
      { header: "Számla sorszáma", key: "invoiceNo", width: 30,},
      { header: "Rövid munkaszám", key: "projectShortIdentifier", width: 30,},
      { header: "Üzenet", key: "message", width: 30,},
      { header: "Sor", key: "line", width: 200,},
    ];

    customerInvoiceLoadResultList.map((loadResult: any, index: number) => {
      sheet.addRow({
        rowNo: loadResult?.rowNo,
        loadId: loadResult?.loadId,
        company: loadResult?.company,
        created: loadResult?.created,
        invoiceNo: loadResult?.invoiceNo,
        projectShortIdentifier: loadResult?.projectShortIdentifier,
        message: loadResult?.message,
        line: loadResult?.line,
      });

      sheet.getRow(index + 2).font = {
        name: "Arial",
        family: 4,
        size: 11,
      };     
    })

    workbook.xlsx.writeBuffer().then(function (data: any) {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const blob = new Blob([data], {
        type: EXCEL_TYPE,
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = 'CustomerInvoiceLoadResult-' + timestamp() + EXCEL_EXTENSION;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  }




  const refresh = (company: string, verification: string) => {
    console.log("CustomerList's useEffect");
    setLoading(true);
    setRole(getCurrentUser().role);
    readAllCustomerInvoiceLoadResults(company, verification).then(
      (response) => {
        setCustomerInvoiceLoadResultList(response.data.customerInvoiceLoadResultList);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        if (error.response.status === StatusCodes.UNAUTHORIZED) {
          logout();
          navigate("/login?status=unauthorized");
        }
      }
    );
  }

  useEffect(() => {
    refresh(company, verification);
  }, [navigate, dispatch]);



  const options = ['SKY', 'BPC'];
  const options2 = ['Sikeres', 'Hibás', 'Összes'];
  return (
    <>
      <div className="border-2 surface-border border-round surface-section flex-auto p-3 md:p-4 lg:p-5">
        <Toast ref={toast} />
        <div className="grid">
          <div className="col-2">
            <SelectButton unselectable={false} style={{ width: '180px' }} className='p-panel p-button p-button-sm  mb-3 p-button-text flex align-items-center' value={company} options={options} onChange={(e) => { setCompany(e.value); refresh(e.value, verification); }} />
          </div>
          <div className="col-4">
            <SelectButton unselectable={false} style={{ width: '480px' }} className='p-panel p-button p-button-sm  mb-3 p-button-text flex align-items-center' value={verification} options={options2} onChange={(e) => { setVerification(e.value); refresh(company, e.value); }} />
          </div>
          <div className="col-6">
            <div className="flex justify-content-end flex-wrap card-container green-container">
              <div className="flex align-items-center justify-content-center">
                <Button
                  className="p-panel p-button p-button-sm p-button-info mt-3 p-button-text"
                  icon="pi pi-download"
                  label="Letöltés"
                  onClick={exportExcel2}
                />
              </div>
            </div>
          </div>
        </div>

        <DataTable
          //className="admin-list"
          value={customerInvoiceLoadResultList}
          size="small"
          scrollable
          scrollHeight="70vh"
          //scrollDirection="both"
          responsiveLayout="scroll"
          stripedRows
          loading={loading}
        >
          <Column field="rowIndex" header="" style={{ maxWidth: '60px' }} body={onRowIndexTemplate} />
          <Column field="loadId" style={{ maxWidth: '100px' }} header="ID" sortable  />
          <Column field="company" style={{ maxWidth: '100px' }} header="Cég" sortable />
          <Column field="created" style={{ maxWidth: '180px' }} header="Létrehozva" sortable />
          <Column style={{ maxWidth: "200px" }} alignHeader="left" field="invoiceNo" header="Számla sorszáma" sortable />
          <Column style={{ maxWidth: "300px" }} alignHeader="left" field="projectShortIdentifier" header="Rövid munkaszám" sortable />
          {/* <Column style={{ maxWidth: "130px" }} alignHeader="left" field="projectOwnerShortName" header="Account" sortable /> */}
          <Column style={{ maxWidth: '150px' }} field="message" header="Üzenet" sortable />
          <Column style={{ maxWidth: '590px' }} field="line" header="Sor" />
        </DataTable>
      </div>
    </>
  );
};

export default CustomerInvoiceLoadResultList;
