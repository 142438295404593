import React, { useEffect, useState, Fragment } from "react";
import { useDispatch } from "react-redux";
import "primeflex/primeflex.css";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useNavigate } from "react-router";
import { StatusCodes } from "http-status-codes";
import * as Yup from "yup";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { confirmDialog } from "primereact/confirmdialog";
import { addTask, updateTaskName } from "../../service/TrafficTableService";
import { setTrafficTableLineList } from "../../state/trafficTableSlice";
import { logout } from "../../service/AuthService";
import {
  readAllAccountSkyUsers,
  readAllCoworkerSkyUsers,
  readAllCreativeSkyUsers,
  readOnlyCoworkerSkyUsers,
} from "../../service/SkyUserService";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { newTask } from "../../service/TaskService";

const NewTaskDialog: React.FC<any> = (props: any) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [dialogShow, setDialogShow] = useState<boolean>(false);

  const [dialogChange, setDialogChange] = useState<number>(0);

  const hideDialog = () => {
    setDialogShow(false);
    formik.resetForm();
    refresh();
  };

  const [allCreatives, setAllCreatives] = useState<any>([]);
  const [selectedCreatives, setSelectedCreatives] = useState<any>(null);

  const [allAccounts, setAllAccounts] = useState<any>([]);
  const [selectedAccounts, setSelectedAccounts] = useState<any>(null);

  const [allCoworkers, setAllCoworkers] = useState<any>([]);
  const [selectedCoworkers, setSelectedCoworkers] = useState<any>(null);

  const projectId: string = props.projectId;
  const refresh = props.refresh;

  const header: string = props.projectShortIdentifier +
    " " + props.projectName;


  const confirmSave = (values: any) => {
    confirmDialog({
      message: "Valóban menti a módosításokat?",
      header: "Megerősítés",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        values.accountList = selectedAccounts;
        values.creativeList = selectedCreatives;
        values.coworkerList = selectedCoworkers;
        //alert(JSON.stringify(values, null, 2));
        newTask(values).then(
          (response) => {
              hideDialog();
          },
          (error) => {
              console.log(error);
              hideDialog();
          }
      );
        hideDialog();
      },
      reject: () => { },
    });
  };

  useEffect(() => {
    if (props.show === true) {
      setSelectedAccounts([]);
      setSelectedCoworkers([]);
      setSelectedCreatives([]);
      readAllCreativeSkyUsers().then(
        (response) => {
          setAllCreatives(response.data.skyUserList);
        },
        (error) => {
          console.log(error.response.status);
        }
      );
      readAllAccountSkyUsers().then(
        (response) => {
          setAllAccounts(response.data.skyUserList);
        },
        (error) => {
          console.log(error.response.status);
        }
      );
      readAllCoworkerSkyUsers().then(
        (response) => {
          setAllCoworkers(response.data.skyUserList);
        },
        (error) => {
          console.log(error.response.status);
        }
      );
      let accounts: any = [];
      
      
      setDialogChange(props.change);
      setDialogShow(props.show);
    }
  }, [dialogChange, navigate, props.show, props.change, projectId]);

  const formik = useFormik({
    initialValues: {
      taskName: "",
      taskComment: "",
      projectId: projectId ? projectId : ""
    },
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: Yup.object({
      taskName: Yup.string()
        .min(2, "A névnek legalább 2 karakternek kell lennie")
        .required("Kötelező kitölteni!"),
    }),
    onSubmit: (values) => {
      if (dialogShow) {
        confirmSave(values);
      }
    },
  });

  return (
    <>
      <Dialog
        header="Új feladat felvétele"
        visible={dialogShow}
        closable={false}
        maximizable
        modal
        style={{ width: "70vw" }}
        onHide={() => hideDialog()}
      >
        <form onSubmit={formik.handleSubmit} className="p-fluid">
          <div className="formgrid grid">
            <div className="field col-12">
              <div className="flex align-items-start p-3 bg-orange-100 border-round border-1 border-yellow-300">
                <i className="pi pi-info-circle text-yellow-900 text-2xl mr-3"></i>
                <div className="mr-1">
                  <p className="m-0 p-0 text-yellow-700 line-height-3 font-bold">
                    Mielőtt létrehozod, győződj meg róla, hogy más még nem hozta
                    létre ezt a feladatot! (Munka adatlapon, Traffik táblában,
                    Elrejtett feladatok között érdemes ellenőrizni.)
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="formgrid grid">
            <div className="field col-12">
              <label
                htmlFor="taskName"
                className="block text-900 font-medium mb-2"
              >
                Munkaszám és Munkanév
              </label>
              <InputText
                className="w-full mb-3"
                autoFocus
                readOnly
                id="projectShortIdentifier"
                name="projectShortIdentifier"
                value={header}
              />
            </div>
          </div>



          <div className="formgrid grid">
            <div className="field col-12">
              <label
                htmlFor="taskName"
                className="block text-900 font-medium mb-2"
              >
                Új feladat neve
              </label>
              <InputText
                className="w-full mb-3"
                autoFocus
                id="taskName"
                name="taskName"
                value={formik.values.taskName}
                onChange={formik.handleChange}
              />
              {formik.touched.taskName && formik.errors.taskName ? (
                <small className="p-error">
                  {formik.errors.taskName.toString()}
                </small>
              ) : null}
            </div>
          </div>

          <div className="formgrid grid">
            <div className="field col-12">
              <div className="flex align-items-start p-3 bg-green-100 border-round border-1 border-green-300">
                <i className="pi pi-info-circle text-green-900 text-2xl mr-3"></i>
                <div className="mr-1">
                  <p className="m-0 p-0 text-green-700 line-height-3 font-bold">
                    Pipával jelöld, hogy kinél jelenjen meg a feladat!
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="formgrid grid">
            <div className="field col-4">
              <DataTable
                header="Kreatívok"
                scrollable
                scrollHeight="40vh"
                value={allCreatives}
                size="small"
                responsiveLayout="scroll"
                selectionMode="checkbox"
                selection={selectedCreatives}
                onSelectionChange={(e) => setSelectedCreatives(e.value)}
                dataKey="id"
              >
                <Column
                  selectionMode="multiple"
                  style={{ maxWidth: "50px" }}
                ></Column>
                <Column
                  key="shortName"
                  sortable
                  filter
                  field="shortName"
                  header="Rövid név"
                  style={{ width: "125px" }}
                />
              </DataTable>
            </div>

            <div className="field col-4">
              <DataTable
                header="Accountok"
                scrollable
                scrollHeight="40vh"
                value={allAccounts}
                size="small"
                responsiveLayout="scroll"
                selectionMode="checkbox"
                selection={selectedAccounts}
                onSelectionChange={(e) => setSelectedAccounts(e.value)}
                dataKey="id"
              >
                <Column
                  selectionMode="multiple"
                  style={{ maxWidth: "50px" }}
                ></Column>
                <Column
                  key="shortName"
                  sortable
                  filter
                  field="shortName"
                  header="Rövid név"
                  style={{ width: "125px" }}
                />
              </DataTable>
            </div>

            <div className="field col-4">
              <DataTable
                header="Munkatársak"
                scrollable
                scrollHeight="40vh"
                value={allCoworkers}
                size="small"
                responsiveLayout="scroll"
                selectionMode="checkbox"
                selection={selectedCoworkers}
                onSelectionChange={(e) => setSelectedCoworkers(e.value)}
                dataKey="id"
              >
                <Column
                  selectionMode="multiple"
                  style={{ maxWidth: "50px" }}
                ></Column>
                <Column
                  key="shortName"
                  sortable
                  filter
                  field="shortName"
                  header="Rövid név"
                  style={{ width: "125px" }}
                />
              </DataTable>
            </div>
          </div>

          <div className="formgrid grid">
            <div className="field">
              <Button
                className="p-button-text"
                type="button"
                label="Bezárás"
                icon="pi pi-times"
                onClick={() => {
                  hideDialog();
                }}
              />
            </div>

            <div className="field">
              <Button
                className="p-button-text"
                type="submit"
                label="Mentés"
                icon="pi pi-check"
              />
            </div>
          </div>
        </form>
      </Dialog>
    </>
  );
};

export default NewTaskDialog;
