import React from "react";
import skyGroupLogo from "../../asset/sky-group-logo.png";

const Footer: React.FunctionComponent<any> = (props: any) => {
    return (
        <div className="surface-section px-4 py-2 md:px-6 lg:px-8 text-center">
            <img
                src={skyGroupLogo}
                height="50"
                className="mt-0 mb-3"
                alt="Zepter Club Live 100 logo"
            />
            <div className="font-medium text-900 mt-0 mb-3">
                &copy; 2022 SKY Marketing Communications Kft.
            </div>
            <p className="text-600 line-height-3 mt-0 mb-3">
            1036 Budapest, Lajos utca 80. +36 1 487 7444
            </p>
            <p className="font-small mt-0 mb-3">
                verzió: {process.env.REACT_APP_VERSION}
            </p>
        </div>
    );
};

export default Footer;
