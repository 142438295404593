import React, { useEffect, useState, Fragment, useRef } from "react";
import { useDispatch } from "react-redux";
import "primeflex/primeflex.css";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useNavigate } from "react-router";
import { StatusCodes } from "http-status-codes";
import { logout } from "../../service/AuthService";
import * as Yup from "yup";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { confirmDialog } from "primereact/confirmdialog";
import { readAllCustomers, readCustomer, saveCustomer } from "../../service/CustomerService";
import { setCustomerList } from "../../state/customerSlice";
import { DataTable } from "primereact/datatable";
import { Column, ColumnEventParams } from "primereact/column";
import { readAllVendors, readAllVendorsAsLabelValue, readVendor, saveVendor } from "../../service/VendorService";
import { setVendorList } from "../../state/vendorSlice";
import { readAllVendorInvoices, readVendorInvoice, saveVendorInvoice } from "../../service/VendorInvoiceService";
import { setVendorInvoiceList } from "../../state/vendorInvoiceSlice";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { Calendar } from "primereact/calendar";
import { Toast } from "primereact/toast";



const VendorInvoiceDetail: React.FC<any> = (props: any) => {

    let navigate = useNavigate();
    const dispatch = useDispatch();

    const [
        vendorInvoiceDetailDialogShow,
        setVendorInvoiceDetailDialogShow,
    ] = useState<boolean>(false);

    const [
        vendorInvoiceDetailDialogChange,
        setVendorInvoiceDetailDialogChange,
    ] = useState<number>(0);

    const hideDialog = () => {
        setVendorInvoiceDetailDialogShow(false);
        setVendorInvoiceDto(null);
        formik.resetForm();
        refreshVendorInvoiceList();
    };

    const [vendorInvoiceDto, setVendorInvoiceDto] = useState<any>();
    const [vendors, setVendors] = useState<any>([]);

    const vendorInvoiceId: any = props.id;
    const onClose = props.onClose;

    const onCloseModal = () => {
        onClose();
    }



    const confirmSave = (values: any) => {
        confirmDialog({
            message: 'Valóban menti a módosításokat?',
            header: 'Megerősítés',
            icon: 'pi pi-exclamation-triangle',
            acceptClassName: 'p-button-text',
            rejectClassName: 'p-button-text',
            accept: () => {
                //alert(JSON.stringify(values, null, 2));
                saveVendorInvoice(values).then(
                    (response) => {
                        setVendorInvoiceDto(response.data);
                        hideDialog();
                        onCloseModal();
                    },
                    (error) => {
                        console.log(error);
                        hideDialog();
                    }
                );

            },
            reject: () => { }
        });
    }


    const refreshVendorInvoiceList = () => {
        readAllVendorInvoices('SKY').then(
            (response) => {
                dispatch(
                    setVendorInvoiceList(
                        response.data.vendorInvoiceList
                    )
                );
            },
            (error) => {
                // console.log(error.response.status);
                if (error.response.status === StatusCodes.UNAUTHORIZED) {
                    //TODO login oldalon üzenet --> Lejárt a munkamenet, jelentkezzen be
                    logout();
                    navigate("/login?status=unauthorized");
                }
            }
        );
    };


    useEffect(() => {
        if (
            props.show === true
        ) {
            console.log("VendorInvoiceDetail's useEffect");
            setVendorInvoiceDto(null);
            setVendors([]);
            readAllVendorsAsLabelValue().then(
                (response) => {
                    setVendors(response.data.labelValueList);
                },
                (error) => {
                    console.log(error.response.status);
                }
            );

            if (vendorInvoiceId !== null && vendorInvoiceId !== "") {
                readVendorInvoice(vendorInvoiceId).then(
                    (response) => {
                        setVendorInvoiceDto(response.data);
                    },
                    (error) => {
                        if (
                            error.response.status === StatusCodes.METHOD_NOT_ALLOWED
                        ) {
                            console.log("A név már szerepel a rendszerben");
                        }
                        if (error.response.status === StatusCodes.UNAUTHORIZED) {
                            logout();
                            navigate("/login?status=unauthorized");
                        }
                    }
                );
            }
            setVendorInvoiceDetailDialogChange(props.change);
            setVendorInvoiceDetailDialogShow(props.show);
        }
    }, [vendorInvoiceDetailDialogChange, navigate, props.show, props.change, vendorInvoiceId]);

    const formik = useFormik({
        initialValues: {
            id: vendorInvoiceDto && vendorInvoiceDto.id ? vendorInvoiceDto.id : "",
            vendorId: vendorInvoiceDto && vendorInvoiceDto.vendorId ? vendorInvoiceDto.vendorId : "",
            invoiceNo: vendorInvoiceDto && vendorInvoiceDto.invoiceNo ? vendorInvoiceDto.invoiceNo : "",
            company: vendorInvoiceDto && vendorInvoiceDto.company ? vendorInvoiceDto.company : "",
            dateOfCompletion: vendorInvoiceDto && vendorInvoiceDto.dateOfCompletion ? new Date(vendorInvoiceDto.dateOfCompletion) : null,
            paymentDue: vendorInvoiceDto && vendorInvoiceDto.paymentDue ? new Date(vendorInvoiceDto.paymentDue) : null,

            amount: vendorInvoiceDto && vendorInvoiceDto.amount ? vendorInvoiceDto.amount : null,
        },
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: Yup.object({
            vendorId: Yup.string().required("Kötelező kitölteni!"),
            invoiceNo: Yup.string()
                .min(2, "A névnek legalább 2 karakternek kell lennie")
                .required("Kötelező kitölteni!"),
            dateOfCompletion: Yup.date()
                .required("Kötelező kitölteni!")
                .typeError("Kötelező kitölteni!"),
            amount: Yup.number()
                .required("Kötelező kitölteni!").typeError("Kötelező kitölteni!"),
        }),
        onSubmit: (values) => {
            if (
                vendorInvoiceDetailDialogShow
            ) {
                confirmSave(values);
            }
        },
    });




    return (
        <>
            <Dialog header="Alvállalkozói számla adatai" visible={vendorInvoiceDetailDialogShow}
                maximizable modal closable={false} style={{ width: "90vw" }} onHide={() => hideDialog()}>

                <form onSubmit={formik.handleSubmit} className="p-fluid">

                    <div className="formgrid grid">
                        <div className="field col-12 md:col-6">
                            <label
                                htmlFor="vendorId"
                                className="block text-900 font-medium mb-2"
                            >
                                Alvállalkozó
                            </label>
                            <Dropdown
                                id="vendorId"
                                className="w-full mb-3"
                                {...formik.getFieldProps("vendorId")}
                                options={vendors}
                                onChange={formik.handleChange}
                                value={formik.values.vendorId}
                                placeholder="Válasszon!"
                            />
                            {formik.touched.vendorId && formik.errors.vendorId ? (
                                <small className="p-error">
                                    {formik.errors.vendorId?.toString()}
                                </small>
                            ) : null}
                        </div>

                        <div className="field col-12 md:col-6">

                            <label
                                htmlFor="invoiceNo"
                                className="block text-900 font-medium mb-2"
                            >
                                Alvállalkozói számla sorszáma <span className="text-red-700 font-medium">(pontosan úgy írd be, ahogy a számlán szerepel)</span>
                            </label>

                            <InputText
                                className="w-full mb-3"
                                id="invoiceNo"
                                name="invoiceNo"
                                value={formik.values.invoiceNo}
                                onChange={formik.handleChange}
                            />
                            {formik.touched.invoiceNo &&
                                formik.errors.invoiceNo ? (
                                <small className="p-error">
                                    {formik.errors.invoiceNo.toString()}
                                </small>
                            ) : null}
                        </div>
                    </div>




                    <div className="formgrid grid">
                        <div className="field col-12 md:col-6">
                            <label
                                htmlFor="dateOfCompletion"
                                className="block text-900 font-medium mb-2"
                            >
                                Alvállalkozói számlán szereplő TELJESÍTÉSI dátum
                            </label>
                            <Calendar
                                className={
                                    formik.errors.dateOfCompletion
                                        ? "w-full p-invalid"
                                        : "w-full"
                                }
                                showIcon
                                dateFormat="yy.mm.dd"
                                id="dateOfCompletion"  {...formik.getFieldProps("dateOfCompletion")} />
                            {formik.errors.dateOfCompletion ? (
                                <small className="p-error">
                                    {formik.errors.dateOfCompletion.toString()}
                                </small>
                            ) : null}
                        </div>

                        <div className="field col-12 md:col-6">
                            <label
                                htmlFor="paymentDue"
                                className="block text-900 font-medium mb-2"
                            >
                                Alvállalkozói számlán szereplő FIZETÉSI határidő
                            </label>
                            <Calendar
                                className={
                                    formik.errors.dateOfCompletion
                                        ? "w-full p-invalid"
                                        : "w-full"
                                }
                                showIcon
                                dateFormat="yy.mm.dd"
                                id="paymentDue"  {...formik.getFieldProps("paymentDue")} />
                            {formik.errors.paymentDue ? (
                                <small className="p-error">
                                    {formik.errors.paymentDue.toString()}
                                </small>
                            ) : null}
                        </div>

                    </div>


                    <div className="formgrid grid">


                        <div className="field col-12 md:col-6">


                            <label
                                htmlFor="invoiceNo"
                                className="block text-900 font-medium mb-2"
                            >
                                Cég
                            </label>

                            <InputText
                                className="w-full mb-3"
                                id="company"
                                name="company"
                                value={formik.values.company}
                                onChange={formik.handleChange}
                            />
                            {formik.touched.company &&
                                formik.errors.company ? (
                                <small className="p-error">
                                    {formik.errors.company.toString()}
                                </small>
                            ) : null}
                        </div>
                        <div className="field col-12 md:col-6">

                            <label
                                htmlFor="invoiceNo"
                                className="block text-900 font-medium mb-2"
                            >
                                Alvállalkozói számlán szereplő díj nettó összege
                            </label>
                            <InputNumber value={formik.values.amount} onValueChange={(e: any) => formik.setFieldValue("amount", e.value)}
                                mode="currency" maxFractionDigits={0} currency="HUF" maxLength={13} min={-99999999} max={99999999}
                                locale="hu-HU" />
                            {formik.touched.amount &&
                                formik.errors.amount ? (
                                <small className="p-error">
                                    {formik.errors.amount.toString()}
                                </small>
                            ) : null}
                        </div>
                    </div>





                    <div className="formgrid grid">
                        <div className="field">
                            <Button
                                className="p-button-text"
                                type="button"
                                label="Bezárás"
                                icon="pi pi-times"
                                onClick={() => {
                                    hideDialog();
                                }}
                            />
                        </div>

                        <div className="field">
                            <Button
                                className="p-button-text"
                                type="submit"
                                label="Mentés"
                                icon="pi pi-check"
                            />
                        </div>
                    </div>
                </form>
            </Dialog>
        </>
    );
};

export default VendorInvoiceDetail;





