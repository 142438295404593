import React, { useEffect, useState, Fragment } from "react";
import { useDispatch } from "react-redux";
import "primeflex/primeflex.css";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useNavigate } from "react-router";
import { StatusCodes } from "http-status-codes";
import * as Yup from "yup";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { confirmDialog } from "primereact/confirmdialog";
import { addTask,  updateTaskName } from "../../service/TrafficTableService";
import { setTrafficTableLineList } from "../../state/trafficTableSlice";
import { logout } from "../../service/AuthService";
import { Dropdown } from "primereact/dropdown";
import { readAllCreativeSkyUsers } from "../../service/SkyUserService";



const AddParticipantDialog: React.FC<any> = (props: any) => {

    let navigate = useNavigate();
    const dispatch = useDispatch();

    const [
        dialogShow,
        setDialogShow,
    ] = useState<boolean>(false);

    const [
        dialogChange,
        setDialogChange,
    ] = useState<number>(0);

    const hideDialog = () => {
        setDialogShow(false);
        formik.resetForm();
        refreshTrafficTable();
    };

    const [allCreativeSkyUsers, setAllCreativeSkyUsers] = useState<any>([]);


    const projectId: string = props.projectId;
    const actualParticipantId: string = props.participantId;
    const refreshTrafficTable = props.refresh;
    const taskName = props.taskName;
    const taskComment = props.taskComment;


    // const refreshTrafficTable = () => {
    //     readTrafficTable().then(
    //         (response) => {
    //             dispatch(
    //                 setTrafficTableLineList(
    //                     response.data.trafficTableLineList
    //                 )
    //             );
    //         },
    //         (error) => {
    //             if (error.response.status === StatusCodes.UNAUTHORIZED) {
    //                 //TODO login oldalon üzenet --> Lejárt a munkamenet, jelentkezzen be
    //                 logout();
    //                 navigate("/login?status=unauthorized");
    //             }
    //         }
    //     );
    // };

    const confirmSave = (values: any) => {
        confirmDialog({
            message: 'Valóban menti a módosításokat?',
            header: 'Megerősítés',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                //values = {taskId: taskId, comment: values.coment};
                //alert(JSON.stringify(values, null, 2));
                addTask(values).then(
                    (response) => {
                        hideDialog();
                    },
                    (error) => {
                        console.log(error);
                        hideDialog();
                    }
                );

            },
            reject: () => { }
        });
    }

    useEffect(() => {
        if (
            props.show === true
        ) {
            readAllCreativeSkyUsers().then(
                (response) => {
                    let skyUserList: { label: any; value: any }[] = [];
                    response.data.skyUserList.forEach((item: any) => {
                        if (item.id !== actualParticipantId) {
                            skyUserList.push({ label: item.shortName, value: item.id });
                        }

                    });
                    setAllCreativeSkyUsers(skyUserList);
                },
                (error) => {
                    console.log(error.response.status);
                }
            );


            setDialogChange(props.change);
            setDialogShow(props.show);
        }
    }, [dialogChange, navigate, props.show, props.change, projectId]);

    const formik = useFormik({
        initialValues: {
            taskName: taskName,
            taskComment: taskComment,
            projectId: projectId ? projectId : "",
            participantId: "",
        },
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: Yup.object({
            // taskName: Yup.string()
            //     .min(2, "A névnek legalább 2 karakternek kell lennie")
            //     .required("Kötelező kitölteni!"),
            participantId: Yup.string()
                .required("Kötelező kitölteni!"),
        }),
        onSubmit: (values) => {
            if (dialogShow) {
                confirmSave(values);
            }
        },
    });


    return (
        <>
            <Dialog header="Kreatív hozzárendelése" visible={dialogShow} closable={false}
                maximizable modal style={{ width: "70vw" }} onHide={() => hideDialog()}>
                <form onSubmit={formik.handleSubmit} className="p-fluid">

                    <div className="formgrid grid">
                        <div className="field col-12 md:col-6">
                            <label
                                htmlFor="taskName"
                                className="block text-900 font-medium mb-2"
                            >
                                Feladat neve
                            </label>
                            <InputText
                                className="w-full mb-3"
                                autoFocus
                                id="taskName"
                                name="taskName"
                                value={formik.values.taskName}
                                onChange={formik.handleChange}
                            />
                            {formik.touched.taskName &&
                                formik.errors.taskName ? (
                                <small className="p-error">
                                    {formik.errors.taskName.toString()}
                                </small>
                            ) : null}
                        </div>

                        <div className="field col-12 md:col-6">
                            <label
                                htmlFor="taskComment"
                                className="block text-900 font-medium mb-2"
                            >
                                Megjegyzés
                            </label>
                            <InputText
                                className="w-full mb-3"

                                id="taskComment"
                                name="taskComment"
                                value={formik.values.taskComment}
                                onChange={formik.handleChange}
                            />
                            {formik.touched.taskComment &&
                                formik.errors.taskComment ? (
                                <small className="p-error">
                                    {formik.errors.taskComment.toString()}
                                </small>
                            ) : null}
                        </div>
                    </div>


                    <div className="formgrid grid">
                        <div className="field col-12 md:col-6">
                            <label
                                htmlFor="participantId"
                                className="block text-900 font-medium mb-2"
                            >
                                Kreatív
                            </label>
                            <Dropdown
                                id="participantId"
                                className="w-full mb-3"
                                {...formik.getFieldProps("participantId")}
                                options={allCreativeSkyUsers}
                                onChange={formik.handleChange}
                                value={formik.values.participantId}
                                placeholder="Válasszon!"
                            />
                            {formik.touched.participantId &&
                                formik.errors.participantId ? (
                                <small className="p-error">
                                    {formik.errors.participantId?.toString()}
                                </small>
                            ) : null}
                        </div>

                    </div>

                    <div className="formgrid grid">
                        <div className="field">
                            <Button
                                className="p-button-text"
                                type="button"
                                label="Bezárás"
                                icon="pi pi-times"
                                onClick={() => {
                                    hideDialog();
                                }}
                            />
                        </div>

                        <div className="field">
                            <Button
                                className="p-button-text"
                                type="submit"
                                label="Mentés"
                                icon="pi pi-check"
                            />
                        </div>
                    </div>
                </form>
            </Dialog>
        </>
    );
};

export default AddParticipantDialog;





