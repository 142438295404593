import { createSlice, PayloadAction } from "@reduxjs/toolkit";


interface SkyUserType {
    skyUserList: [];
}


const initialState: SkyUserType = {
    skyUserList: []
};

export const skyUserSlice = createSlice({
    name: "SkyUserAction",
    initialState: initialState,
    reducers: {
        setSkyUserList: (state, action: PayloadAction<any>) => {
            state.skyUserList = action.payload;
        },
        cleanSkyUserList: (state) => {
            state.skyUserList = [];
        },
    },
});

export const { setSkyUserList } = skyUserSlice.actions;
export const { cleanSkyUserList } = skyUserSlice.actions;

export default skyUserSlice.reducer;
