import React from "react";
import { Navigate, Outlet } from "react-router";
import { getCurrentUser } from "../service/AuthService";

const FinanceRoute: React.FC = () => {
    let user = getCurrentUser();

    if (!user) {
        return <Navigate to="/login" replace />;
    } else if (user.role !== 'ADMIN' && user.role !== 'FINANCE' && user.role !== 'MANAGEMENT') {
        return <Navigate to="/main2/dashboard" replace />;
    }
    return <Outlet />;
};

export default FinanceRoute;
