import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "primeflex/primeflex.css";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { RootState } from "../../state/store";

import { StatusCodes } from "http-status-codes";
import { getCurrentUser, logout, setAuthToken } from "../../service/AuthService";
import { useNavigate } from "react-router";
import { setActiveMenu, setActiveView } from "../../state/navigationSlice";
import "../../css/Admin.css";
import { FileUpload, FileUploadUploadParams } from 'primereact/fileupload';
import { readAllVendorInvoices } from "../../service/VendorInvoiceService";
import { setVendorInvoiceList } from "../../state/vendorInvoiceSlice";
import VendorInvoiceDetail from "./VendorInvoiceDetail";
import { Toast } from "primereact/toast";
import { server } from "../../config/endpoints";
import { authHeader } from "../../service/AuthService";
import { Checkbox } from "primereact/checkbox";
import { SelectButton } from 'primereact/selectbutton';
import { onRowIndexTemplate } from "../../service/IndexColumn";
import {readAllCustomerInvoices} from "../../service/CustomerInvoiceService";
import {setCustomerInvoiceList} from "../../state/customerInvoiceSlice";



const CustomerInvoiceList: React.FC = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  setAuthToken();
  dispatch(setActiveView("Vevői számlák"));
  dispatch(setActiveMenu("customerInvoicelist"));

  const [customerInvoiceId, setCustomerInvoiceId] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const toast: any = useRef(null);

  const [customerInvoiceDetailDialogShow, setCustomerInvoiceDetailDialogShow] =
    useState<boolean>(false);

  const [customerInvoiceDetailDialogChange, setCustomerInvoiceDetailDialogChange] =
    useState<number>(0);

  const customerInvoiceList: [] = useSelector(
    (state: RootState) => state.customerInvoice.customerInvoiceList
  );

  const [company, setCompany] = useState('SKY');
  const [role, setRole] = useState<String>("");


  const onCustomerInvoiceDetailDialogClose = () => {
    toast.current.show({ severity: 'info', summary: 'Információs üzenet', detail: 'Emailen küldd el az alvállalkozói számlát az illetékes accountoknak leigazolásra!', life: 5000 });
  }

  const formatCurrency = (value: any) => {
    return value.toLocaleString('hu-HU', { style: 'currency', currency: 'HUF', maximumFractionDigits: 0 });
  }

  const customerInvoiceAmountTemplate = (rowData: any) => {
    return formatCurrency(rowData.amount);
  }

  const dateOfCompletionTemplate = (rowData: any) => {
    return rowData.dateOfCompletion.replaceAll('-', '.').toLocaleString('hu-HU');
  }

  const paymentDueTemplate = (rowData: any) => {
    return rowData.paymentDue.replaceAll('-', '.').toLocaleString('hu-HU');
  }



  const refresh = (company: string) => {
    console.log("CustomerList's useEffect");
    setLoading(true);
    setRole(getCurrentUser().role);
    readAllCustomerInvoices(company).then(
      (response) => {
        dispatch(setCustomerInvoiceList(response.data.customerInvoiceList));
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        if (error.response.status === StatusCodes.UNAUTHORIZED) {
          logout();
          navigate("/login?status=unauthorized");
        }
      }
    );
  }

  useEffect(() => {
    refresh(company);
  }, [navigate, dispatch]);

  const editVendorInvoiceTemplate = (rowData: any) => {
    return (
      <>
        <Button
          className="p-button-rounded p-button-text"
          type="button"
          onClick={() => {
            setCustomerInvoiceId(rowData.id);
            setCustomerInvoiceDetailDialogShow(true);
            setCustomerInvoiceDetailDialogChange(Date.now());
          }}
          icon="pi pi-pencil"
          aria-label="Edit"
        />
      </>
    );
  };

  const API_URL = server;

  const skyOptions = { icon: 'pi pi-fw pi-cloud-upload', iconOnly: false, className: 'p-panel p-button p-button-sm  mt-3 p-button-text' };
  const bpcOptions = { icon: 'pi pi-fw pi-cloud-upload', iconOnly: false, className: 'p-panel p-button p-button-sm p-button-warning mt-3 p-button-text' };

  const onUpload = (event: FileUploadUploadParams) => {
    toast.current.show({ severity: 'info', summary: 'Információs üzenet', detail: event.xhr.responseText });
    refresh(company);
  }

  const onBeforeSend = (event: any) => {
    console.log(authHeader().Authorization);
    return event.xhr.setRequestHeader('Authorization', authHeader().Authorization);
  }

  const onBeforeUpload = () => {
    setLoading(true);
  }

  const onError = (event: any) => {
    setLoading(false);
    toast.current.show({ severity: 'error', summary: 'Információs üzenet', detail: 'Hiba a feltöltés során.' });
  }


  const amountTemplate = (rowData: any) => {
    return formatCurrency(rowData.amount);
  }


  const options = ['SKY', 'BPC', 'Összes'];
  return (
    <>
      <div className="border-2 surface-border border-round surface-section flex-auto p-3 md:p-4 lg:p-5">
        <Toast ref={toast} />
        <div className="grid">
          <div className="col-6">
            <SelectButton unselectable={false} style={{ width: '380px' }} className='p-panel p-button p-button-sm  mb-3 p-button-text flex align-items-center' value={company} options={options} onChange={(e) => { setCompany(e.value); refresh(e.value); }} />
          </div>

          <div className="col-6">
            {role === 'ADMIN' || role === 'FINANCE' ? (
              <div className="flex justify-content-end flex-wrap card-container green-container">
                <div className="flex align-items-center justify-content-center">
                  <FileUpload onBeforeSend={onBeforeSend} chooseOptions={skyOptions} mode="basic" name="invoicefile" url={API_URL + '/protected/customerinvoice/upload/sky'}
                    accept="text/csv" maxFileSize={1000000} onUpload={onUpload} auto chooseLabel="SKY Feltöltés" onBeforeUpload={onBeforeUpload}
                    onError={onError} />
                  <FileUpload onBeforeSend={onBeforeSend} chooseOptions={bpcOptions} mode="basic" name="invoicefile" url={API_URL + '/protected/customerinvoice/upload/bpc'}
                    accept="text/csv" maxFileSize={1000000} onUpload={onUpload} auto chooseLabel="BPC Feltöltés" onBeforeUpload={onBeforeUpload}
                    onError={onError} />
                </div>
              </div>
            ) : null}

          </div>
          <VendorInvoiceDetail
            id={customerInvoiceId}
            onClose={onCustomerInvoiceDetailDialogClose}
            show={customerInvoiceDetailDialogShow}
            change={customerInvoiceDetailDialogChange}
          />
        </div>

        <DataTable
          //className="admin-list"
          value={customerInvoiceList}
          size="small"
          scrollable
          scrollHeight="70vh"
          //scrollDirection="both"
          responsiveLayout="scroll"
          stripedRows
          loading={loading}
        >
          <Column field="rowIndex" header="" style={{ maxWidth: '60px' }} body={onRowIndexTemplate} />
          {/* <Column field="rowNo" style={{ maxWidth: '80px' }} header="Sor" sortable /> */}
          <Column field="company" style={{ maxWidth: '100px' }} header="Cég" sortable />
          <Column field="year" style={{ maxWidth: '100px' }} header="Év" sortable />
          <Column style={{ maxWidth: '230px' }} field="customerShortName" header="Vevő" sortable />
          <Column style={{ maxWidth: "300px" }} alignHeader="left" field="projectShortIdentifier" header="Rövid munkaszám" sortable />
          <Column style={{ maxWidth: "200px" }} alignHeader="right" className="justify-content-end" field="amount" header="Vevői számlán szereplő díj" sortable body={amountTemplate} />
          <Column style={{ maxWidth: "200px" }} alignHeader="left" field="invoiceNo" header="Vevői számla sorszáma" sortable />
          <Column alignHeader="left" field="dateOfCompletion" style={{ maxWidth: "150px" }} header="Vevői számla TELJESÍTÉSI dátuma" sortable body={dateOfCompletionTemplate} />
          <Column alignHeader="left" field="paymentDue" style={{ maxWidth: "150px" }} header="Vevői számla FIZETÉSI határideje" sortable body={paymentDueTemplate} />
        </DataTable>
      </div>
    </>
  );
};

export default CustomerInvoiceList;
