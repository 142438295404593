import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "primeflex/primeflex.css";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { RootState } from "../../state/store";
import { readAllCustomers } from "../../service/CustomerService";
import { StatusCodes } from "http-status-codes";
import { authHeader, logout, setAuthToken } from "../../service/AuthService";
import { useNavigate } from "react-router";
import { setActiveMenu, setActiveView } from "../../state/navigationSlice";
import "../../css/Admin.css";

import { setCustomerList } from "../../state/customerSlice";
import CustomerDetail from "./CustomerDetail";
import {readAllVendors} from "../../service/VendorService";
import {setVendorList} from "../../state/vendorSlice";
import VendorDetail from "./VendorDetail";
import { FileUpload, FileUploadUploadParams } from "primereact/fileupload";
import { server } from "../../config/endpoints";
import { Toast } from "primereact/toast";
import { onRowIndexTemplate } from "../../service/IndexColumn";

const VendorList: React.FC = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  setAuthToken();
  dispatch(setActiveView("Alvállalkozók"));
  dispatch(setActiveMenu("vendorlist"));
  const API_URL = server;

  const [vendorId, setVendorId] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const toast: any = useRef(null);


  const [vendorDetailDialogShow, setVendorDetailDialogShow] =
    useState<boolean>(false);

  const [vendorDetailDialogChange, setVendorDetailDialogChange] =
    useState<number>(0);

  const vendorList: [] = useSelector(
    (state: RootState) => state.vendor.vendorList
  );


  const refresh = () => {
    console.log("CustomerList's useEffect");
    setLoading(true);
    readAllVendors().then(
      (response) => {
        dispatch(setVendorList(response.data.vendorList));
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        if (error.response.status === StatusCodes.UNAUTHORIZED) {
          logout();
          navigate("/login?status=unauthorized");
        }
      }
    );
  };



  useEffect(() => {
    refresh();
  }, [navigate, dispatch]);

  const editVendorTemplate = (rowData: any) => {
    return (
      <>
        <Button
          className="p-button-rounded p-button-text"
          style={{height: '30px', width: '30px'}}
          type="button"
          onClick={() => {
            setVendorId(rowData.id);
            setVendorDetailDialogShow(true);
            setVendorDetailDialogChange(Date.now());
          }}
          icon="pi pi-pencil"
          aria-label="Edit"
        />
      </>
    );
  };

  const onUpload = (event: FileUploadUploadParams ) => {
    console.log(event.xhr.responseText);
    toast.current.show({severity: 'info', summary: 'Információs üzenet', detail: event.xhr.responseText });
    refresh();
  }

  const onBeforeUpload = () => {
    setLoading(true);
  }

   
  const onBeforeSend = (event: any) => {
    console.log(authHeader().Authorization);
    return event.xhr.setRequestHeader('Authorization', authHeader().Authorization);
  }

  const onError = (event: any) => {
    setLoading(false);
    toast.current.show({ severity: 'error', summary: 'Információs üzenet', detail: 'Hiba a feltöltés során.' });
  }

  const options = { icon: 'pi pi-fw pi-cloud-upload', iconOnly: false, className: 'p-panel p-button p-button-sm p-button-warning mb-3 p-button-text' };


  return (
    <>
      <div className="border-2 surface-border border-round surface-section flex-auto p-3 md:p-4 lg:p-5">
      <Toast ref={toast} />
        <div className="flex justify-content-end flex-wrap card-container green-container">
          <div className="flex align-items-center justify-content-center">
          <FileUpload disabled onBeforeSend={onBeforeSend} chooseOptions={options} mode="basic" name="vendorFile" url={API_URL + '/protected/vendor/upload'} 
          accept="text/csv" maxFileSize={1000000} onUpload={onUpload} auto chooseLabel="Feltöltés" 
          onBeforeUpload={onBeforeUpload} onError={onError}/>

            <Button
              className="p-panel p-button p-button-sm p-button-info mb-3 p-button-text"
              icon="pi pi-plus"
              label="Új alvállalkozó"
              disabled
              onClick={() => {
                setVendorId("");
                setVendorDetailDialogShow(true);
                setVendorDetailDialogChange(Date.now());
              }}
            />
            <VendorDetail
              id={vendorId}
              show={vendorDetailDialogShow}
              change={vendorDetailDialogChange}
            />
          </div>
        </div>
        <DataTable
          //className="admin-list"
          value={vendorList}
          size="small"
          scrollable
          scrollHeight="70vh"
          //scrollDirection="both"
          responsiveLayout="scroll"
          stripedRows
          loading={loading}
        >
          <Column field="rowIndex" header="" style={{ maxWidth: '50px' }} body={onRowIndexTemplate} />
          <Column bodyClassName="p-0" header="Művelet" style={{maxWidth: '100px'}} body={editVendorTemplate} />
          <Column field="shortName" header="Rövid név" sortable filter />
          <Column field="name" header="Név" sortable filter />
        </DataTable>
      </div>
    </>
  );
};

export default VendorList;
