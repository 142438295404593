import React, { useLayoutEffect } from 'react';
import logo from './logo.svg';
import '../css/App.css';
import { setLocalHU } from '../config/localhu';
import { Route, Routes, useLocation, useNavigate } from 'react-router';
import WelcomeLoginRoute from '../route/WelcomeLoginRoute';
import Login from './home/Login';
import ProtectedRoute from '../route/ProtectedRoute';
import NotFound from './NotFound';
import Footer from './home/Footer';
import Main from './Main';
import Main2 from './Main2';
import Main3 from './Main3';

type ScrollToTopWrapperProps = {
  children: React.ReactNode;
};

const ScrollToTopWrapper = (props: ScrollToTopWrapperProps) => {
  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location.pathname]);
  return <div>{props.children}</div>;
};


function App() {

  setLocalHU();
  let navigate = useNavigate();

  return (
      <div>
        <div className="mb-3">
          <ScrollToTopWrapper>
            <Routes >
              <Route element={<WelcomeLoginRoute />}>
                <Route path={"/"} element={<Login />} />
              </Route>
              <Route path={"/login"} element={<Login />} />
              <Route element={<ProtectedRoute />}>
                <Route path={"/main/*"} element={<Main2 />} />
                <Route path={"/main2/*"} element={<Main2 />} />
                <Route path={"/main3/*"} element={<Main3 />} />

              </Route>
              <Route path="*" element={<NotFound />} />
            </Routes>
          </ScrollToTopWrapper>
        </div>
        <Footer />
      </div>
  );
}
export default App;
