import React, { useEffect, useRef, useState } from "react";
import { Route, Routes, useNavigate } from "react-router";
import {
    setAuthToken,
    getCurrentUser,
    logout,
} from "../service/AuthService";
import { StyleClass } from "primereact/styleclass";
import { Ripple } from "primereact/ripple";

import skyGroupLogo from "../asset/sky-group-logo.png";

import Dashboard from "./dashboard/Dashboard";
import SkyUserList from "./dashboard/SkyUserList";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../state/store";
import { setActiveMenu, setActiveView } from "../state/navigationSlice";
import RoleList from "./dashboard/RoleList";
import ProtectedRoute from "../route/ProtectedRoute";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import ProjectList from "./dashboard/ProjectList";
import CustomerList from "./dashboard/CustomerList";
import BranchList from "./dashboard/BranchList";
import TrafficTable from "./dashboard/TrafficTable";
import WorkingTime from "./dashboard/WorkingTime";
import TrafficTableNotes from "./dashboard/TrafficTableNotes";
import { Badge } from 'primereact/badge';
import EonReportDialog from "./dashboard/EonReportDialog";
import { createEonReport } from "../service/ReportService";
import FileSaver from "file-saver";
import { BlockUI } from "primereact/blockui";
import WorkingTimeReportDialog from "./dashboard/WorkingTimeReportDialog";
import { createWorkingTimeReport } from "../service/WorkingTimeReportService";
import MessageDialog from "../component/MessageDialog";
import VendorList from "./dashboard/VendorList";
import VendorInvoiceList from "./dashboard/VendorInvoiceList";
import ContributorList from "./dashboard/ContributorList";
import { Menubar } from 'primereact/menubar';
import { classNames } from "primereact/utils";

const Main3: React.FC = () => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    setAuthToken();


    const btnRefSidebar = useRef(null);
    const btnRefDashboard = useRef(null);
    const btnRefUserList = useRef(null);
    const btnRefAdministration = useRef(null);
    const btnRefCustomerList = useRef(null);
    const btnRefProjectList = useRef(null);
    const btnRefTrafficTable = useRef(null);
    const btnRefWorkingHours = useRef(null);
    const btnRefTopbar = useRef(null);
    const btnRefProfil = useRef(null);

    const btnRef1 = useRef(null);
    const btnRef2 = useRef(null);
    const btnRefReports = useRef(null);
    const btnVendorInvoice = useRef(null);

    const [user, setUser] = useState<any>();
    const [userName, setUserName] = useState<String>("");
    const [name, setName] = useState<String>("");
    const [role, setRole] = useState<String>("");
    const [eonReport, setEonReport] = useState<Boolean>(false);
    const [blockedPage, setBlockedPage] = useState<boolean>(false);
    const [blockedMessage, setBlockedMessage] = useState<string>("");

    const [messageDialogShow, setMessageDialogShow] = useState<Number>(0);
    const [messageDialogHeader, setMessageDialogHeader] = useState<string>("");
    const [messageDialogBody, setMessageDialogBody] = useState<string>("");
    const [messageDialogError, setMessageDialogError] =
        useState<boolean>(false);

    const activeView: any = useSelector(
        (state: RootState) => state.navigation.activeView
    );
    const activeMenu: string = useSelector(
        (state: RootState) => state.navigation.activeMenu
    );

    const [updateGenericReportDialogShow, setUpdateGenericReportDialogShow] =
        useState<boolean>(false);

    const [updateGenericReportDialogChange, setUpdateGenericReportDialogChange] =
        useState<number>(0);

    const onWorkingTimeReportDialogClose = (data: any) => {
        console.log("onWorkingTimeReportDialogClose is called");
        setBlockedPage(true);
        setBlockedMessage('Jelentés készítése folyamatban...');
        setUpdateGenericReportDialogShow(false);
        setUpdateGenericReportDialogChange(0);
        console.log(data);
        let reportTypeItems = [
            { label: "Naptár nézet egyben", value: "1" },
            { label: "Naptár nézet emberenként", value: "2" },
            { label: "E.ON Havidíj kimutatás", value: "3" },
            { label: "E.ON Fix/Óradíjas kimutatás", value: "3" },
        ];
        createWorkingTimeReport(data).then(
            (response) => {
                setBlockedPage(false);
                setUpdateGenericReportDialogShow(false);

                console.log(response);
                let blob = new Blob([response.data]);

                FileSaver(blob, "Munkaórák-" + reportTypeItems[data.reportTypeId - 1].label + "-" +
                    + new Date(data.fromDate).toLocaleDateString("hu-HU").replaceAll(" ", "").replaceAll(".", "") + "-"
                    + new Date(data.toDate).toLocaleDateString("hu-HU").replaceAll(" ", "").replaceAll(".", "")
                    + ".xlsx");
            },
            async (error) => {
                console.log(error);
                let jsonAsText = await error.response.data.text();
                let json = JSON.parse(jsonAsText)
                setMessageDialogHeader("Ellenőrzési hiba");
                setMessageDialogBody(json.message);
                setMessageDialogError(true);
                setMessageDialogShow(Date.now());
                setBlockedPage(false);
                setUpdateGenericReportDialogShow(false);
            }
        );
    };

    const onGenericReportDialogReject = () => {
        console.log("onGenericReportDialogReject is called");
        setUpdateGenericReportDialogShow(false);
        setUpdateGenericReportDialogChange(0);
    };

    const showGenericReport = () => {
        console.log("showEonReport is called");
        setUpdateGenericReportDialogShow(true);
        setUpdateGenericReportDialogChange(Date.now());
    };


    //setUserName(getCurrentUser().userName);

    const clear = () => {
        logout();
        navigate("/login");
    };


    useEffect(() => {
        setUserName(getCurrentUser().userName);
        setName(getCurrentUser().name);
        setRole(getCurrentUser().role);
        setEonReport(getCurrentUser().eonReport);

    }, []);

    const confirmLogout = () => {
        confirmDialog({
            message: 'Valóban kijelentkezik?',
            header: 'Megerősítés',
            icon: 'pi pi-exclamation-triangle',
            acceptClassName: 'p-button-text',
            rejectClassName: 'p-button-text',
            accept: () => { clear(); },
            reject: () => { }
        });
    }


    const items = [
        {
           label:'File',
           icon:'pi pi-fw pi-file',
           items:[
              {
                 label:'New',
                 icon:'pi pi-fw pi-plus',
                 items:[
                    {
                       label:'Bookmark',
                       icon:'pi pi-fw pi-bookmark'
                    },
                    {
                       label:'Video',
                       icon:'pi pi-fw pi-video',
                       className: 'z-3'
                    },
    
                 ]
              },
              {
                 label:'Delete',
                 icon:'pi pi-fw pi-trash'
              },
              {
                 separator:true
              },
              {
                 label:'Export',
                 icon:'pi pi-fw pi-external-link',
                 className: 'z-3',
                
              }
           ]
        },
        {
           label:'Edit',
           icon:'pi pi-fw pi-pencil',
           items:[
              {
                 label:'Left',
                 icon:'pi pi-fw pi-align-left'
              },
              {
                 label:'Right',
                 icon:'pi pi-fw pi-align-right'
              },
              {
                 label:'Center',
                 icon:'pi pi-fw pi-align-center'
              },
              {
                 label:'Justify',
                 icon:'pi pi-fw pi-align-justify'
              },
    
           ]
        },
        {
           label:'Users',
        
           icon:'pi pi-fw pi-user',
           items:[
              {
                 label:'New',
                 icon:'pi pi-fw pi-user-plus',
    
              },
              {
                 label:'Delete',
                 icon:'pi pi-fw pi-user-minus',
    
              },
              {
                 label:'Search',
                 icon:'pi pi-fw pi-users',
                 items:[
                    {
                       label:'Filter',
                       icon:'pi pi-fw pi-filter',
                       items:[
                          {
                             label:'Print',
                             icon:'pi pi-fw pi-print'
                          }
                       ]
                    },
                    {
                       icon:'pi pi-fw pi-bars',
                       label:'List'
                    }
                 ]
              }
           ]
        },
        {
           label:'Events',
           icon:'pi pi-fw pi-calendar',
           items:[
              {
                 label:'Edit',
                 icon:'pi pi-fw pi-pencil',
                 items:[
                    {
                       label:'Save',
                       icon:'pi pi-fw pi-calendar-plus'
                    },
                    {
                       label:'Delete',
                       icon:'pi pi-fw pi-calendar-minus'
                    },
    
                 ]
              },
              {
                 label:'Archieve',
                 icon:'pi pi-fw pi-calendar-times',
                 items:[
                    {
                       label:'Remove',
                       icon:'pi pi-fw pi-calendar-minus'
                    }
                 ]
              }
           ]
        },
        {
           label:'Quit',
           icon:'pi pi-fw pi-power-off'
        }
     ];


    return (
        <>
            <BlockUI
                blocked={blockedPage}
                fullScreen
                template={
                    <div
                        className="text-center text-teal-50"
                        style={{ fontSize: "2rem" }}
                    >
                        <i
                            className="pi pi-spin pi-spinner"
                            style={{ fontSize: "3rem" }}
                        />
                        <br />
                        {blockedMessage}
                    </div>
                }
            />

<Menubar model={items}/>

            <div className="flex flex-column flex-auto p-2 mt-2 md:p-2 lg:p-3">
                {/* <div className="text-xl text-900 font-medium mb-3">
                    {activeView}
                </div> */}
                <Routes>
                    <Route element={<ProtectedRoute />}>
                        <Route path={"rolelist"} element={<RoleList />} />
                        <Route path={"dashboard"} element={<Dashboard />} />
                        <Route path={"userlist"} element={<SkyUserList />} />
                        <Route path={"projectList"} element={<ProjectList />} />
                        <Route path={"customerlist"} element={<CustomerList />} />
                        <Route path={"vendorlist"} element={<VendorList />} />
                        <Route path={"contributorlist"} element={<ContributorList />} />
                        <Route path={"vendorinvoicelist"} element={<VendorInvoiceList />} />
                        <Route path={"traffictable"} element={<TrafficTable />} />
                        <Route path={"workingtime"} element={<WorkingTime />} />
                        <Route path={"demo"} element={<TrafficTableNotes />} />
                    </Route>

                </Routes>
            </div>
        </>
    );
};
export default Main3;


